import useAuth from '../helper/useAuth';
import React from 'react';
import { db} from '../utils/firebase-config';
import { Outlet, Navigate } from 'react-router-dom';
import { doc, getDoc } from "firebase/firestore";
import { motion } from "framer-motion";

//let user = auth.currentUser

const PrivateRoutes = () => {
    
    const user = useAuth();    

    //const [userCurrentData, setUserCurrentData] = useState([]);           


    // const getUserDetails = async () => {
    //     const uid = await user.uid;        
    //     const userDocRef = doc(db, "users", uid );   
    //     const userDocSnap = await getDoc(userDocRef);
    //     if (userDocSnap.exists()) {                
    //         setUserCurrentData(userDocSnap.data())                
    //     } else {                
    //         console.log("No User Data!");
    //     }
    // }
    // getUserDetails();




    // useEffect(() => {
            
    //         const unsubscribe = async () => {
    //             const uid = await user.uid;             
    //             const userDocRef = doc(db, "users", uid );   
    //             const userDocSnap = await getDoc(userDocRef);
    //             if (userDocSnap.exists()) {                
    //                 setUserCurrentData(userDocSnap.data())
    //             } else {                
    //                 console.log("No User Data!");
    //             }
    //         }
    //         return unsubscribe;
    // }, []); 



    //console.log('/////user autheticated', user);
    //console.log('/////user details', userCurrentData);
    


    return typeof user === 'undefined' ? (
        <div className='flex flex-col h-screen justify-center items-center'>
            {/* <progress className="progress w-56"></progress> */}
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='fixed w-screen h-screen inset-0 z-50 flex justify-center items-center'><div className='spinner'></div></motion.div>
        </div>
    ) : user ? (                
        <>
            <Outlet />               
        </> 
    ) : (
        <Navigate to="/app/login"/>
    );

    
    // return typeof user === 'undefined' ? (
    //     <div className='flex flex-col h-screen justify-center items-center'>
    //         <progress className="progress w-56"></progress>
    //     </div>
    // ) : userCurrentData.isCompleted === false && userCurrentData.registerStep === 0 ? (        
    //     <Navigate to="/create-business-owner" />        
    // ) : userCurrentData.isCompleted === false && userCurrentData.registerStep === 1 ? (        
    //     <Navigate to="/create-business" />
    // ) : userCurrentData.isCompleted === false && userCurrentData.registerStep === 2 ? (        
    //     <Navigate to="/create-main-branch" />    
    // ) : user ? (        
    //     <Outlet />    
    // ) : (
    //     <Navigate to="/login"/>
    // );



       
    // return(
    //     auth.token ? <Outlet/> : <Navigate to="/login"/>
    // )
}

export default PrivateRoutes