import React, { useState, useEffect } from 'react';
import { db } from '../../utils/firebase-config.js';
import { format } from 'date-fns';
import { collectionGroup, query, getDocs, doc, updateDoc, orderBy } from "firebase/firestore";  
import { Link, useNavigate } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import quecks_logo from '../../assets/images/quecks_logo.png';
import user_img from '../../assets/images/user.png'


const FLAllUsers = () => {

  const navigate = useNavigate();
  

  const [users, setUsers] = useState([]);
  const [comChange, setComChange] = useState(0)




  const getUsers = async () => {
    const users = await getDocs(query(collectionGroup(db, 'users'), orderBy("createdAt", "desc")));
    setUsers(users.docs.map((doc) => ({ ...doc.data() })));
  };




  useEffect(() => {    
    getUsers();    
  }, [comChange]);

  const deactivate = async(uid) => {
    const userRef = doc(db, "users", uid);
    await updateDoc(userRef, {
      isActive: false,
    })
    setComChange(current => current + 1)
  }

  const activate = async(uid) => {
    const userRef = doc(db, "users", uid);
    await updateDoc(userRef, {
      isActive: true,
    })
    setComChange(current => current + 1)
  }

  const gotToDetails = (uid, firstName, lastName, imgUrl, phoneNumber, email) => {
    //navigate(`/1612/user-details/${uid}`)    
    navigate(`/1612/user-details/${uid}`, {
        state: {
            passedFirstName: firstName,
            passedLastName: lastName,
            passedImgUrl: imgUrl,
            passedPhoneNumber: phoneNumber,
            passedEmail: email,
        }
    });
  }


  console.log(comChange);


  return (
    <div className='my-7'>
      <Link to={'/1612'} className='flex flex-col justify-center items-center mb-7'>
        <img src={quecks_logo} alt='quecks' width={50} />
        <h1 className='font-semibold text-3xl text-primary pt-2'>Quecks 1612</h1>
      </Link>
      <div className='w-full'>
        <div className="overflow-x-auto w-full">
          {
            users.map( user => (
              <div key={user?.id} className='relative z-50 bg-white border border-gray-300 rounded-2xl shadow-sm mb-3'>
                <div className='flex justify-start items-start p-5'>
                  <div className='basis-1/2 md:basis-1/6 grow mr-3'>
                    {
                      user?.imgURL === "" || user?.imgURL === undefined ? (                        
                        <LazyLoadImage effect="blur" className="mask mask-squircle w-16 h-16 object-cover" src={user_img} alt={user?.firstName} />
                      ) : (
                        <LazyLoadImage effect="blur" className="mask mask-squircle w-16 h-16 object-cover" src={user?.imgURL} alt={user?.firstName} />
                      )
                    }                    
                  </div>
                  <div>
                    { user?.firstName && user?.lastName ? <h2 dir='auto' className='font-bold text-xl'>{user?.firstName} {user?.lastName}</h2> : null }                                      
                    <div>
                      {user?.isActive === true ? <span className="badge badge-ghost badge-sm mr-1 bg-teal-600 border-teal-600 text-white rounded-md">Active</span> : <span className="badge badge-ghost badge-sm mr-1 bg-red-400 text-white border-red-400">Inactive</span>}                      
                      {user?.isCompleted === true ? <span className="badge badge-ghost badge-sm mr-1 bg-teal-600 border-teal-600 text-white rounded-md">Completed</span> : <span className="badge badge-ghost badge-sm mr-1 bg-red-400 text-white border-red-400">Incomplete - {user?.registerStep}</span>}                      
                      {user?.phone && <a href={"tel:" + user?.phone}  className="badge badge-ghost badge-sm mr-1 bg-primary text-white border-primary rounded-md">{user?.phone}</a>}                                            
                      {user?.email && <span className="badge badge-ghost badge-sm mr-1 rounded-md">{user?.email}</span>}   
                      <span className="badge badge-ghost badge-sm rounded-md">Created: {format(user?.createdAt.seconds * 1000, 'dd/MM/yyyy - hh:mm a')}</span>
                    </div>
                  </div>
                </div>
                <div className='bg-accent border-t border-gray-300 rounded-b-2xl p-5'>
                  <div className='flex justify-between items-start'>
                    <div>
                      {user?.isActive === true ? ( 
                        <button className="btn btn-ghost btn-xs text-red-600" onClick={()=>deactivate(user?.id)}>Deactivae</button> 
                        ) : ( 
                          <button className="btn btn-ghost btn-xs text-teal-700" onClick={()=>activate(user?.id)}>Activate</button> 
                      )}                      
                    </div>
                    <div>
                      <Link to={`/app/booking/${user?.id}`} target="_blank" className='btn btn-ghost btn-xs'>Booking</Link>                      
                      <button className="btn btn-ghost btn-xs">Login</button>
                      <button className="btn btn-ghost btn-xs" onClick={()=>gotToDetails(user?.id, user?.firstName, user?.lastName, user?.imgURL, user?.phone, user?.email)}>details</button>
                    </div>
                  </div>
                </div>      
              </div>  
            ) )
          } 
        </div>
      </div>
    </div>
  )
}

export default FLAllUsers