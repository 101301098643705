import React, { useState, useEffect } from 'react';
import { db, auth, storage } from '../../utils/firebase-config';
import { onAuthStateChanged } from "firebase/auth";
import { useTranslation } from 'react-i18next';
import { doc, setDoc, getDoc, addDoc, collection, serverTimestamp, query, getDocs } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import moment from 'moment';
import { GlobalState } from '../../context/StateContext';
import { useNavigate, useLocation } from "react-router-dom";
import { HeaderOfUnAuth, BusinessWizard } from '../../components/app';
import { motion } from "framer-motion";
import { Input, Textarea } from "@material-tailwind/react";
import Select from 'react-tailwindcss-select';
import { MdBusinessCenter } from 'react-icons/md';
import { CgRename } from "react-icons/cg";
import Lottie from "lottie-react";
import LoadingAnimation from '../../assets/jsons/lottie/loading.json'







const CreateBusiness = () => {

    const {t} = useTranslation();
    const navigate = useNavigate();
    //const location = useLocation();

    const isCompleted = GlobalState()?.userData?.isCompleted;          
    const registerStep = GlobalState()?.userData?.registerStep;


    const businessCategoryOptions = [
        {value: "hospital", label: t('Hospital')},
        {value: "clinic", label: t('Clinic')},
        {value: "bank", label: t('Bank')},
        {value: "barber-shop", label: t('Barber Shop')},
        {value: "restaurant", label: t('Restaurant')},
        {value: "comunications-store", label: t('Comunications Store')},
        {value: "game-stations", label: t('Game Stations')},
        {value: "other", label: t('Other')},    
    ];
    
    //const user = auth.currentUser;
    const [user, setUser] = useState(null)
    const [uid, setUid] = useState(null)

    const [submitLoading, setSubmitLoading] = useState(false)

    useEffect( () => {

        const un = onAuthStateChanged(auth, (user) => {            

        //const un = () => {  

            if (user) {
                setUser(auth.currentUser);
                setUid(user.uid);                  
            }
        });

        // };

        return (un);

    }, [] )

    //console.log(user);

    console.log('isCompleted: ', isCompleted)
    console.log('registerStep: ', registerStep)

    //const [userData, setUserData] = useState({});    

    const [businessName, setBusinessName] = useState('');
    const [businessDesc, setBusinessDesc] = useState('');
    const [businessImg, setBusinessImg] = useState('');
    const [domainName, setDomainName] = useState('');
    const [progress, setProgress] = useState(0);
    const [businessCategory, setBusinessCategory] = useState(null);
    
    const [businessDocuments, setBusinessDocuments] = useState([]);
    const [businessId, setBusinessId] = useState(null);

    const [isFormValid, setIsFormValid] = useState(null);
    const [formErrMsg, setFormErrMsg] = useState("");  
    const [businessNameErrMsg, setBusinessNameErrMsg] = useState("");  
    const [uploadImgFileName, setUploadImgFileName] = useState(null);    

    
    useEffect ( () => {
        !businessName || !businessCategory? setIsFormValid(current => false) : setIsFormValid(current => true) 
    }, [businessName, businessCategory] )  
      


    // useEffect( () => {
    //     const userDocRef = doc(db, "users", uid);
    //     const unsubscribe = async () => {
    //         const userDocSnap = await getDoc(userDocRef);
    //         if (userDocSnap.exists()) {
    //             setUserData(userDocSnap.data())                
    //         } else {                
    //             console.log("No User Data!");
    //         }
    //     }                          

    //     return () => {
    //         unsubscribe();
    //     }
        
    // }, [] );



    useEffect(() => {
    const fetchBusinessDocuments = async () => {
        const q = query(collection(db, `users/${uid}/business`));
        const querySnapshot = await getDocs(q);
    
        const documents = querySnapshot.docs.map((doc) => doc.data());
        setBusinessDocuments(documents);
    };
    
    fetchBusinessDocuments();
    }, [uid, businessName, businessDesc]);


   

    //console.log(businessId)
    console.log('bid:', businessDocuments[0]?.id)


    const uploadBusinessImgHandler = (e) => {
        setUploadImgFileName(e.currentTarget.files[0]);
        const file = e.currentTarget.files[0];
        uploadFiles(file);
    }


    const uploadFiles = (file) => {
        //
        if (!file) return;
        const sotrageRef = ref(storage, `businesses/${file.name + moment().format()}`);
        const uploadTask = uploadBytesResumable(sotrageRef, file);

        uploadTask.on(
        "state_changed",
        (snapshot) => {
            const prog = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgress(prog);
        },
        (error) => console.log(error),
        () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            //console.log("File available at", downloadURL);
            setBusinessImg(downloadURL);
            });
        }
        );
    };


    const handleBusinessCategoryChange = (value) => {        
        setBusinessCategory(value);                      
    }


    const submitForm = async(e) => {
        e.preventDefault();

        setSubmitLoading(current => true)
        if (isFormValid === false) setFormErrMsg(t('Business name, Business category are required'));

        if (user && isFormValid === true) {
        // User is signed in            

            const userDocRef = doc(db, "users", uid);
            const userBusinessColRef = collection(userDocRef, "business")    


            //const bdocRef = doc(db, `users/${uid}/business`, businessDocuments[0]?.id);
            //const bdocSnap = await getDoc(bdocRef);


            if (isCompleted === false && registerStep === 2 ) {                                               

                await setDoc(doc(db, `users/${uid}/business`, businessDocuments[0]?.id), {                
                    uid: uid,
                    businessName: businessName,
                    businessDesc: businessDesc,
                    domainName: domainName,
                    isDomainNameActive: false,
                    businessImg: businessImg,
                    businessCategory: businessCategory,
                    isActive: true,
                    createdAt: serverTimestamp(),
                }, {merge: true})
                .then(docRef => {           
    
                    setDoc(docRef, {id: docRef.id}, { merge:true })    
                    setBusinessId(docRef.id)  
                                                                    
                })
                .catch(error => console.error("Error adding document: ", error))
    
                await setDoc(doc(db, "users", uid), {                
                    registerStep: 2,
                }, {
                    merge: true
                });            
    
                
                navigate('/app/create-main-branch');


            } else {
                await addDoc(userBusinessColRef, {                                 
                    uid: uid,
                    businessName: businessName,
                    businessDesc: businessDesc,
                    domainName: domainName,
                    isDomainNameActive: false,
                    businessImg: businessImg,
                    businessCategory: businessCategory,
                    isActive: true,
                    createdAt: serverTimestamp(),
                })
                .then(docRef => {           
    
                    setDoc(docRef, {id: docRef.id}, { merge:true })    
                    setBusinessId(docRef.id)  
                                                                    
                })
                .catch(error => console.error("Error adding document: ", error))
    
                await setDoc(doc(db, "users", uid), {                
                    registerStep: 2,
                }, {
                    merge: true
                });            
    
                
                navigate('/app/create-main-branch');
    
                // navigate('/create-main-branch', {
                //     state: {
                //         uid: uid,
                //         businessId: businessId,                    
                //     }
                // });  
            }                      

        } else {
            // No user is signed in.
        }

    }








    return (
        user === null || uid === null ? (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='fixed w-screen h-screen inset-0 z-50 flex justify-center items-center'><div className='spinner'></div></motion.div>
          ) : (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                //animate={{ opacity: 1, transition: { duration: 1 } }}
                exit={{ opacity: 0 }}
                >

                    <HeaderOfUnAuth title = {t('Create Business')} />
                    <div className='upper_container xs:mt-10 md:mt-10'>
                        <BusinessWizard firstStepColor='text-primary' secondStepColor='text-primary' thirdStepColor='text-neutral/10' oneToTwoColor='bg-primary' twoToThreeColor='bg-neutral/10' />
                        <div className='mt-10'>
                            <form>
                                <div className='mb-7'>
                                    <strong className='text-neutral/50 uppercase text-2xs block'>{t('Business Details')}</strong>
                                    <div className="flex w-full mt-4">
                                        <Input 
                                        type='text' 
                                        className={businessName.length > 0 ? "bg-teal-600/10" : null}
                                        value={businessName} 
                                        onChange={(e)=>setBusinessName((e.target.value))} 
                                        color="blue" 
                                        size="lg" 
                                        label={t('Business name')}
                                        icon={<CgRename fontSize={20} className={"text-neutral/50"} />}
                                        onBlur={(e) => {
                                            setBusinessNameErrMsg(t('Business name is required'))
                                            if (businessName.length <= 0) { 
                                            e.target.classList.add("bg-red-600/10");
                                            e.target.classList.remove("bg-teal-600/10")
                                            } else {
                                            e.target.classList.add("bg-teal-600/10"); 
                                            e.target.classList.remove("bg-red-600/10")
                                            }
                                        }}  
                                        required/>
                                    </div>  
                                    {!businessName && !isFormValid ? (<p className="text-sm text-red-400">{businessNameErrMsg}</p>) : null}
                                    <div className="flex w-full mt-3">
                                        <Textarea label={t('Bussiness Description')} onChange={(e)=>setBusinessDesc((e.target.value))} />
                                    </div>      
                                    <div className="flex w-full mt-4">
                                        <Input type='text' className='' onChange={(e)=>setDomainName((e.target.value))} color="blue" size="lg" label={t('Domain name')} />
                                    </div>                           
                                    <div className="flex w-full">
                                        {/* <Input type='file' className='block w-full text-sm text-gray-500 file:mr-4 file:py-0 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100' color="blue" size="lg" label="Profile photo" icon={<BsPersonBadge fontSize={20} className={'text-neutral/50'} />} /> */}
                                        <div className="flex flex-row justify-center items-center gap-3 w-full mt-3 border border-gray-400 rounded-md p-3">
                                            <div>
                                                {businessImg ?
                                                    <img className="mask mask-squircle w-12 h-12 object-cover" src={businessImg} alt='' />
                                                :
                                                    <div className="mask mask-squircle w-12 h-12 object-cover bg-black/10 flex justify-center items-center">
                                                        <strong className='text-white text-xs'>{progress === 0 ? <MdBusinessCenter fontSize={20} /> : progress + '%'}</strong>
                                                    </div>
                                                }
                                            </div>
                                            <Input type='file' onChange={uploadBusinessImgHandler} className='block w-full text-sm text-gray-500 file:mr-4 file:py-0 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100' color="blue" size="lg" label={t('Business Logo')} />
                                        </div>
                                    </div>
                                    <div className="flex w-full mt-3">      
                                        <Select
                                            placeholder = {t('Business category') + ' *'}
                                            value={businessCategory}
                                            onChange={handleBusinessCategoryChange}
                                            options={businessCategoryOptions}           
                                            className="border !border-gray-400 rounded-md"                                                                     
                                        />                                     
                                    </div>

                                    {
                                        isFormValid === false && formErrMsg !== "" ? (
                                        <div className="alert alert-warning shadow-lg mt-5">
                                            <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                                            <span>{formErrMsg}</span>
                                            </div>
                                        </div>
                                        ) : null
                                    }


                                    <div className='mt-7'>

                                        { uploadImgFileName ? (
                                            progress < 100 ? (
                                                <button 
                                                onClick={submitForm}
                                                type="button" 
                                                className="
                                                rounded-md
                                                font-bold
                                                uppercase
                                                text-xs
                                                w-full
                                                py-5
                                                text-white
                                                hover:text-white
                                                focus:text-white
                                                bg-primary
                                                hover:bg-primary
                                                focus:bg-primary
                                                disabled:opacity-25
                                                cursor-not-allowed
                                                "                                            
                                                disabled                                                                                                          
                                                >                                    
                                                    {t('Next')}
                                                </button>
                                            ) : (
                                                <motion.button 
                                                onClick={submitForm}
                                                type="button" 
                                                className="
                                                rounded-md
                                                font-bold
                                                uppercase
                                                text-xs
                                                w-full
                                                py-5
                                                text-white
                                                hover:text-white
                                                focus:text-white
                                                bg-primary
                                                hover:bg-primary
                                                focus:bg-primary
                                                disabled:opacity-25
                                                cursor-pointer
                                                "
                                                whileTap={{ scale: 0.9 }}                                                                                                                                                        
                                                >                                    
                                                    {t('Next')}
                                                </motion.button>
                                            )
                                        ) : (
                                            <motion.button 
                                            onClick={submitForm}
                                            type="button" 
                                            className={`
                                            rounded-md 
                                            font-bold 
                                            uppercase 
                                            text-xs 
                                            w-full 
                                            flex 
                                            justify-center 
                                            items-center 
                                            py-5 
                                            text-white                         
                                            ${submitLoading === true ? 'bg-primary/50 cursor-wait ':'bg-primary hover:text-white focus:text-white hover:bg-primary focus:bg-primary cursor-pointer '}
                                            `}
                                            disabled={
                                                submitLoading
                                            }
                                            whileTap={{ scale: 0.9 }}                                                                                                                                                        
                                            >                                    
                                                {submitLoading ? (<Lottie animationData={LoadingAnimation} style={{ width: 19, height: 19 }} loop={true} />) : t('Next')}                  
                                            </motion.button>                                        
                                        ) }                                                                   
                                        
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
            </motion.div>
          )
    )
}

export default CreateBusiness
