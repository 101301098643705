import React, { Fragment, useEffect, useState } from 'react';
import { db } from '../../utils/firebase-config.js';
import { doc, onSnapshot, collection, getDocs, query, orderBy } from "firebase/firestore";
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import quecks_logo from '../../assets/images/quecks_logo.png';
import user_img from '../../assets/images/user.png'

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
  Tooltip,
  IconButton
} from "@material-tailwind/react";


const FLUserDetails = () => {


  const params = useParams();
  const location = useLocation();

  const [businessData, setBusinessData] = useState(null)
  const [refBid, setRefBid] = useState(null)
  

  const [branches, setBranches] = useState([]);

  const uid = params?.uid
  
  const { 
    passedFirstName = null,
    passedLastName = null,
    passedImgUrl = null,
    passedPhoneNumber = null,
    passedEmail = null,
  } = location.state || {};


  useEffect ( () => {
    const businessCollectionRef = collection(db, "users", uid, "business");
    onSnapshot(businessCollectionRef, (querySnapshot) => {                
      const userBusinessInfo = querySnapshot.docs.map((doc)=>({
          ...doc.data()
      }))                
      const bid = userBusinessInfo[0].id;  
      setRefBid(bid)
      //console.log('bid: ', bid)                          
      onSnapshot(doc(db, `users/${uid}/business`, bid), (doc) => {
        if (doc.exists()) {
          setBusinessData(doc.data());
        } else {
          console.log("Business document does not exist");
        }
      })
    }); 
  }, [uid] )

  // useEffect(() => {
  //   const businessDataRef = doc(db, `users/${uid}/business`, bid);
  //   const businessDataUnsub = onSnapshot(businessDataRef, (doc) => {
  //     if (doc.exists()) {
  //       setBusinessData(doc.data());
  //     } else {
  //       console.log("Business document does not exist");
  //     }
  //   });
  //   return () => {
  //     businessDataUnsub();
  //   };
  // }, [uid, bid]);

  const getBranches = async () => {
    const users = await getDocs(query(collection(db, `users/${uid}/business/${refBid}/branches`), orderBy("createdAt", "asc")));
    setBranches(users.docs.map((doc) => ({ ...doc.data() })));
  };

  useEffect(() => {    
    getBranches();    
  }, [refBid]);



const Accordion = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mb-2 border rounded-md">
      <div className="flex justify-between items-center" onClick={toggleAccordion}>
        <h2 className='btn btn-link'>{title}</h2>
        <span className='btn btn-link'>{isOpen ? '-' : '+'}</span>
      </div>
      {isOpen && (
        <div className="p-4">
          {content}
        </div>
      )}
    </div>
  );
}


  // console.log('branches: ', branches);
  // console.log('bdata: ', businessData);


  const DCOM = () => {
    return (
      <Card className="w-full shadow-lg">
        <CardHeader floated={false} color="blue-gray">
          { businessData?.businessImg && <img className='object-cover w-full' src={businessData?.businessImg} alt={businessData?.businessName} /> }          
          <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-tr from-transparent via-transparent to-black/60 " />
          <IconButton
            size="sm"
            color="white"
            variant="text"
            className="!absolute top-4 right-4 rounded-md bg-teal-600 px-8"
          >
            {businessData?.isActive ? "Active" : "Inactive"}
          </IconButton>
        </CardHeader>
        <CardBody>
          <div className="mb-3 flex items-center justify-between">
            {
              businessData?.businessName && (
                <Typography variant="h5" color="blue-gray" className="font-medium">{businessData?.businessName}</Typography>
              )
            }     
            {
              businessData?.domainName ? (
                <Typography color="blue-gray" className="flex items-center gap-1.5 font-normal">{businessData?.domainName}</Typography>
              ) : (
                <Typography color="blue-gray" className="flex items-center gap-1.5 font-normal text-red-500">No Domain</Typography>
              )
            }                   
          </div>
          {
            businessData?.businessDesc && (
              <Typography color="gray">{businessData?.businessDesc}</Typography>
            )
          }  
          <div className='border border-x-0 border-t border-b mt-4 -mx-6 py-6 px-6'>
            <div>
              <strong className='text-neutral/50 uppercase text-2xs'>Branches</strong>
              <div className='mt-2'>
                {branches.map( (branch, indx) => (
                  <Accordion key={indx} title={branch?.branchName} content={(
                    <div className='flex flex-col'>
                      <strong className='text-neutral/50 uppercase text-2xs'>Active Sessions</strong>
                      <strong>{branch?.branchName}</strong>
                    </div>
                  )} />               
                ) )}
              </div>
            </div>
          </div>        
          <div className="group mt-8 inline-flex flex-wrap items-center gap-3">
            <Tooltip content="$129 per night">
              <span className="cursor-pointer rounded-full border border-blue-500/5 bg-blue-500/5 p-3 text-blue-500 transition-colors hover:border-blue-500/10 hover:bg-blue-500/10 hover:!opacity-100 group-hover:opacity-70">
                money
              </span>
            </Tooltip>
            <Tooltip content="Free wifi">
              <span className="cursor-pointer rounded-full border border-blue-500/5 bg-blue-500/5 p-3 text-blue-500 transition-colors hover:border-blue-500/10 hover:bg-blue-500/10 hover:!opacity-100 group-hover:opacity-70">
                wifi
              </span>
            </Tooltip>
            <Tooltip content="2 bedrooms">
              <span className="cursor-pointer rounded-full border border-blue-500/5 bg-blue-500/5 p-3 text-blue-500 transition-colors hover:border-blue-500/10 hover:bg-blue-500/10 hover:!opacity-100 group-hover:opacity-70">
                home
              </span>
            </Tooltip>
            <Tooltip content={`65" HDTV`}>
              <span className="cursor-pointer rounded-full border border-blue-500/5 bg-blue-500/5 p-3 text-blue-500 transition-colors hover:border-blue-500/10 hover:bg-blue-500/10 hover:!opacity-100 group-hover:opacity-70">
                tv
              </span>
            </Tooltip>
            <Tooltip content="Fire alert">
              <span className="cursor-pointer rounded-full border border-blue-500/5 bg-blue-500/5 p-3 text-blue-500 transition-colors hover:border-blue-500/10 hover:bg-blue-500/10 hover:!opacity-100 group-hover:opacity-70">
                fire
              </span>
            </Tooltip>
            <Tooltip content="And +20 more">
              <span className="cursor-pointer rounded-full border border-blue-500/5 bg-blue-500/5 p-3 text-blue-500 transition-colors hover:border-blue-500/10 hover:bg-blue-500/10 hover:!opacity-100 group-hover:opacity-70">
                +20
              </span>
            </Tooltip>
          </div>
        </CardBody>
        <CardFooter className="pt-3">
          <Button size="lg" fullWidth={true}>
            Reserve
          </Button>
        </CardFooter>
      </Card>
    )
  }


  return (
    uid === null || passedFirstName === null || passedLastName === null || passedImgUrl === null || passedPhoneNumber === null || passedEmail === null || businessData === null ? (
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='fixed w-screen h-screen inset-0 z-50 flex justify-center items-center'><div className='spinner'></div></motion.div>
    ) : (
      <div className='my-7'>
        <Link to={'/1612'} className='flex flex-col justify-center items-center mb-7'>
          <img src={quecks_logo} alt='quecks' width={50} />
          <h1 className='font-semibold text-3xl text-primary pt-2'>Quecks 1612</h1>
        </Link>
        <div className='w-full flex justify-center items-center'>
          <div className="flex flex-col justify-center items-center ">
            { passedImgUrl === "" ? (
              <div className='w-20'>
                <LazyLoadImage effect="blur" className="mask mask-squircle object-cover" src={user_img} alt={passedFirstName} />
              </div>
            ) : (
              <div className='w-20'>
                <LazyLoadImage effect="blur" className="mask mask-squircle object-cover" src={passedImgUrl} alt={passedFirstName} />              
              </div>
            ) }
            <div className="flex flex-col justify-center items-center mt-2">
              <h2 dir='auto' className="font-bold text-xl">{passedFirstName + " " + passedLastName}</h2>
              <div className=''>
              <a href={"tel:" + passedPhoneNumber}  className="badge badge-ghost badge-sm mr-1 bg-primary text-white border-primary rounded-md">{passedPhoneNumber}</a>
                <span className="badge badge-ghost badge-sm mr-1 rounded-md">{passedEmail}</span>         
              </div>
            </div>
            <div className='mt-5 mb-10'>
              <DCOM />
            </div>
            {/* <div className='mt-8'>
              <div className="stats w-full shadow-sm border border-gray-300">  
                <div className="stat">
                  <div className="stat-title uppercase text-sm">domain</div>
                  <div className="stat-value font-medium text-2xl">con.com</div>                                                
                </div>            
              </div>
              <div className="mt-3 stats w-full shadow-sm border border-gray-300">
                <div className="stat min-w-[170px]">              
                  <div className="stat-title uppercase text-sm">ti</div>
                  <div className="stat-value font-medium text-2xl">con</div>              
                </div>            
                <div className="stat min-w-[170px]">              
                  <div className="stat-title uppercase text-sm">ti</div>
                  <div className="stat-value font-medium text-2xl">con</div>              
                </div>                                  
              </div>
            </div> */}
          </div>
        </div>
      </div>
    )
  )
}

export default FLUserDetails