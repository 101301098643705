import React, {useState, useEffect} from 'react';
import { auth } from '../../utils/firebase-config';
import { signOut } from "firebase/auth";
import { BusinessWithBranch, DashboardElements } from '../app'
import { RiMenu4Fill, RiCloseLine, RiArrowDropRightLine, RiArrowDropLeftLine, RiSettings3Fill } from 'react-icons/ri';
import { AiOutlinePoweroff } from 'react-icons/ai';
import { motion } from "framer-motion";
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ChangeLangDir from '../../helper/ChangeLangDir';
import IfNoBranch from '../../helper/IfNoBranch';


const Header = (props) => {

    const IfNoBranchKey = IfNoBranch();
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [isMenueOpened, setisMenueOpened] = useState(false);
    const user = auth.currentUser;

    
    

    
    const openMenueHandle = () => {
        setisMenueOpened( current => !current );        
    }
    
    const logoutHandler = (e) => {
      e.preventDefault();
      signOut(auth).then(() => {
        // Sign-out successful.
        navigate('/app');     
        //localStorage.setItem('selectedBranch', 'none');   
        //localStorage.clear();   
        localStorage.removeItem(`selectedBranch-${user?.uid}`);  
      }).catch((error) => {
        // An error happened.
        console.log(error)
      });            
    }



    

    return (
        <header className='flex gap-4 justify-between items-center h-12 xs:mt-4'> 
            { isMenueOpened ? 
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='fixed inset-0 z-40 w-screen h-screen bg-white/30 backdrop-blur-md cursor-pointer' onClick={(openMenueHandle)}></motion.div> : null
            }
            <div className='relative'>                
                <div className="flex items-center">                    
                    <motion.div onClick={openMenueHandle}
                    className='w-10 h-8 flex justify-center items-center bg-accent rounded-md cursor-pointer relative z-50'
                    initial={{ scale: 0 }}
                    animate={{ rotate: 180, scale: 1 }}
                    transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 20
                    }}                    
                    whileTap={{ scale: 1.5 }}                    
                    >                        
                        {
                            isMenueOpened ? 
                            <RiCloseLine fontSize={23} className={'text-primary'} />
                            :
                            <RiMenu4Fill fontSize={20} className={'text-primary'} />
                        }                                                
                    </motion.div>
                </div>                                
                <motion.div 
                className={`
                    fixed 
                    bottom-0 
                    left-0 
                    right-0 
                    container                     
                    xs:w-full 
                    sm:w-2/6 
                    3xl:w-2/6                     
                    backdrop-blur-md 
                    bg-white/10 
                    rounded-2xl
                    overflow-scroll
                    p-4
                    grow
                    
                    ${isMenueOpened && 'z-50' }
                `}                
                initial={false}
                animate={isMenueOpened ? {opacity: 1, y: 0} : {opacity: 0, y: "calc(100vh - 10%)" }}                
                style={{ boxShadow: '0px -10px 20px 0px rgba(0,0,0,0.10)', maxHeight: '80%' }}
                >
                    <div className='h-full'>
                        
                        <div className='relative backdrop-blur-md bg-white/70 rounded-2xl shadow-md p-5'>
                            <Link to={'/app/edit-business'} className='absolute top-5 right-5'>
                                <motion.button className='w-10 h-8 flex justify-center items-center bg-accent rounded-md cursor-pointer' whileTap={{ scale: .9 }}>
                                    <RiSettings3Fill fontSize={20} className={'text-primary cursor-pointer'} />
                                </motion.button>
                            </Link>                                                        
                            <BusinessWithBranch />                                                  
                        </div>

                        {
                            localStorage.getItem(`selectedBranch-${user?.uid}`) !== null ? (
                                <>
                                    <div className='mt-5'>                                                            
                                        <DashboardElements />                                       
                                    </div>

                                    <Link to={'/app/profile-settings'} className='w-full mt-5 btn flex flex-row justify-between items-center shadow-md'>
                                        <span>{t('Profile Settings')}</span>                            
                                        {
                                            i18n.language === 'en' ? <RiArrowDropRightLine fontSize={23} className={'text-white'} />
                                            : i18n.language === 'ar' ?<RiArrowDropLeftLine fontSize={23} className={'text-white'} />
                                            : null
                                        }
                                    </Link>

                                    <ChangeLangDir classNameProp="btn gap-2 w-full mt-4"/>
                                </>
                            ) : null
                        }                                                                

                        <div className='mt-5 w-full flex flex-row justify-between items-center'>
                            <p className='font-medium text-xs opacity-70'>Quecks — Version 1.0.1</p>
                            <Link to={{  }}>
                                <motion.button className='flex justify-center items-center backdrop-blur-md bg-white/50 rounded-full shadow-md cursor-pointer p-4' whileTap={{ scale: .9 }} onClick={logoutHandler}>
                                    <AiOutlinePoweroff fontSize={20} className={'text-red-200 cursor-pointer'} />
                                </motion.button>
                            </Link>
                        </div>
                        
                        {/* <nav className='h-full'>
                            <ul className="">
                                <li className="active"><a href="index.html" className="block text-sm px-2 py-4 text-white bg-green-500 font-semibold">Home</a></li>
                                <li><a href="#services" className="block text-sm px-2 py-4 hover:bg-green-500 transition duration-300">Services</a></li>
                                <li><a href="#about" className="block text-sm px-2 py-4 hover:bg-green-500 transition duration-300">About</a></li>
                                <li><a href="#contact" className="block text-sm px-2 py-4 hover:bg-green-500 transition duration-300">Contact Us</a></li>
                            </ul>
                        </nav> */}

                    </div>
                </motion.div>
            </div>
            <div className='text-center'>
                { props.logo ? 
                        <img src={props.logo} width='116' alt='' />
                    :
                        <span className='text-base font-medium text-neutral/50'>{ props.title}</span>
                }
            </div>
            <div className={`w-10 h-8 flex justify-center items-center rounded-md ${props.extraNavAction && 'bg-accent cursor-pointer' }`}>
                {props.extraNavAction}
            </div>
            {IfNoBranchKey}
        </header>
    )
}

export default Header