import React, { useState, useEffect } from 'react';
import { db, auth } from '../../utils/firebase-config';
import { onAuthStateChanged } from "firebase/auth";
import { useTranslation } from 'react-i18next';
import { doc, setDoc, addDoc, collection, serverTimestamp, getDocs, query, getDoc, onSnapshot } from "firebase/firestore";
import { HeaderOfUnAuth, BusinessWizard } from '../../components/app';
import { motion } from "framer-motion";
import { useNavigate, useLocation } from "react-router-dom";
import { Input, Textarea } from "@material-tailwind/react";
import Select from 'react-tailwindcss-select';
import { TbDeviceMobile, TbBrandWhatsapp } from 'react-icons/tb';
import useLocalStorage from '../../helper/useLocalStorage'
import Lottie from "lottie-react";
import LoadingAnimation from '../../assets/jsons/lottie/loading.json'




const CreateMainBranch = () => {

    const {t} = useTranslation();

    const governorateOptions = [
        {value: "cairo", label: t('Cairo')},  
        {value: "giza", label: t('Giza')},  
        {value: "alexandria", label: t('Alexandria')},  
        {value: "ismailia ", label: t('Ismailia')},  
        {value: "aswan ", label: t('Aswan')},  
        {value: "asyut ", label: t('Asyut')},  
        {value: "beheira ", label: t('Beheira')},  
        {value: "beni-suef", label: t('Beni Suef')},  
        {value: "dakahlia", label: t('Dakahlia')},  
        {value: "damietta", label: t('Damietta')},  
        {value: "faiyum", label: t('Faiyum')},  
        {value: "gharbia", label: t('Gharbia')},  
        {value: "kafr-el-sheikh", label: t('Kafr El Sheikh')},  
        {value: "luxor", label: t('Luxor')},  
        {value: "matruh", label: t('Matruh')},  
        {value: "minya", label: t('Minya')},  
        {value: "monufia", label: t('Monufia')},  
        {value: "new-valley", label: t('New Valley')},  
        {value: "north-sinai", label: t('North Sinai')},  
        {value: "port-said", label: t('Port Said')},  
        {value: "qalyubia", label: t('Qalyubia')},  
        {value: "qena", label: t('Qena')},  
        {value: "red-sea", label: t('Red Sea')},  
        {value: "sharqia", label: t('Sharqia')},  
        {value: "sohag", label: t('Sohag')},  
        {value: "south-sinai", label: t('South Sinai')},  
        {value: "suez", label: t('Suez')},  
    ];

    
  
   

    const navigate = useNavigate();
    //const location = useLocation();

    const user = auth.currentUser;
    //const [user, setUser] = useState(null)
    //const [uid, setUid] = useState(null) 
    const uid = user?.uid;


    // useEffect( () => {

    //     const un = () => {            
    //         if (user) {
    //             //setUser(auth.currentUser);
    //             setUid(user.uid);                  
    //         }
    //     };

    //     return (un);

    // }, [user] )


    //const businessId = location.state.businessId;      

    //const [userData, setUserData] = useState({});    

    const [branchName, setBranchName] = useState('');
    const [branchPhone, setBranchPhone] = useState('');  
    const [branchWhatsapp, setBranchWhatsapp] = useState('');  
    const [governorate, setGovernorate] = useState(null);
    const [area, setArea] = useState('');
    const [fullAddress, setFullAddress] = useState('');    
    const [businessId, setBusinessId] = useState('');    
    //const [branchId, setBranchId] = useState('');

    const [isFormValid, setIsFormValid] = useState(null);
    const [formErrMsg, setFormErrMsg] = useState("");  
    const [branchNameErrMsg, setBranchNameErrMsg] = useState(""); 
    const [branchPhoneErrMsg, setBranchPhoneErrMsg] = useState(""); 
    const [branchWhatsappErrMsg, setBranchWhatsappErrMsg] = useState(""); 

    const [submitLoading, setSubmitLoading] = useState(false)


    const [storedValue, setStoredValue] = useLocalStorage(
        `selectedBranch-${user?.uid}`,
        null
    );


    useEffect ( () => {
        !branchName || !branchPhone || !branchWhatsapp || !governorate ? setIsFormValid(current => false) : setIsFormValid(current => true) 
    }, [branchName, branchPhone, branchWhatsapp, governorate] )  

    
    


    // useEffect( () => {
    //     const userDocRef = doc(db, "users", uid);
    //     const unsubscribe = async () => {  
  
    //         const userDocSnap = await getDoc(userDocRef);
    //         if (userDocSnap.exists()) {
    //             setUserData(userDocSnap.data())                
    //         } else {                
    //             console.log("No User Data!");
    //         }
            
    //     }

    //     //console.log(moment().format('MMMM Do YYYY, h:mm:ss a'))         
        

    //     return () => {
    //         unsubscribe(); 
    //     }
    // }, [] );




    // useEffect(() => {
    //     const unsubscribe = async () => {              
    //           const userCollectionQ = query(collection(db, 'users'))
    //           const snapshot = await getDocs(userCollectionQ)
    //           const data = snapshot.docs.map((doc)=>({
    //               ...doc.data(), id:doc.id
    //           }))
    //           data.map(async (elem)=>{
    //             const userBusinessQ = query(collection(db, `users/${elem.id}/business`))
    //             const userBusinessDetails = await getDocs(userBusinessQ)
    //             const userBusinessInfo = userBusinessDetails.docs.map((doc)=>({
    //                 ...doc.data(), id:doc.id
    //             }))
    //             setBusinessId(userBusinessInfo[0].id);
    //           })
    //         }
    //         return (() => {unsubscribe()})
    // }, [])


    useEffect( () => {

        const unsb = async () => {
          const userRef = doc(db, "users", user?.uid);
          const userDocSnap = await getDoc(userRef);

          if (userDocSnap.exists()) {
            const userDocResult = userDocSnap.data()
            const userDocId = userDocResult?.id
            

            let businessCollectionRef = collection(db, "users", userDocId, "business");

            onSnapshot(businessCollectionRef, (querySnapshot) => {
                
                const userBusinessInfo = querySnapshot.docs.map((doc)=>({
                    ...doc.data(), id:doc?.id
                }))

                setBusinessId(userBusinessInfo[0]?.id);
            });

            //localStorage.setItem('selectedBranch', branchName)
            setStoredValue(branchName)
            
          } else {
            console.log("no user document!")
          }
        }

        return (() => {unsb()})

    }, [user.uid, branchName, setStoredValue] )

    // console.log('bid ===== '+businessId)
    // console.log('stored branch:', storedValue)

    const handleGovernorateChange = (value) => {        
        setGovernorate(value);                      
    }

    // console.log('isFormValid', isFormValid)


    const submitForm = async (e) => {
        e.preventDefault();      
        
        setSubmitLoading(current => true)
        if (isFormValid === false) setFormErrMsg(t('Branch name, Branch phone, Governorate are required'));

        if (user && isFormValid === true) {        

            const userBusinessDocRef = doc(db, `users/${uid}/business`, businessId);        
            const businessBranchColRef = collection(userBusinessDocRef, "branches")    


            await addDoc(businessBranchColRef, { 
                uid: uid,
                businessid: businessId,
                branchName: branchName,
                branchPhone: branchPhone,
                branchWhatsapp: branchWhatsapp,
                governorate: governorate,
                area: area,
                fullAddress: fullAddress,
                isActive: true,                                
                createdAt: serverTimestamp(),
            }, { merge: false })
            .then(docRef => {                
                setDoc(docRef, {
                    id: docRef?.id
                }, { merge:true })    
                //setBranchId(docRef.id)                
                
            })
            .catch(error => console.error("Error adding document: ", error))            
            
            await setDoc(doc(db, "users", uid), {                
                registerStep: 3,
                isCompleted: true,
            }, {
                merge: true
            });

            // await setDoc(doc(db, `users/${uid}/business`, businessId), {                
            //     selectedBranch: branchName,
            // }, {
            //     merge: true
            // });        

        } else {
            // No user is signed in.
        }


        navigate('/app');       
    }

    
    

    return (
        user === null || uid === null || businessId === '' || storedValue === null ? (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='fixed w-screen h-screen inset-0 z-50 flex justify-center items-center'><div className='spinner'></div></motion.div>
          ) : (
            <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                //animate={{ opacity: 1, transition: { duration: 1 } }}
                exit={{ opacity: 0 }}               
                >                
                    
                    <HeaderOfUnAuth title = {t('Create Main Branch')} />                
                    <div className='upper_container xs:mt-10 md:mt-10'>                    
                        <BusinessWizard firstStepColor='text-primary' secondStepColor='text-primary' thirdStepColor='text-primary' oneToTwoColor='bg-primary' twoToThreeColor='bg-primary' />
                        <div className='mt-10'>
                            <form>
                                <div className='mb-7'>
                                    <strong className='text-neutral/50 uppercase text-2xs block'>{t('Main Branch Details')}</strong>
                                    <div className="flex w-full mt-4">
                                        <Input 
                                        type='text' 
                                        className={branchName.length > 0 ? "bg-teal-600/10" : null} 
                                        value={branchName}
                                        onChange={(e)=>setBranchName((e.target.value))} 
                                        color="blue" 
                                        size="lg" 
                                        label={t('Branch name')} 
                                        onBlur={(e) => {
                                            setBranchNameErrMsg(t('Branch name is required'))
                                            if (branchName.length <= 0) { 
                                                e.target.classList.add("bg-red-600/10");
                                                e.target.classList.remove("bg-teal-600/10")
                                            } else {
                                                e.target.classList.add("bg-teal-600/10"); 
                                                e.target.classList.remove("bg-red-600/10")
                                            }
                                        }}  
                                        required
                                        />
                                    </div>
                                    {!branchName && !isFormValid ? (<p className="text-sm text-red-400">{branchNameErrMsg}</p>) : null}
                                    <div className="flex w-full mt-3">
                                        <Input 
                                        type='number' 
                                        className={branchPhone.length > 0 ? "bg-teal-600/10" : null} 
                                        value={branchPhone} 
                                        onChange={(e)=>setBranchPhone((e.target.value), setBranchWhatsapp(e.target.value))} 
                                        color="blue" 
                                        size="lg" 
                                        label={t('Branch phone number')} 
                                        icon={<TbDeviceMobile fontSize={20} className={'text-neutral/50'} />} 
                                        onBlur={(e) => {
                                            setBranchPhoneErrMsg(t('Branch phone number is required'))
                                            if (branchPhone.length <= 0) { 
                                                e.target.classList.add("bg-red-600/10");
                                                e.target.classList.remove("bg-teal-600/10")
                                            } else {
                                                e.target.classList.add("bg-teal-600/10"); 
                                                e.target.classList.remove("bg-red-600/10")
                                            }
                                        }}  
                                        required
                                        />
                                    </div>
                                    {!branchPhone && !isFormValid ? (<p className="text-sm text-red-400">{branchPhoneErrMsg}</p>) : null}
                                    <div className="flex w-full mt-3">
                                        <Input 
                                        type='number' 
                                        className={branchWhatsapp.length > 0 ? "bg-teal-600/10" : null} 
                                        value={branchWhatsapp} 
                                        onChange={(e)=>setBranchWhatsapp((e.target.value))} 
                                        color="blue" 
                                        size="lg" 
                                        label={t('Branch Whatsapp number')} 
                                        icon={<TbBrandWhatsapp fontSize={20} className={'text-neutral/50'} />} 
                                        onBlur={(e) => {
                                            setBranchWhatsappErrMsg(t('Branch Whatsapp number is required'))
                                            if (branchWhatsapp.length <= 0) { 
                                                e.target.classList.add("bg-red-600/10");
                                                e.target.classList.remove("bg-teal-600/10")
                                            } else {
                                                e.target.classList.add("bg-teal-600/10"); 
                                                e.target.classList.remove("bg-red-600/10")
                                            }
                                        }}  
                                        required
                                        />
                                    </div>
                                    {!branchWhatsapp && !isFormValid ? (<p className="text-sm text-red-400">{branchWhatsappErrMsg}</p>) : null}
                                    <div className="flex w-full mt-3">
                                        <Select
                                            placeholder = {t('Governorate')}
                                            value={governorate}
                                            onChange={handleGovernorateChange}
                                            options={governorateOptions}           
                                            className="border border-gray-400 rounded-md "                             
                                        /> 
                                        {/* <Select label="Governorate">
                                            <Option>Cairo</Option>                                        
                                            <Option>Giza</Option>                                        
                                            <Option>Alexandria</Option>                                        
                                            <Option>Ismailia</Option>                                                                               
                                        </Select> */}
                                    </div> 
                                    <div className="flex w-full mt-3">
                                        <Input type='text' className='' onChange={(e)=>setArea((e.target.value))} color="blue" size="lg" label={t('Area')} />
                                    </div>    
                                    <div className="flex w-full mt-3">
                                        <Textarea label={t('Full address')} onChange={(e)=>setFullAddress((e.target.value))} />
                                    </div>   


                                    {
                                        isFormValid === false && formErrMsg !== "" ? (
                                        <div className="alert alert-warning shadow-lg mt-5">
                                            <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                                            <span>{formErrMsg}</span>
                                            </div>
                                        </div>
                                        ) : null
                                    }                         
                                    
                                                                    
                                    
                                    <div className='mt-7'>  

                                        {
                                            isFormValid === false ? (
                                                <motion.input 
                                                onClick={submitForm}
                                                type='submit' 
                                                value={t('Finish')} 
                                                className="              
                                                rounded-md 
                                                font-bold 
                                                uppercase 
                                                text-xs 
                                                w-full 
                                                py-5
                                                text-white 
                                                hover:text-white 
                                                focus:text-white 
                                                bg-primary 
                                                hover:bg-primary 
                                                focus:bg-primary      
                                                disabled:opacity-25
                                                cursor-not-allowed                  
                                                "
                                                disabled                                   
                                                />
                                            ) : (
                                                <motion.input 
                                                onClick={submitForm}
                                                type='submit' 
                                                value={submitLoading ? t('Finishing Setup') : t('Finish')}
                                                className={`
                                                rounded-md 
                                                font-bold 
                                                uppercase 
                                                text-xs 
                                                w-full 
                                                flex 
                                                justify-center 
                                                items-center 
                                                py-5 
                                                text-white                         
                                                ${submitLoading === true ? 'bg-primary/50 cursor-wait ':'bg-primary hover:text-white focus:text-white hover:bg-primary focus:bg-primary cursor-pointer '}
                                                `}
                                                disabled={submitLoading}
                                                whileTap={{ scale: 0.9 }}                                    
                                                />                                            
                                            )
                                        }


                                        
                                    </div> 
                                </div> 
                                
                            </form>
                        </div>
                    </div>
            </motion.div>
          )
    )
}

export default CreateMainBranch