import React, { useState, useEffect, useMemo } from 'react';
import { db, auth } from '../../utils/firebase-config.js';
import { useTranslation } from 'react-i18next';
import { onSnapshot, doc, query, collection, orderBy, where} from "firebase/firestore";
import { GlobalState } from '../../context/StateContext';
//import GetCurrentBranch from '../../helper/GetCurrentBranch';
import NewGetCurrentBranch from "../../helper/NewGetCurrentBranch";
import { Header, SessionInfoCap } from '../../components/app';
import { motion } from "framer-motion";
import { MdNotifications } from 'react-icons/md';
import { TbQrcode } from 'react-icons/tb';
import { IoCopyOutline } from 'react-icons/io5';
import { BsWhatsapp } from "react-icons/bs";
import { RiPhoneFill, RiCloseCircleLine } from "react-icons/ri";
import { TbExternalLink, TbNotebook, TbEdit } from "react-icons/tb";
import { Link, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { arSA } from 'date-fns/locale';
import Dayjs from "dayjs";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {QRCodeSVG} from 'qrcode.react';

const AllTickets = () => {

  const {t, i18n} = useTranslation();
  const params = useParams();  
  const user = auth.currentUser;
  const uidRef = GlobalState()?.userData?.id;
  const businessID = GlobalState()?.businessData?.id; 
  // const retrevedCurrentSelectedBranchID = GetCurrentBranch();
  // const [currentSelectedBranchID, setCurrentSelectedBranchID] = useState();
  const currentSelectedBranchID = NewGetCurrentBranch();
  const [isLoading, setIsloading] = useState(true);
  const [sessionData, setSessionData] = useState({});    
  const [queus, setQueues] = useState([]);    
  const [copied, setCopied] = useState(false);    
  const [showQrCode, setShowQrCode] = useState(false);    
  const [qRid, setQRid] = useState("");    
  const [qRfirstName, setQRfirstName] = useState("");    
  const [qRlastName, setQRlastName] = useState("");    
  const [qRTicketNumber, setQRTicketNumber] = useState("");    

  const [showNote, setShowNote] = useState(false);    
  const [noteFirstName, setNotefirstName] = useState("");    
  const [noteLastName, setNotelastName] = useState("");    
  const [noteTicketNumber, setNoteTicketNumber] = useState("");   
  const [noteText, setNoteText] = useState("");   
  

  const businessDomainName = GlobalState()?.businessData?.domainName; 
  const businessDomainNameActive = GlobalState()?.businessData?.isDomainNameActive; 
  const [rootDomainName, setRootDomainName] = useState(null)


  const [searchTerm, setSearchTerm] = useState('');
  const [filteredQueues, setFilteredQueues] = useState(queus);



  const nowTime = parseInt( new Date().getTime() / 1000 );      
  const sessionEndDateTime = sessionData?.endDate?.seconds     
  const sessionRemainingEquation = parseInt ( (sessionEndDateTime - nowTime) / 60  )
  const [sessionRemainingHr, setSessionRemainingHr] = useState("")
  const [sessionRemainingMin, setSessionRemainingMin] = useState("")

  useEffect ( () => {
    const sessionRemainingTimeUnsub = () => {            
      setSessionRemainingHr( Number(parseInt (sessionRemainingEquation / 60)) )
      setSessionRemainingMin( Number(sessionRemainingEquation % 60) )                  
    } 
    return sessionRemainingTimeUnsub;
  } )


  const totalSessionMinutes = (sessionEndDateTime - nowTime) / 60;
  const remainingMinutes = sessionRemainingHr * 60 + sessionRemainingMin;
  const remainingPercentage = (remainingMinutes / totalSessionMinutes) * 100;



  useEffect( () => {
    setFilteredQueues(queus)
  }, [queus] )


  // retrevedCurrentSelectedBranchID.then(function(currentSelectedBranchIDResult) {    
  //   try {
  //     setCurrentSelectedBranchID(currentSelectedBranchIDResult)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }) 

  useEffect ( () => {
    if (businessDomainName && businessDomainNameActive === true) {
    setRootDomainName(businessDomainName)    
    } else {
      setRootDomainName(process.env.REACT_APP_DOMAIN)
    } 
  }, [businessDomainName, businessDomainNameActive] )

  //console.log('domain: ', rootDomainName)

  const sessionId = params.id;  

  useEffect(() => {
    if (user && localStorage.getItem(`selectedBranch-${user?.uid}`) !== null && localStorage.getItem(`selectedBranch-${user?.uid}`) !== undefined && localStorage.getItem(`selectedBranch-${user?.uid}`) !== '' ) {
        setIsloading(false)
        if (isLoading === false) {
            const unsub = onSnapshot(doc(db, `users/${uidRef}/business/${businessID}/branches/${currentSelectedBranchID}/sessions/${sessionId}`), (doc) => {
                //console.log("Current data: ", doc.data());
                setSessionData(doc.data())
            });
            return () => unsub();
        }  
        
    }
       
  }, [user, uidRef, businessID, currentSelectedBranchID, isLoading, sessionId]);  


  useEffect(() => {
    if (user && localStorage.getItem(`selectedBranch-${user?.uid}`) !== null && localStorage.getItem(`selectedBranch-${user?.uid}`) !== undefined && localStorage.getItem(`selectedBranch-${user?.uid}`) !== '' ) {
      const queuesUnsub = onSnapshot( 
          query( collection( db, `users/${uidRef}/business/${businessID}/branches/${currentSelectedBranchID}/sessions/${sessionId}/queues` ),
            where("ticketMove", "==", 'enqueue'),
            orderBy("ticketNumber", "asc"),            
          ),
          (querySnapshot) => {
            const queuesTickets = querySnapshot.docs.map((doc) => {
              return {
                ...doc.data(),
                id: doc.id,
              };
            });
            setQueues(queuesTickets);
          }
        );
      return () => queuesUnsub();
    }
  }, [user, businessID, currentSelectedBranchID, uidRef, sessionId]);

  const CopiedToast = () => {
    
    return (
      <>
        <div className="fixed inset-x-0 bottom-0 z-40 flex justify-center items-center p-1 rounded-md bg-success mb-4 mx-40 drop-shadow-xl">        
            <span className='text-white uppercase font-semibold text-xs'>{t("Copied")}</span>
        </div> 
        {
          setTimeout(() => {
            setCopied(current => false)
          }, 1000)
        }
      </>
    );
    
  }

  const showQrCodeHandler = (id, firstName, lastName, ticketNumber) => {              
    setShowQrCode(current => true)
    setQRfirstName (firstName)
    setQRlastName (lastName)
    setQRTicketNumber (ticketNumber)
    setQRid(id)
    
    console.log(id, firstName, lastName)        
  }

  const showQrCodeReseter = () => {
    setShowQrCode(current => false)
    setQRfirstName ("")
    setQRlastName ("")
    setQRTicketNumber ("")
    setQRid("")
  }

  const showNoteHandler = (firstName, lastName, ticketNumber, ticketNote) => {              
    setShowNote(current => true)
    setNotefirstName (firstName)
    setNotelastName (lastName)
    setNoteTicketNumber (ticketNumber)
    setNoteText(ticketNote)
    
    console.log(firstName, lastName)        
  }

  const showNoteReseter = () => {
    setShowNote(current => false)
    setNotefirstName ("")
    setNotelastName ("")
    setNoteTicketNumber ("")
    setNoteText("")
  }
  
  const AllQueuesComponent = () => {
    return (
      <>
        {
          filteredQueues.length > 0 ? (
          filteredQueues.map( (queue) => (
            <div key={queue.id} className="bg-accent mb-3 rounded-md p-5">
              <div className='flex flex-row justify-between items-start mb-2'>
                <div className='w-full'>
                  <strong className='text-xl'>{queue?.firstName + " " + queue?.lastName}</strong>                  
                  <div className='flex flex-row justify-start items-start mt-1'>
                    <div className='flex flex-col '>
                      <span className='text-neutral/60 text-sm'>{t("Paid")}</span>
                      <span className='font-bold -mt-1'>{queue.amountPaid ? queue?.amountPaid : 0} <small>{t('EGP')}</small></span>
                    </div>
                    <div className='flex flex-col ltr:ml-5 rtl:mr-5'>
                      <span className='text-neutral/60 text-sm'>{t('Due')}</span>
                      <span className='font-bold -mt-1'>{queue.amountDue ? queue?.amountDue : 0} <small>{t('EGP')}</small></span>
                    </div>
                  </div>
                </div>
                <div className='bg-neutral text-white shadow-md shadow-blue-500/10 rounded-md font-bold text-3xl px-10 py-9 w-12 h-9 flex justify-center items-center '>
                  {queue?.ticketNumber}
                </div>
              </div>
              <div className='flex flex-row justify-start items-center mt-5'>
                <motion.a href={`tel:${queue?.phoneNumber}`} whileTap={{ scale: 0.6 }} className="bg-white rounded-md p-2 shadow-sm ltr:mr-2 rtl:ml-2 flex items-center">
                  <RiPhoneFill fontSize={16} className={"text-neutral"} />    
                  {/* <small className='ml-1 uppercase font-semibold text-xs'>Call</small>           */}
                </motion.a>
                <motion.a 
                  href={`https://wa.me/+2${queue?.phoneNumber}?text=${queue?.businessName},%20Your%20tracking%20link%20is:%20${rootDomainName}/app/tracking/${uidRef}/${businessID}/${currentSelectedBranchID}/${sessionId}/${queue?.id}`}
                  target="_blank"
                  whileTap={{ scale: 0.6 }} className="bg-white rounded-md p-2 shadow-sm ltr:mr-2 rtl:ml-2 flex items-center"
                >
                  <BsWhatsapp fontSize={16} className={"text-neutral"} />                          
                </motion.a>
                <button className="bg-white rounded-md p-2 shadow-sm ltr:mr-2 rtl:ml-2 cursor-not-allowed opacity-50 flex items-center" disabled>
                  <MdNotifications fontSize={16} className={"text-neutral"} />               
                </button>
                <motion.button whileTap={{ scale: 0.6 }} className="bg-white rounded-md p-2 shadow-sm ltr:mr-2 rtl:ml-2 flex items-center">
                  <CopyToClipboard text={`${rootDomainName}/app/tracking/${uidRef}/${businessID}/${currentSelectedBranchID}/${sessionId}/${queue.id}`}
                    onCopy={() => setCopied(current => true)}>
                    <IoCopyOutline fontSize={16} className={"text-neutral"} />
                  </CopyToClipboard>                            
                </motion.button>
                <motion.a
                className="bg-white rounded-md p-2 shadow-sm ltr:mr-2 rtl:ml-2 flex items-center"
                href={`${rootDomainName}/app/tracking/${uidRef}/${businessID}/${currentSelectedBranchID}/${sessionId}/${queue.id}`}                           
                target="_blank"  
                whileTap={{ scale: 0.6 }}     
                >
                  <TbExternalLink fontSize={16} className={"text-neutral"} />              
                </motion.a>
                <motion.button 
                whileTap={{ scale: 0.6 }} 
                className="bg-white rounded-md p-2 shadow-sm ltr:mr-2 rtl:ml-2 flex items-center"
                onClick={()=>{showQrCodeHandler(queue?.id, queue?.firstName, queue?.lastName, queue?.ticketNumber)}}
                >
                  <TbQrcode fontSize={16} className={"text-neutral"} />              
                </motion.button>     
                <motion.button 
                whileTap={{ scale: 0.6 }} 
                className="bg-white rounded-md p-2 shadow-sm ltr:mr-2 rtl:ml-2 flex items-center"
                onClick={()=>{showNoteHandler(queue?.firstName, queue?.lastName, queue?.ticketNumber, queue?.ticketNote)}}
                >
                  <TbNotebook fontSize={16} className={"text-neutral"} />              
                </motion.button>              
                <Link 
                className="bg-white rounded-md p-2 shadow-sm ltr:mr-2 rtl:ml-2 flex items-center"
                to={"/app/edit-ticket"} state={{ 
                passedSessionId: sessionId,
                passedTicketId: queue?.id, 
                passedFirstName: queue?.firstName, 
                passedLastName: queue?.lastName,
                passedPhoneNumber: queue?.phoneNumber,
                passedPaid: queue?.amountPaid, 
                passedDue: queue?.amountDue, 
                passedNote: queue?.ticketNote,                 
                }} 
                >
                  <TbEdit fontSize={16} className={"text-neutral"} />    
                </Link>
              </div>
              <div className='border-t mt-4 pt-4'>
                <div className='flex flex-col '>
                  <span className='text-neutral/60 text-sm'>{t("Created At")}</span>
                  <span className='italic text-sm'>                    
                    {format(queue?.createdAt?.seconds * 1000, 'dd MMMM yyyy - hh:mm aa', i18n.language === 'ar' && { locale: arSA })}
                  </span>
                </div>                
              </div>
            </div>
          ) ) )
          : null
        }
        {copied === true ? CopiedToast() : null}
        { showQrCode === true ?
          <div className='fixed inset-0 z-50 flex flex-col justify-center items-center rounded-md shadow-2xl backdrop-blur-md bg-white/80 mx-4 md:mx-80 my-40'>            
            <div className="relative flex flex-row items-start my-2">
              <span className="text-black/60 text-xl absolute -top-3 -left-3">#</span>
            <strong className="text-6xl">{qRTicketNumber}</strong>
          </div>
          <strong className='text-4xl mb-4'>{qRfirstName} {qRlastName}</strong>
          <QRCodeSVG 
          size={300}                
          value={`${rootDomainName}/app/tracking/${uidRef}/${businessID}/${currentSelectedBranchID}/${sessionId}/${qRid}`}                   
          />
          <button onClick={() => showQrCodeReseter()} className='flex justify-center items-center mt-4'>               
            <strong className='text-xs text-red-500 uppercase bg-red-300/10 py-4 px-7 rounded-md'>{t('Close')}</strong>
          </button>
          </div>
        : null
        }
        { showNote === true ?
          <div className='fixed inset-0 z-50 flex flex-col justify-center items-center rounded-md shadow-2xl backdrop-blur-md bg-white/80 mx-4 md:mx-80 my-40'>            
            <div className="relative flex flex-row items-start my-2">
              <span className="text-black/60 text-xl absolute -top-3 -left-3">#</span>
            <strong className="text-6xl">{noteTicketNumber}</strong>
          </div>
          <strong className='text-4xl mb-4'>{noteFirstName} {noteLastName}</strong>
          {noteText ? (
            <p>{noteText}</p>
          ) : (
            <p>No note to display!</p>
          )}
          
          <button onClick={() => showNoteReseter()} className='flex justify-center items-center mt-4'>               
            <strong className='text-xs text-red-500 uppercase bg-red-300/10 py-4 px-7 rounded-md'>{t('Close')}</strong>
          </button>
          </div>
        : null
        }
      </>      
    )    
  }

  const handleSearch = event => {
    setSearchTerm(event.target.value);
    setFilteredQueues(queus.filter(queue => queue.phoneNumber.includes(event.target.value)));
  }

  const handleReset = () => {
    setSearchTerm('');
    setFilteredQueues(queus);
  }


  return (
    <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        //animate={{ opacity: 1, transition: { duration: 1 } }}
        exit={{ opacity: 0 }}
      >
      
        <Header title={t('All Remaining Tickets')}/>

        <div className="upper_container xs:mt-10 md:mt-10 ">

          {isLoading === false && sessionData && (
            <SessionInfoCap
              name={sessionData?.sessionName}
              date={Dayjs(sessionData.startDate?.seconds * 1000).format(
                "ddd, DD MMM YYYY"
              )}
              startat={Dayjs(sessionData.startDate?.seconds * 1000).format(
                "h:mm A"
              )}
              endat={Dayjs(sessionData.endDate?.seconds * 1000).format(
                "h:mm A"
              )}
              url={`/session/watcher/${sessionId}`}
              remainingValue={sessionData.started === true ? (isNaN(remainingPercentage) ? null : remainingPercentage) : null}
              remainingTotal={isNaN(totalSessionMinutes) ? 0 : totalSessionMinutes}              
              remainingTime={
                sessionData.started === true
                  ? `${isNaN(sessionRemainingHr) ? '' : sessionRemainingHr}:${isNaN(sessionRemainingMin) ? '' : sessionRemainingMin} ${t('Remaining to end')}`
                  : null
              } 
            />
            )}

            <div className="mt-10">
              <div className='relative mb-5'>
                <input type="text" value={searchTerm} onChange={handleSearch} placeholder={t('Search by phone number')}
                className='input input-bordered w-full' />
                {
                  searchTerm && (
                    <button onClick={handleReset} className="absolute top-3.5 ltr:right-3.5 rtl:left-3.5">
                      <RiCloseCircleLine
                          fontSize={20}
                          className={"text-neutral/80"}
                      />
                    </button>
                  )
                }                
              </div>
              <AllQueuesComponent />
            </div>          
        </div>
      </motion.div>
  )
}

export default AllTickets