import React from 'react';
//import { MdOutlineMoreHoriz } from 'react-icons/md';
import { RiArrowDropRightLine } from 'react-icons/ri';
import { LazyLoadImage } from 'react-lazy-load-image-component';


const Member = (props) => {
  
  return (
    <div className='rounded-md bg-accent p-5 xs:w-full relative'>

        <div className='flex flex-row justify-between items-center'>
            <div className='flex flex-row justify-start items-center gap-3'>
                <div>                                    
                    <LazyLoadImage effect="blur" className="mask mask-squircle w-12 h-12 object-cover" src={props.memberImg} alt={props.memberFullName} />
                </div>
                <div>
                    <h2 className='text-neutral text-lg font-semibold'>{props.memberFullName}</h2>
                    <p className='text-neutral/80 text-sm capitalize'>{props.roleType} {props.isActive}</p>
                </div>
            </div>
            <div>
                <RiArrowDropRightLine fontSize={30} className={'text-neutral/50'} />
            </div>
        </div>
        
    </div>
  )
}

export default Member


