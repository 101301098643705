import React from 'react';
import ReactDOM from 'react-dom/client';
import './i18n';
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@material-tailwind/react";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {StateContextProvider} from './context/StateContext';

const root = ReactDOM.createRoot(
  document.getElementById('root')
);
root.render(
  <React.StrictMode>    
    <StateContextProvider>
      <ThemeProvider>
        {/* <Router forceRefresh={true}>           */}
        <Router>          
          <App />          
        </Router>
      </ThemeProvider>    
    </StateContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
