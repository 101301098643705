import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../utils/firebase-config';

export default function useAuth() {  
    const [currentUser, setCurrentUser] = useState();
        useEffect(() => {
            const unSubscribe = onAuthStateChanged(auth, (user) =>
                setCurrentUser(user)
            );
            return unSubscribe;
        }, []);
    return currentUser;
}