import React, { useState, useEffect } from 'react';
import { db, auth, storage } from '../../utils/firebase-config';
import {useTranslation} from 'react-i18next'
import { doc, setDoc } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import { GlobalState } from '../../context/StateContext';
import { Header } from '../../components/app';
import { motion } from "framer-motion";
import { Input, Textarea } from "@material-tailwind/react";
import { TbDeviceMobile } from 'react-icons/tb';
import { BsPersonCircle, BsGlobe } from 'react-icons/bs';
import { CgRename } from "react-icons/cg";
import { MdBusinessCenter } from "react-icons/md";

const EditBusiness = (props) => {

    const {t} = useTranslation();

    const navigate = useNavigate();    
    const user = auth.currentUser;
    const uid = user.uid;
    
    // const [uid, setUid] = useState(null)
      

    // useEffect( () => {
    //     if (user) {
    //         const un = () => {                                            
    //             setUid(user.uid);  
    //         }        

    //         return (un);
    //     }

    // }, [user] )


    const uidRef = GlobalState()?.userData?.id;
    console.log('uidRef =>>>>>>>> ', uidRef)    
    console.log('uid =>>>>>>>> ', uid)    

    const businessID = GlobalState()?.businessData?.id;            
    const businessNameGS = GlobalState()?.businessData?.businessName
    const businessDescGS = GlobalState()?.businessData?.businessDesc
    const businessDomainGS = GlobalState()?.businessData?.domainName
    const businessImgGS = GlobalState()?.businessData?.businessImg

    

    const [businessName, setBusinessName] = useState('');
    const [businessDesc, setBusinessDesc] = useState('');
    const [businessDomain, setBusinessDomain] = useState('');
    const [progress, setProgress] = useState(0);
    const [businessImg, setBusinessImg] = useState('')
    

    const [isFormValid, setIsFormValid] = useState(null);
    const [formErrMsg, setFormErrMsg] = useState("");  
    const [businessNameErrMsg, setBusinessNameErrMsg] = useState("");  
    const [businessDescErrMsg, setBusinessDescErrMsg] = useState("");    
    const [uploadImgFileName, setUploadImgFileName] = useState(null);    

    useEffect( () => {
        setBusinessName(businessNameGS)
        setBusinessDesc(businessDescGS)
        setBusinessDomain(businessDomainGS)
        setBusinessImg(businessImgGS)
    }, [businessNameGS, businessDescGS, businessDomainGS, businessImgGS] )     



    useEffect ( () => {
        !businessName || !businessDesc ? setIsFormValid(current => false) : setIsFormValid(current => true) 
    }, [businessName, businessDesc] )


    const uploadProfileImgHandler = (e) => {        
        setUploadImgFileName(e.currentTarget.files[0]);
        const file = e.currentTarget.files[0];
        uploadFiles(file);
    }


    const uploadFiles = (file) => {        
        if (!file) return;
        const sotrageRef = ref(storage, `businesses/${file.name + moment().format()}`);
        const uploadTask = uploadBytesResumable(sotrageRef, file);

        uploadTask.on(
        "state_changed",
        (snapshot) => {
            const prog = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgress(prog);
        },
        (error) => console.log(error),
        () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {            
              setBusinessImg(downloadURL);
            });
        }
        );
    };


    console.log('businessID: ', businessID)
    console.log('gs: ', businessNameGS)
    console.log('us: ', businessName)

   

    const submitForm = async (e) => {
        e.preventDefault();

        if (isFormValid === false) setFormErrMsg(t('Business Name, Business Description are required'))

        if (user && isFormValid === true && businessID) {
            await setDoc(doc(db, `users/${uid}/business`, businessID), {
                businessName,
                businessDesc,
                businessImg,
            }, {
                merge: true
            })            

            navigate('/app');            
        }        
    }
    


    return (
        <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            //animate={{ opacity: 1, transition: { duration: 1 } }}
            exit={{ opacity: 0 }}               
            >                
                
                <Header title = {t('Business Settings')} />
                <div className='upper_container xs:mt-10 md:mt-10'>                    
                    <div className='token_form mt-10'>
                        <form>
                            <div className='mb-7'>
                                <strong className='text-neutral/50 uppercase text-2xs block'>{t('Business Details')}</strong>
                                <div className="flex w-full mt-4">
                                    <Input type='text' 
                                    className={businessName.length >= 3 ? "bg-teal-600/10" : null}
                                    id='businessName' 
                                    value={businessName} 
                                    onChange={(e)=>setBusinessName((e.target.value))} 
                                    onBlur={(e) => {
                                        setBusinessNameErrMsg(t('Business name is required'))
                                        if (businessName.length <= 0) { 
                                        e.target.classList.add("bg-red-600/10");
                                        e.target.classList.remove("bg-teal-600/10")
                                        } else {
                                        e.target.classList.add("bg-teal-600/10"); 
                                        e.target.classList.remove("bg-red-600/10")
                                        }
                                    }} 
                                    color="blue" size="lg" label={t('Business name')} 
                                    icon={<CgRename fontSize={20} className={"text-neutral/50"} />}
                                    required />
                                </div>
                                {!businessName && !isFormValid ? (<p className="text-sm text-red-400">{businessNameErrMsg}</p>) : null}
                                <div className="flex w-full mt-3">                                    
                                    <Textarea type='text' 
                                    className={businessDesc.length >= 3 ? "bg-teal-600/10" : null}
                                    id='businessDesc' 
                                    value={businessDesc} 
                                    onChange={(e)=>setBusinessDesc((e.target.value))} 
                                    onBlur={(e) => {
                                        setBusinessDescErrMsg(t('Business description is required'))
                                        if (businessDesc.length <= 0) { 
                                        e.target.classList.add("bg-red-600/10");
                                        e.target.classList.remove("bg-teal-600/10")
                                        } else {
                                        e.target.classList.add("bg-teal-600/10"); 
                                        e.target.classList.remove("bg-red-600/10")
                                        }
                                    }} 
                                    color="blue" size="lg" label={t('Business Description') + ' *'}                                     
                                    required />
                                </div>
                                {!businessDesc && !isFormValid ? (<p className="text-sm text-red-400">{businessDescErrMsg}</p>) : null}
                                <div className="flex w-full mt-3">
                                    <Input type='text' className='' value={`${businessDomain}`} color="blue" size="lg" label={t('Domain name')} icon={<BsGlobe fontSize={20} className={'text-neutral/50'} />} disabled={true} />
                                </div>                               
                                <div className="flex w-full">                                    
                                    <div className="flex flex-row justify-center items-center gap-3 w-full mt-3 border border-grey-400 rounded-md p-3">
                                        <div>
                                            {businessImg ?
                                                <img className="mask mask-squircle w-12 h-12 object-cover" src={businessImg} alt='' />
                                            :
                                                <div className="mask mask-squircle w-12 h-12 object-cover bg-black/10 flex justify-center items-center">
                                                    <strong className='text-white text-xs'>{progress === 0 ? <BsPersonCircle fontSize={20} /> : progress + '%'}</strong>
                                                </div>
                                            }
                                        </div>
                                        <Input type='file' 
                                        onChange={uploadProfileImgHandler} 
                                        className='block w-full text-sm text-gray-500 file:mr-4 file:py-0 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100' 
                                        color="blue" 
                                        size="lg" 
                                        label={t('Business Logo')} 
                                        accept="image/*"                                                                                
                                        icon={<MdBusinessCenter fontSize={20} className={'text-neutral/50'} />} />
                                    </div>
                                </div>    
                                {
                                    isFormValid === false && formErrMsg !== "" ? (
                                        <div className="alert alert-warning shadow-lg mt-5">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                                                <span>{formErrMsg}</span>
                                            </div>
                                        </div>
                                    ) : null
                                }                          
                                <div className='mt-7'>
                                    { uploadImgFileName ? (
                                        progress < 100 ? (
                                            <button 
                                            onClick={submitForm}
                                            type="button" 
                                            className="
                                            rounded-md
                                            font-bold
                                            uppercase
                                            text-xs
                                            w-full
                                            py-5
                                            text-white
                                            hover:text-white
                                            focus:text-white
                                            bg-primary
                                            hover:bg-primary
                                            focus:bg-primary
                                            disabled:opacity-25
                                            cursor-not-allowed
                                            "                                            
                                            disabled                                                                                                          
                                            >                                    
                                                {t('Save')}
                                            </button>
                                        ) : (
                                            <motion.button 
                                            onClick={submitForm}
                                            type="button" 
                                            className="
                                            rounded-md
                                            font-bold
                                            uppercase
                                            text-xs
                                            w-full
                                            py-5
                                            text-white
                                            hover:text-white
                                            focus:text-white
                                            bg-primary
                                            hover:bg-primary
                                            focus:bg-primary
                                            disabled:opacity-25
                                            cursor-pointer
                                            "
                                            whileTap={{ scale: 0.9 }}                                                                                                                                                        
                                            >                                    
                                                {t('Save')}
                                            </motion.button>
                                        )
                                    ) : (
                                        <motion.button 
                                        onClick={submitForm}
                                        type="button" 
                                        className="
                                        rounded-md
                                        font-bold
                                        uppercase
                                        text-xs
                                        w-full
                                        py-5
                                        text-white
                                        hover:text-white
                                        focus:text-white
                                        bg-primary
                                        hover:bg-primary
                                        focus:bg-primary
                                        disabled:opacity-25
                                        cursor-pointer
                                        "
                                        whileTap={{ scale: 0.9 }}                                                                                                                                                        
                                        >                                    
                                            {t('Save')}
                                        </motion.button>
                                    ) }                                                                                                       
                                </div> 
                            </div>                             
                        </form>
                    </div>
                </div>
        </motion.div>
    )
}

export default EditBusiness