import React, {useState, useEffect} from 'react';
import { Link, useLocation } from "react-router-dom";
import Dayjs from "dayjs";
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Header, SessionInfoCap } from '../../components/app';
import { GlobalState } from "../../context/StateContext";
import { FiCreditCard } from 'react-icons/fi';

const SessionStats = () => {
  const location = useLocation();
  const { passedSessionId, passedSessionData } = location.state;
  const businessCategory = GlobalState()?.businessData?.businessCategory?.value;
  const {t, i18n} = useTranslation();
  //console.log(passedSessionData)



  const nowTime = parseInt( new Date().getTime() / 1000 );      
  const sessionEndDateTime = passedSessionData?.endDate?.seconds     
  const sessionRemainingEquation = parseInt ( (sessionEndDateTime - nowTime) / 60  )
  const [sessionRemainingHr, setSessionRemainingHr] = useState("")
  const [sessionRemainingMin, setSessionRemainingMin] = useState("")


  useEffect ( () => {
    const sessionRemainingTimeUnsub = () => {            
      setSessionRemainingHr( Number(parseInt (sessionRemainingEquation / 60)) )
      setSessionRemainingMin( Number(sessionRemainingEquation % 60) )                  
    } 
    return sessionRemainingTimeUnsub;
  } )



  
const totalSessionMinutes = (sessionEndDateTime - nowTime) / 60;
const remainingMinutes = sessionRemainingHr * 60 + sessionRemainingMin;
const remainingPercentage = (remainingMinutes / totalSessionMinutes) * 100;


const formatMoney = (amount) => {     
  if ( i18n.language === 'en' ) {
    return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + ' £'
  } else if ( i18n.language === 'ar' ) {
    return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + ' جنيه'
  } else {
    return;
  }
}


  console.log(businessCategory)

  return (
    <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        //animate={{ opacity: 1, transition: { duration: 1 } }}
        exit={{ opacity: 0 }}
      >
        <Header
          title={t('Session Stats')}   
          extraNavAction={
            <Link to={`/app/session/watcher/${passedSessionId}`}>
              <FiCreditCard
                fontSize={20}
                className={"text-primary cursor-pointer"}
              />
            </Link>
          }       
        />
        <div className="upper_container xs:mt-10 md:mt-10 ">
          {passedSessionData && (
            <SessionInfoCap
              name={passedSessionData?.sessionName}
              date={Dayjs(passedSessionData.startDate?.seconds * 1000).format(
                "ddd, DD MMM YYYY"
              )}
              startat={Dayjs(passedSessionData.startDate?.seconds * 1000).format(
                "h:mm A"
              )}
              endat={Dayjs(passedSessionData.endDate?.seconds * 1000).format(
                "h:mm A"
              )}
              url={`/session/watcher/${passedSessionId}`}
              remainingValue={
                passedSessionData?.started === true ? remainingPercentage : null
              }
              remainingTotal={totalSessionMinutes}              
              remainingTime={
                passedSessionData?.started === true ? `${sessionRemainingHr}:${sessionRemainingMin} ${t('Remaining to end')}` : null
              }              
            />
          )}
        </div>
        <div className='mt-10'>
          {/* <div className='rounded-md bg-accent p-5 xs:w-full mb-2 relative'>
              
          </div> */}
          <div className="stats w-full shadow-sm border border-gray-300">  
            <div className="stat">
              <div className="stat-title uppercase text-sm">{t('Total Income')}</div>
              <div className="stat-value font-medium text-2xl">{formatMoney(passedSessionData?.feesAmount * passedSessionData?.queueLenth)}</div>                                                
            </div>            
          </div>
          <div className="mt-3 stats w-full shadow-sm border border-gray-300">
            <div className="stat min-w-[170px]">              
              <div className="stat-title uppercase text-sm">{t('Fees')}</div>
              <div className="stat-value font-medium text-2xl">{formatMoney(passedSessionData?.feesAmount)}</div>              
            </div>            
            <div className="stat min-w-[170px]">              
              <div className="stat-title uppercase text-sm">{t('Deposit amount')}</div>
              <div className="stat-value font-medium text-2xl">{formatMoney(passedSessionData?.depositAmount)}</div>              
            </div>                                  
          </div>
          <div className="mt-3 stats w-full shadow-sm border border-gray-300">
            <div className="stat min-w-[170px]">              
              <div className="stat-title uppercase text-sm">{t('Queue length')}</div>
              <div className="stat-value font-medium text-2xl">{passedSessionData?.queueLenth} {passedSessionData?.queuePeak > 0 ? (`${t('of')} ${passedSessionData?.queuePeak}` ):null}</div>  
              {
                passedSessionData?.queuePeak > 0 ? (
                  <progress className="progress progress-primary w-full h-1 mt-1" value={passedSessionData?.queueLenth} max={passedSessionData?.queuePeak}></progress>
                ):null
              }              
            </div>            
            <div className="stat min-w-[170px]">              
              <div className="stat-title uppercase text-sm">{t('Remaining')}</div>
              <div className="stat-value font-medium text-2xl">{passedSessionData?.remaining}</div>              
            </div>                                  
          </div>
          <div className="mt-3 stats w-full shadow-sm border border-gray-300">
            <div className="stat min-w-[170px]">              
              <div className="stat-title uppercase text-sm">{t('Completed')}</div>
              <div className="stat-value font-medium text-2xl">{passedSessionData?.completed}</div> 
              <progress className="progress progress-primary w-full h-1 mt-1" value={passedSessionData?.completed} max={passedSessionData?.queueLenth}></progress>             
            </div>            
            <div className="stat min-w-[170px]">              
              <div className="stat-title uppercase text-sm">{t('Absence')}</div>
              <div className="stat-value font-medium text-2xl">{passedSessionData?.absence}</div>   
              <progress className="progress progress-error w-full h-1 mt-1" value={passedSessionData?.absence} max={passedSessionData?.queueLenth}></progress>                        
            </div>                                  
          </div>
          <div className="mt-3 stats w-full shadow-sm border border-gray-300">
            <div className="stat min-w-[170px]">              
              <div className="stat-title uppercase text-sm">{t('Ticket Kind')}</div>
              <div className="stat-value font-medium text-2xl capitalize">{passedSessionData?.ticketKind}</div>              
            </div>            
            {
              passedSessionData?.avgTime > 0 ? (
                <div className="stat min-w-[170px]">              
                  <div className="stat-title uppercase text-sm">{t('AvgTime')}</div>
                  <div className="stat-value font-medium text-2xl">{passedSessionData?.avgTime} {t('Min')}</div>              
                </div>  
              ):null
            }                                            
          </div>
        </div>
      </motion.div>
  )
}

export default SessionStats