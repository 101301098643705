import React, {useEffect, useState} from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
//import { Offline } from "react-detect-offline";
import { RiWifiOffLine } from 'react-icons/ri';
import PrivateRoutes from './utils/PrivateRoutes'
import AdminRoutes from './utils/AdminRoutes'
import { useTranslation } from 'react-i18next';
import Home from './pages/Home';
import Login from './pages/app/Login';
import FourOFour from './pages/FourOFour';
import Dashboard from './pages/app/Dashboard';
import Sessions from './pages/app/Sessions';
import EndedSessions from './pages/app/EndedSessions';
import InactiveSessions from './pages/app/InactiveSessions';
import CreateSession from './pages/app/CreateSession';
import CreateSessionRepeat from './pages/app/CreateSessionRepeat';
import EditSession from './pages/app/EditSession';
import SessionWatcher from './pages/app/SessionWatcher';
import SessionStats from './pages/app/SessionStats';
import SessionDownloads from './pages/app/SessionDownloads';
import AllTickets from './pages/app/AllTickets';
import CreateTicket from "./pages/app/CreateTicket";
import CreateMember from './pages/app/CreateMember';
import Members from './pages/app/Members';
import EditMember from './pages/app/EditMember';
import ProfileSettings from './pages/app/ProfileSettings';
import EditBusiness from './pages/app/EditBusiness';
import EditTicket from './pages/app/EditTicket';
import CreateBusinessOwner from './pages/app/CreateBusinessOwner';
import CreateBusiness from './pages/app/CreateBusiness';
import CreateMainBranch from './pages/app/CreateMainBranch';
import AddNewBranch from './pages/app/AddNewBranch';
import EditBranch from './pages/app/EditBranch';
import TicketTracking from './pages/app/TicketTracking';
import Booking from './pages/app/Booking';
import BookSession from './pages/app/BookSession';
import Reviews from './pages/app/Reviews';
import FLDashboard from './pages/fali/FLDashboard';
import FLAllUsers from './pages/fali/FLAllUsers';
import FLUserDetails from './pages/fali/FLUserDetails';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
// import useRTLCSS from './helper/useRTLCSS';
import './components/app/styles/global.css';
import './components/app/styles/rtl.css';



function App() {

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  if (process.env.REACT_APP_NODE_ENV === 'production') {
    disableReactDevTools();
  }

  const location = useLocation();
  const { i18n, t } = useTranslation();
  // useRTLCSS();

  useEffect(() => {
    const handleOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOnlineStatus);

    return () => {
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOnlineStatus);
    };
  }, []);

  useEffect( () => {
    document.title = 'QUECKS! -- Don\'t waste your time.';
  },[] )

  useEffect(() => {
    const langDirUnsub = () => {
      const dirValue = localStorage.getItem('dir');
      if (dirValue === 'ltr') {
        document.documentElement.setAttribute('dir', 'ltr');
        document.body.classList.remove("rtl");
        i18n.changeLanguage('en');
      } else if (dirValue === 'rtl') {
        document.documentElement.setAttribute('dir', 'rtl');
        document.body.classList.add("rtl");
        i18n.changeLanguage('ar');
      }
    }
    return () => langDirUnsub()
  }, [i18n]);
 

  return (
    <AnimatePresence>          
      <div className="App relative container xs:w-full sm:w-2/6 3xl:w-2/6">        
        {/* <Offline>
          <div className='
            fixed 
            inset-0
            w-screen
            h-screen 
            z-[200] 
            backdrop-blur-sm
            bg-black/60 
            overflow-hidden 
            flex flex-col justify-center items-center content-center 
            text-white            
            text-center
            px-5
            '>
              <RiWifiOffLine fontSize={36} className={"text-white"} />              
              <p className='text-lg font-bold pt-4'>{t('Youre offline Please check your connection')}</p>
            </div>        
        </Offline> */}
        
        {/* {isOnline ? null : (
          <div className='
          fixed 
          inset-0
          w-screen
          h-screen 
          z-[200] 
          backdrop-blur-sm
          bg-black/60 
          overflow-hidden 
          flex flex-col justify-center items-center content-center 
          text-white            
          text-center
          px-5
          '>
            <RiWifiOffLine fontSize={36} className={"text-white"} />              
            <p className='text-lg font-bold pt-4'>{t('Youre offline Please check your connection')}</p>
          </div> 
        )} */}
        
        <div className="mx-4">  
          {isOnline ? null : (
            <div className='flex justify-center items-center bg-red-600 -mx-4 h-10'>
              <RiWifiOffLine fontSize={16} className={"text-white ltr:mr-2 rtl:ml-2"} />              
              <p className='text-xs font-bold text-white'>{t('Youre offline Please check your connection')}</p>
            </div>
          )}                  
          <Routes location={location} key={location.pathname}>
            <Route element={<PrivateRoutes />}>
              <Route path="/app" element={<Dashboard />} />
              <Route path="/app/sessions" element={<Sessions />} />
              <Route path="/app/ended-sessions" element={<EndedSessions />} />              
              <Route path="/app/inactive-sessions" element={<InactiveSessions />} />              
              <Route path="/app/create-session" element={<CreateSession />} />
              <Route path="/app/create-repeated-sessions" element={<CreateSessionRepeat />} />
              <Route path="/app/edit-session" element={<EditSession />} />
              <Route path="/app/session/watcher/:id" element={<SessionWatcher />} />
              <Route path="/app/session/session-stats/:id" element={<SessionStats />} />
              <Route path="/app/session/session-downloads/:id" element={<SessionDownloads />} />
              <Route path="/app/session/all-tickets/:id" element={<AllTickets />} />
              <Route path="/app/create-ticket" element={<CreateTicket />} />
              <Route path="/app/add-member" element={<CreateMember />} />
              <Route path="/app/members" element={<Members />} />
              <Route path="/app/edit-member" element={<EditMember />} />
              <Route path="/app/profile-settings" element={<ProfileSettings />} />
              <Route path="/app/edit-business" element={<EditBusiness />} />
              <Route path="/app/edit-ticket" element={<EditTicket />} />
              <Route path="/app/create-business-owner" element={<CreateBusinessOwner />} />
              <Route path="/app/create-business" element={<CreateBusiness />} />
              <Route path="/app/create-main-branch" element={<CreateMainBranch />} />
              <Route path="/app/add-branch" element={<AddNewBranch />} />
              <Route path="/app/edit-branch" element={<EditBranch />} />
              <Route path="/app/reviews" element={<Reviews />} />
            </Route>

            <Route element={<AdminRoutes />}>
              <Route path="/1612" element={<FLDashboard />} />
              <Route path="/1612/all-users" element={<FLAllUsers />} />
              <Route path="/1612/user-details/:uid" element={<FLUserDetails />} />
            </Route>

            <Route path="*" element={<FourOFour />} />
            <Route path="/" element={<Home />} />
            <Route path="/app/login" element={<Login />} />            
            <Route path="/app/tracking/:uId/:businessId/:branchId/:sessionId/:ticketId" element={<TicketTracking />} />
            <Route path="/app/booking/:uId" element={<Booking />} />
            <Route path="/app/booking/:uId/session/:sId" element={<BookSession />} />            
          </Routes>
          
        </div>
      </div>
    </AnimatePresence>
  );
}

export default App;



//xs:mx-5 md:mx-auto