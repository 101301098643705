import React, {useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import ChangeLangDir from '../helper/ChangeLangDir';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import { db, auth } from '../utils/firebase-config';
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import quecks_logo_w from '../assets/images/quecks_logo_w.png';

const Home = () => {

    const { t } = useTranslation();
    
    
    const [userCurrentData, setUserCurrentData] = useState([]);        
    useEffect( () => {

        const un = onAuthStateChanged(auth, (user) => {            
          if (user) {
              //const user = auth.currentUser;           
              const userDocRef = doc(db, "users", user.uid); 
              const getUserCurrentStep = async () => {
                  const userDocSnap = await getDoc(userDocRef);
                  if (userDocSnap.exists()) {                
                      setUserCurrentData(userDocSnap.data())                
                  } else {                
                      console.log("No User Data!");
                  }
              }
              getUserCurrentStep();            
          }
        });

        return (un);

    }, [] )

    console.log(userCurrentData);




  return (
    <>
      {/* <div>      
        <h1>{t('test')}</h1>
        <Link to={'/app'}>app</Link>
        <div className='my-8'>
          <ChangeLangDir classNameProp="btn gap-2" />
        </div>
        <div className="drawer">
          <input id="my-drawer" type="checkbox" className="drawer-toggle" />
          <div className="drawer-content">              
            <label htmlFor="my-drawer" className="btn btn-primary drawer-button">Open drawer</label>
          </div> 
          <div className="drawer-side">
            <label htmlFor="my-drawer" className="drawer-overlay"></label>
            <ul className="menu p-4 overflow-y-auto w-80 bg-base-100 text-base-content">                
              <li><Link to={{  }}>Sidebar Item 1</Link></li>
              <li><Link to={{  }}>Sidebar Item 2</Link></li>
            </ul>
          </div>
        </div>
      </div> */}

      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='fixed w-screen h-screen inset-0 z-50 flex flex-col justify-center items-center'>
        <div className="hero min-h-screen bg-[#2E51D3]">
          <div className="hero-content text-center">
            <div className="max-w-md flex flex-col justify-center items-center">
              <img src={quecks_logo_w} width='75' alt='sadf' className='mb-4' />
              <h1 className="text-5xl font-bold text-white">Quecks</h1>
              <p className="py-6 text-white/80">{t('Intuitive Queue Management & Booking System.')}</p>
              <div className='flex justify-evenly items-center'>                                
                <Link to={'/app'} className="btn bg-white hover:bg-white border-none text-primary">{t('Join Beta Free')}</Link>
                <Link to={'/app'} className="btn btn-link text-white">{t('Login')}</Link>                
              </div>
            </div>
          </div>
        </div>
      </motion.div>




    </>
  )
}

export default Home