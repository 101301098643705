import React, { useState, useEffect } from 'react';
import { db, auth } from '../../utils/firebase-config.js';
import { collectionGroup, collection, query, getDocs, doc, updateDoc, orderBy, onSnapshot, limit } from "firebase/firestore";  
import { GlobalState } from '../../context/StateContext.js';
import { useTranslation } from 'react-i18next';
import { Header } from "../../components/app";
import { motion } from "framer-motion";
import Select from 'react-tailwindcss-select';
import Dayjs from "dayjs";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import Lottie from "lottie-react";
import emptyImg from "../../assets/jsons/lottie/empty.json";

const Reviews = () => {

  const { t } = useTranslation();    
  const user = auth.currentUser;
  const uidRef = GlobalState()?.userData?.id;
  const businessID = GlobalState()?.businessData?.id;    


  // const branchsOptions = [
  //   {value: "cairo", label: t('Cairo')},    
  //   {value: "giza", label: t('Giza')},  
  // ];


  
  const [open, setOpen] = useState(null);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };


  const [allBranches, setAllBranches] = useState([]);
  

  const branchsOptions = allBranches.map((branch) => ({
    value: branch?.branchName,
    label: branch?.branchName,
    id: branch?.id
  }));


  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedBranchId, setSelectedBranchId] = useState(null)


  // const [ratings, setRatings] = useState([]);




  const [ratings, setRatings] = useState([]);
  const [numDocs, setNumDocs] = useState(0);
  const [avgServiceRating, setAvgServiceRating] = useState(0);
  const [avgAssistanceRating, setAvgAssistanceRating] = useState(0);
  const [avgPlaceRating, setAvgPlaceRating] = useState(0);


  useEffect(() => {    
    const getBranches = async () => {
      if (user) {
        const branches = await getDocs(query(collection(db, `users/${uidRef}/business/${businessID}/branches`), orderBy("createdAt", "desc")));
        setAllBranches(branches.docs.map((doc) => ({ ...doc.data() })));
      }
    };
    getBranches();
  }, [user, uidRef, businessID]);


  // useEffect(() => {    
  //   const getRatings = async () => {
  //     if (user && selectedBranchId != null) {
  //       const selectedBranchRatings = await getDocs(query(collection(db, `users/${uidRef}/business/${businessID}/branches/${selectedBranchId}/ratings`), orderBy("createdAt", "desc")));
  //       setRatings(selectedBranchRatings.docs.map((doc) => ({ ...doc.data() })));
  //     }
  //   };
  //   getRatings();
  // }, [user, uidRef, businessID, selectedBranchId]);





  useEffect(() => {
    if (user && selectedBranchId != null) {
      const q = query(collection(db, `users/${uidRef}/business/${businessID}/branches/${selectedBranchId}/ratings`), orderBy("createdAt", "desc"), limit(500));
      const unsubscribe = onSnapshot(q, (snapshot) => {
      const ratingsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRatings(ratingsData);


      // Calculate the number of documents
      setNumDocs(snapshot.size);

      // Calculate the number of documents
      setNumDocs(snapshot.size);

      // Calculate the average ratings
      const totalServiceRating = ratingsData.reduce(
        (acc, curr) => acc + curr.serviceRating,
        0
      );
      const totalAssistanceRating = ratingsData.reduce(
        (acc, curr) => acc + curr.assistanteRating,
        0
      );
      const totalPlaceRating = ratingsData.reduce(
        (acc, curr) => acc + curr.placeRating,
        0
      );
      if (snapshot.size > 0) {
        setAvgServiceRating(totalServiceRating / snapshot.size);
        setAvgAssistanceRating(totalAssistanceRating / snapshot.size);
        setAvgPlaceRating(totalPlaceRating / snapshot.size);
      } else {
        setAvgServiceRating(0);
        setAvgAssistanceRating(0);
        setAvgPlaceRating(0);
      }
    }) 
    return () => unsubscribe();
    };

    
  }, [user, uidRef, businessID, selectedBranchId]);









  const handleBranchChange = (value) => {        
    setSelectedBranch(value);            
    setSelectedBranchId(value?.id)
  }










  //console.log(ratings)


  return (
    user === null || uidRef === undefined || businessID === undefined || allBranches?.length <= 0 ? (
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='fixed w-screen h-screen inset-0 z-50 flex justify-center items-center'><div className='spinner'></div></motion.div>
    ) : (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="my-6"
        > 
        <Header title={t('Reviews')} />
        <div className="upper_container xs:mt-10 md:mt-10">
          <div className="w-full">
            <strong className='text-neutral/50 uppercase text-2xs block mb-3'>{t('Select Branch first')}</strong>
            <Select
                placeholder = {t('Select branch') + ' *'}
                value={selectedBranch}              
                onChange={handleBranchChange}
                options={branchsOptions}                
            />           
          </div>

          { selectedBranch ? 
           numDocs > 0 ? (
            <div className='mt-5'>                                          
              <div className='mt-3'>
                <div className="stats w-full shadow-sm border border-gray-300 mb-3">  
                  <div className="stat">
                    <div className="stat-title uppercase text-sm">{t('Average service rating')}</div>
                    <div className="stat-value font-medium text-2xl"><strong>{avgServiceRating.toFixed(2)}</strong> <small>{t('of')} 5</small></div>  
                    <progress className="progress w-full h-1 mt-1" value={avgServiceRating.toFixed(2)} max={5}></progress>
                  </div>            
                </div>

                <div className="stats w-full shadow-sm border border-gray-300 mb-3">  
                  <div className="stat">
                    <div className="stat-title uppercase text-sm">{t('Average assistance rating')}</div>
                    <div className="stat-value font-medium text-2xl"><strong>{avgAssistanceRating.toFixed(2)}</strong> <small>{t('of')} 5</small></div>    
                    <progress className="progress w-full h-1 mt-1" value={avgAssistanceRating.toFixed(2)} max={5}></progress>                                            
                  </div>            
                </div>

                <div className="stats w-full shadow-sm border border-gray-300">  
                  <div className="stat">
                    <div className="stat-title uppercase text-sm">{t('Average place rating')}</div>
                    <div className="stat-value font-medium text-2xl"><strong>{avgPlaceRating.toFixed(2)}</strong> <small>{t('of')} 5</small></div>            
                    <progress className="progress w-full h-1 mt-1" value={avgPlaceRating.toFixed(2)} max={5}></progress>                                    
                  </div>            
                </div>
              </div>

              {/* <div className='mt-8'>
                <strong className="text-lg font-bold w-full text-center block">{selectedBranch?.label} {t('Reviews')} <span className='badge badge-lg'>{numDocs}</span></strong>
              </div> */}

              <div className='mt-5 '>
                {ratings.map((rating, indx) => (                
                  <Accordion key={indx} open={open === indx} className='border rounded-lg mb-3 px-5'>                            
                    <AccordionHeader onClick={() => handleOpen(indx)} className='border-none text-sm'>
                      <div className='flex flex-col'>
                      <strong className='ltr:text-left rtl:text-right'>{rating?.firstName} {rating?.lastName}</strong>
                      <small className='opacity-70'>{ Dayjs(rating?.createdAt?.seconds * 1000).format("ddd, DD MMM YYYY - h:mm A") }</small>
                      </div>
                    </AccordionHeader>
                    <AccordionBody>
                      <div className='flex justify-between items-center border-b border-black/10 border-dotted mb-3 pb-3'>
                        <strong>{t('The Service')}</strong>       
                        <div>
                          <div className="rating rating-md">
                            {createStarRating(rating.serviceRating)}                          
                          </div>
                        </div>                  
                      </div> 
                      <div className='flex justify-between items-center border-b border-black/10 border-dotted mb-3 pb-3'>
                        <strong>{t('The Assistant')}</strong>       
                        <div>
                          <div className="rating rating-md">
                            {createStarRating(rating.assistanteRating)}                          
                          </div>
                        </div>                  
                      </div> 
                      <div className='flex justify-between items-center border-b border-black/10 border-dotted mb-3 pb-3'>
                        <strong>{t('The Place')}</strong>       
                        <div>
                          <div className="rating rating-md">
                            {createStarRating(rating.placeRating)}                          
                          </div>
                        </div>                  
                      </div> 
                      <div className='pt-2 pb-5'>
                        <p>{rating?.commentRating}</p>
                      </div>
                      <div
                        className="btn border-none bg-accent hover:bg-accent"                        
                        href={`tel:${rating?.phoneNumber}`}
                      >
                        <strong className='text-primary'>{t('Call')} {rating?.phoneNumber}</strong>                                                           
                      </div>  
                    </AccordionBody>
                  </Accordion>
                ))}
              </div>
            </div>
          ) : (
            <div className='mt-5'>
              <div className="flex flex-col justify-center items-center h-[calc(100vh-200px)]">
                <Lottie animationData={emptyImg} loop={true} />
                <h1 className="text-2xl font-bold text-center">
                  {t('No Reviews Yet')}
                </h1>
              </div>
            </div>
          ) : null
        }
          

      

        </div>        
      </motion.div>
    )
    
  )
}


const createStarRating = (rating) => {
  let stars = [];
  for (let i = 0; i < rating; i++) {
    stars.push(<span key={i}>★</span>);
  }
  return stars;
};



export default Reviews
