import React, { useEffect, useRef, useState, Suspense, useCallback } from 'react';
import { motion } from "framer-motion";
import Sheet, { SheetRef } from 'react-modal-sheet';
import { Link } from "react-router-dom";
import {useTranslation} from 'react-i18next';
import './styles/BusinessWithBranch.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
//import default_business from '../../assets/images/default_business.png';
import Lottie from "lottie-react";
import default_business from "../../assets/jsons/lottie/default_business.json";
import 'react-lazy-load-image-component/src/effects/blur.css';
//import {BusinessName, BusinessDesc, BusinessImg} from '../../helper/Globals';
import { db, auth } from '../../utils/firebase-config';
import { doc, setDoc, addDoc, collection, serverTimestamp, getDocs, query, getDoc, onSnapshot, where, collectionGroup } from "firebase/firestore";
import { GlobalState } from '../../context/StateContext';
//import GetCurrentBranch from '../../helper/GetCurrentBranch';


const BusinessWithBranch = () => {

  const {t} = useTranslation();

  const user = auth.currentUser;
  const uid = GlobalState()?.userData?.id
  const businessId = GlobalState()?.businessData?.id
  const businessName = GlobalState()?.businessData?.businessName
  const businessDesc = GlobalState()?.businessData?.businessDesc
  const businessImg = GlobalState()?.businessData?.businessImg
  const allbranches = GlobalState()?.branchsData
  const urlTo = '/app';
  //const selectedBranch = GlobalState()?.businessData?.selectedBranch  
  //const retrevedCurrentSelectedBranchID = GetCurrentBranch();
  //const [currentSelectedBranchID, setCurrentSelectedBranchID] = useState()

  // const businessName = BusinessName()
  // const businessDesc = BusinessDesc()
  // const businessImg = BusinessImg()

  
  //const selectedBranch =  Storage.getItem('selectedBranch') 
  const [selectedBranch, setSelectedBranch] = useState(localStorage.getItem(`selectedBranch-${user?.uid}`))

  useEffect( () => {
    const selectedBranchUnsubscribe = () => {
      if ( localStorage.getItem(`selectedBranch-${user?.uid}`) !== null || localStorage.getItem(`selectedBranch-${user?.uid}`) !== undefined || localStorage.getItem(`selectedBranch-${user?.uid}`) !== '' ) {
        setSelectedBranch(localStorage.getItem(`selectedBranch-${user?.uid}`) )
      } else {
        setSelectedBranch(null)
      }
    }
    return () => selectedBranchUnsubscribe();
  }, [selectedBranch, user?.uid] ) 


  const [isBranchesOpened, setIsBranchesOpened] = useState(false);
  const SheetRef = useRef();

  

  //if (!selectedBranch) return;
  
  //console.log(selectedBranch)
  

  const openBranchesHandler = () => {
    setIsBranchesOpened( current => true )    
  }


  
  

  const chooseBranchHandler = async (branch) => {          
      if (user) {    
                
        setSelectedBranch(localStorage.setItem(`selectedBranch-${user?.uid}`, branch?.branchName))  
        window.location.reload();       
        //window.location.href = urlTo;
        //window.location.reload(false);
        //console.log(selectedBranch)
                                                
        // await setDoc(doc(db, `users/${uid}/business`, businessId), {                
        //     selectedBranch: branch.branchName,
        // }, {
        //     merge: true
        // }); 

        } else {
          console.log('no user')
      }
  }

  // retrevedCurrentSelectedBranchID.then(function(currentSelectedBranchIDResult) {    
  //   setCurrentSelectedBranchID(currentSelectedBranchIDResult)
  // })


  // console.log(currentSelectedBranchID)

  //console.log('branch => ', selectedBranch)


  return (
    <>
      <div className='flex flex-col justify-center items-center content-center'>
        
        <Link to={'/app'} className='flex flex-col justify-center items-center content-center'> 
        {
          businessImg ? (
            <LazyLoadImage effect="blur" className="mask mask-squircle w-32 h-32 object-cover" src={businessImg} alt={businessName && businessName} />
          ) : (
            <Lottie animationData={default_business} style={{ height: 140 }} loop={true} /> 
          )
        }                                  
          <div className='mt-1 mb-3 text-center'>
            {/* {businessName && <strong className='text-primary inline-block mb-1 text-lg md:text-7xl'>{businessName}</strong>} */}
            {businessName && <strong className='text-center text-lg font-bold mt-3 mb-1 inline-block'>{businessName}</strong>}
            {businessDesc && <p className='text-neutral '>{businessDesc}</p>}
          </div>          
        </Link>

        {
          localStorage.getItem(`selectedBranch-${user?.uid}`) === null || localStorage.getItem(`selectedBranch-${user?.uid}`) === undefined || localStorage.getItem(`selectedBranch-${user?.uid}`) === '' ? 
            
            <motion.button
            className='rounded-md bg-red-600 text-white px-8 h-8 mt-1'
            whileTap={{ scale: 0.9 }}
            onClick={ openBranchesHandler }
            >            
              {t('Choose a branch or create one!')}
            </motion.button>
            
          :
            <motion.button
            className='rounded-md bg-primary/20 px-8 h-8 mt-1'
            whileTap={{ scale: 0.9 }}
            onClick={ openBranchesHandler }
            >            
              {selectedBranch}
            </motion.button>
        }    

      </div>
            

      <Sheet 
      isOpen={isBranchesOpened} 
      onClose={() => setIsBranchesOpened(false)}
      ref={SheetRef}
      snapPoints={[600, 400, 100, 0]}
      initialSnap={1}
      // onSnap={snapIndex =>
      //   console.log('> Current snap point index:', snapIndex)
      // }
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <div className='px-5'>
              {allbranches.map( (branch, idx)  => (
                <motion.button 
                  key={idx} 
                  className={`rounded-md px-8 h-10 w-full mb-3 ${selectedBranch === branch?.branchName ? 'bg-primary flex flex-row justify-between items-center text-white' : 'bg-primary/20'} `} 
                  whileTap={{ scale: 0.9 }} 
                  onClick={()=>{chooseBranchHandler(branch)}}>
                  <span>{branch?.branchName}</span>  
                  {selectedBranch === branch?.branchName && <strong className='uppercase text-2xs block opacity-70'>{t('Current Branch')}</strong>}               
                </motion.button>                   
              ) )}              
              <div className='flex justify-center items-center'>
                <Link to={'/app/add-branch'} className='btn btn-link text-center'>{t('Add new branch')}</Link>
                <Link to={'/app/edit-branch'} className='btn btn-link text-center'>{t('Edit branch')}</Link>
              </div>
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </>
  )
}



export default BusinessWithBranch