import React, {useState, useEffect} from 'react';
import { db, auth } from '../../utils/firebase-config.js';
import { collection, query, getDocs, orderBy } from "firebase/firestore";  
import { GlobalState } from '../../context/StateContext';
import NewGetCurrentBranch from '../../helper/NewGetCurrentBranch.js';
import { CSVLink } from 'react-csv';
import { Link, useLocation } from "react-router-dom";
import Dayjs from "dayjs";
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Header } from '../../components/app';
import { FiCreditCard } from 'react-icons/fi';

const SessionDownloads = () => {
  const user = auth.currentUser;
  const uidRef = GlobalState()?.userData?.id;
  const businessID = GlobalState()?.businessData?.id;
  const currentSelectedBranchID = NewGetCurrentBranch();
  const location = useLocation();
  const { passedSessionId, passedSessionData } = location.state;
  const {t} = useTranslation();
  const sessionName = passedSessionData?.sessionName;
  const sessionStartDateFull = Dayjs(passedSessionData.startDate?.seconds * 1000).format("ddd, DD MMM YYYY");
  const [allQueues, setAllQueues] = useState([]);
  const [downloadableData, setDownloadableData] = useState([])

  const fileName = sessionName === sessionStartDateFull ? sessionName : sessionName + "_" + sessionStartDateFull


  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      
      try {
        if (user) {
          const q = query(
            collection(
              db,
              `users/${uidRef}/business/${businessID}/branches/${currentSelectedBranchID}/sessions/${passedSessionId}/queues`
            ), orderBy("ticketNumber", "asc")
          );
          const snapshot = await getDocs(q);
          const queues = snapshot.docs.map((doc) => doc.data());
          if (isMounted) {
            setAllQueues(queues);
            
          }
        }
      } catch (err) {
        console.log(err)
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [uidRef, businessID, currentSelectedBranchID, passedSessionId, user]);



  useEffect( () => {
    const downloadableDataUnsub = () => {
      if ( allQueues.length > 1 ) {
        const selectedArray = allQueues.map((item) => {
          return {
            "Ticket Number": item.ticketNumber,
            "First Name": item.firstName,
            "Last Name": item.lastName,
            "Phone Number": item.phoneNumber,
            "Paid": item.amountPaid,
            "Due": item.amountDue,
            "Note": item.ticketNote,            
            "Date": Dayjs(item.createdAt?.seconds * 1000).format("ddd, DD MMM YYYY hh:mm a"),                      
          };
        });
        setDownloadableData(selectedArray)
      }      
    }
    downloadableDataUnsub();
  }, [allQueues] )




  const headers = [
    {
      label: "Ticket Number", key: "Ticket Number"    
    },
    {
      label: "First Name", key: "First Name"    
    },
    {
      label: "Last Name", key: "Last Name"    
    },
    {
      label: "Phone Number", key: "Phone Number"    
    },
    {
      label: "Paid", key: "Paid"    
    },
    {
      label: "Due", key: "Due"    
    },
    {
      label: "Note", key: "Note"    
    },
    {
      label: "Date", key: "Date"    
    },
  ]


  const csvLink = {
    filename: `${fileName}.csv`,
    headers: headers,
    data: downloadableData, 
  }

  

  

  
console.log(fileName)
  
  


  return (
    <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Header
          title={t('Download center')}   
          extraNavAction={
            <Link to={`/app/session/watcher/${passedSessionId}`}>
              <FiCreditCard
                fontSize={20}
                className={"text-primary cursor-pointer"}
              />
            </Link>
          }       
        />        
        <div className='mt-10'>
          <div className='text-center'>
            {
              sessionName === sessionStartDateFull ? (
                <h1 className='text-3xl font-bold'>{sessionName}</h1>
              ) : (
                <>
                  <h1 className='text-3xl font-bold'>{sessionName}</h1>
                  <p className='text-neutral/70 mt-1'>{sessionStartDateFull}</p>
                </>
              )
            }            
          </div>                    
          <div className="flex flex-col justify-center items-center text-center p-5 mt-5 rounded-md bg-accent">
            <p className='text-blue-gray-700 mb-3'>{t('Download all registered and approved tickets for this session in an excel sheet format')}</p>
            <CSVLink className="btn btn-md w-full" dir="auto" {... csvLink}>{t('Download CSV File')}</CSVLink>
          </div>
        </div>
      </motion.div>
  )
}

export default SessionDownloads