import React, {useState} from 'react';
import { auth } from '../../utils/firebase-config';
import { signOut } from "firebase/auth";
import { RiMenu4Fill, RiCloseLine } from 'react-icons/ri';
import { AiOutlinePoweroff } from 'react-icons/ai';
import { motion } from "framer-motion";
import { Link, useNavigate } from 'react-router-dom';


const HeaderOfUnAuth = (props) => {
    
    const navigate = useNavigate();
    const [isMenueOpened, setisMenueOpened] = useState(false);
    
    const openMenueHandle = () => {
        setisMenueOpened( current => !current );        
    }
    
    const logoutHandler = (e) => {
      e.preventDefault();
      signOut(auth).then(() => {
        // Sign-out successful.
        navigate('/app');             
        //localStorage.clear();   
        localStorage.removeItem('selectedBranch');    
      }).catch((error) => {
        // An error happened.
        console.log(error)
      });            
    }
    

    return (
        <header className='flex gap-4 justify-between items-center h-12 xs:mt-4'> 
            <div className='relative'>                
                <div className="flex items-center">                    
                    <motion.div onClick={openMenueHandle}
                    className='w-10 h-8 flex justify-center items-center bg-accent rounded-md cursor-pointer'
                    initial={{ scale: 0 }}
                    animate={{ rotate: 180, scale: 1 }}
                    transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 20
                    }}                    
                    whileTap={{ scale: 1.5 }}                    
                    >                        
                        {
                            isMenueOpened ? 
                            <RiCloseLine fontSize={23} className={'text-primary'} />
                            :
                            <RiMenu4Fill fontSize={20} className={'text-primary'} />
                        }                                                
                    </motion.div>
                </div>                                
                <motion.div 
                className={`
                    fixed 
                    bottom-0 
                    left-0 
                    right-0 
                    container                     
                    xs:w-full 
                    sm:w-2/6 
                    3xl:w-2/6                     
                    backdrop-blur-md 
                    bg-white/10 
                    rounded-2xl
                    overflow-scroll
                    p-4
                    grow
                    
                    ${isMenueOpened && 'z-50' }
                `}                
                initial={false}
                animate={isMenueOpened ? {opacity: 1, y: 0} : {opacity: 0, y: "calc(100vh - 10%)" }}                
                style={{ boxShadow: '0px -10px 20px 0px rgba(0,0,0,0.10)', maxHeight: '80%' }}
                >
                    <div className='h-full'>
                        <div className='w-full flex flex-row justify-between items-center'>
                            <p className='font-medium text-xs opacity-70'>Quecks — Version 1.0.1</p>
                            <Link to={{  }}>
                                <motion.button className='flex justify-center items-center backdrop-blur-md bg-white/50 rounded-full shadow-md cursor-pointer p-4' whileTap={{ scale: .9 }} onClick={logoutHandler}>
                                    <AiOutlinePoweroff fontSize={20} className={'text-red-200 cursor-pointer'} />
                                </motion.button>
                            </Link>
                        </div>
                    </div>
                </motion.div>
            </div>
            <div className='text-center'>
                { props.logo ? 
                        <img src={props.logo} width={50} alt='' />
                    :
                        <span className='text-base font-medium text-neutral/50'>{ props.title}</span>
                }
            </div>
            <div className={`w-10 h-8 flex justify-center items-center rounded-md ${props.extraNavAction && 'bg-accent cursor-pointer' }`}>
                {props.extraNavAction}
            </div>
        </header>
    )
}

export default HeaderOfUnAuth