import React, {useState, useEffect} from 'react';
import useAuth from './useAuth';
import { useTranslation } from 'react-i18next';
import {GlobalState} from '../context/StateContext';
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Lottie from "lottie-react";
import no_branch from '../assets/jsons/lottie/no_branch.json'

const IfNoBranch = () => {
  const {t} =useTranslation();  
  const user = useAuth(); 
  const allbranches = GlobalState()?.branchsData  

  const [showNoBranch, setShowNoBranch] = useState(false);
  //const [selectedBranch, setSelectedBranch] = useState(null)

  useEffect(() => {
      if(user){
          const selectedBranchKey = localStorage.getItem(`selectedBranch-${user?.uid}`);
          if(selectedBranchKey === null || selectedBranchKey === undefined || selectedBranchKey === "") {
              setShowNoBranch(true);                
          }
      }
  }, [user, user?.uid]);  

  const chooseBranchHandler = async (branch) => {          
    if (user) {                  
      //setSelectedBranch(localStorage.setItem(`selectedBranch-${user?.uid}`, branch?.branchName))  
      localStorage.setItem(`selectedBranch-${user?.uid}`, branch?.branchName)
      window.location.reload();             
      } else {
        console.log('no user')
    }
}
    
  return (
    showNoBranch ? (
      <div className='fixed z-50 inset-0 w-full h-full flex flex-col justify-start items-center pt-20 backdrop-blur-md bg-red-300/10'>
        <div>
          <h1 className='font-bold text-4xl capitalize'>{t('No branch selected!')}</h1>
          <Lottie animationData={no_branch} style={{ height: 200 }} loop={true} /> 
        </div>        

        <div className='w-full'>
          <div className='flex w-full justify-start items-center px-5'>
            <p className='font-bold mb-3'>{t('Choose a branch or create one!')}</p>
          </div>
          <div className="w-full px-5 flex justify-start items-center flex-wrap">          
            {allbranches.map( (branch, idx)  => (
              <motion.button 
                key={idx} 
                className="rounded-md px-8 h-10 ltr:mr-1 rtl:ml-1 mb-2 bg-blue-50/80 shadow-md"
                whileTap={{ scale: 0.9 }} 
                onClick={()=>{chooseBranchHandler(branch)}}>
                <span className='text-primary font-bold'>{branch?.branchName}</span>                
              </motion.button>                   
            ) )}                 
          </div> 
          <Link to={'/app/add-branch'}>
            <div 
            className='
              flex 
              justify-center 
              items-center
              fixed 
              bottom-8           
              left-0
              right-0 
              z-10 
              w-full
              px-4
            '
            >
              <motion.button 
              className="
                xs:w-full 
                sm:w-2/6 
                3xl:w-2/6
                rounded-md 
                font-bold 
                uppercase 
                text-xs 
                py-5 
                text-white 
                hover:text-white 
                focus:text-white 
                bg-primary 
                hover:bg-primary 
                focus:bg-primary           
              "
              whileTap={{ scale: 0.9 }}
              >
                {t('Add new branch')}
              </motion.button>                
            </div>           
          </Link>
        </div>
      </div>
    ) : null    
  )
}

export default IfNoBranch