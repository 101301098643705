import React, { useState, useEffect } from 'react';
import { db, auth } from '../../utils/firebase-config.js';
import { collectionGroup, collection, query, getDocs, doc, updateDoc, orderBy, onSnapshot, setDoc, limit, serverTimestamp, addDoc } from "firebase/firestore";  
import { GlobalState } from '../../context/StateContext.js';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { Input, Textarea } from "@material-tailwind/react";
import { Header } from "../../components/app";
import { motion } from "framer-motion";
import Select from 'react-tailwindcss-select';
import Dayjs from "dayjs";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import Lottie from "lottie-react";
import emptyImg from "../../assets/jsons/lottie/empty.json";
import { TbDeviceMobile, TbBrandWhatsapp } from 'react-icons/tb';

const EditBranch = () => {

  const { t } = useTranslation();    
  const navigate = useNavigate();
  const user = auth.currentUser;
  const uidRef = GlobalState()?.userData?.id;
  const businessID = GlobalState()?.businessData?.id;    


  const governorateOptions = [
    {value: "cairo", label: t('Cairo')},  
    {value: "giza", label: t('Giza')},  
    {value: "alexandria", label: t('Alexandria')},  
    {value: "ismailia ", label: t('Ismailia')},  
    {value: "aswan ", label: t('Aswan')},  
    {value: "asyut ", label: t('Asyut')},  
    {value: "beheira ", label: t('Beheira')},  
    {value: "beni-suef", label: t('Beni Suef')},  
    {value: "dakahlia", label: t('Dakahlia')},  
    {value: "damietta", label: t('Damietta')},  
    {value: "faiyum", label: t('Faiyum')},  
    {value: "gharbia", label: t('Gharbia')},  
    {value: "kafr-el-sheikh", label: t('Kafr El Sheikh')},  
    {value: "luxor", label: t('Luxor')},  
    {value: "matruh", label: t('Matruh')},  
    {value: "minya", label: t('Minya')},  
    {value: "monufia", label: t('Monufia')},  
    {value: "new-valley", label: t('New Valley')},  
    {value: "north-sinai", label: t('North Sinai')},  
    {value: "port-said", label: t('Port Said')},  
    {value: "qalyubia", label: t('Qalyubia')},  
    {value: "qena", label: t('Qena')},  
    {value: "red-sea", label: t('Red Sea')},  
    {value: "sharqia", label: t('Sharqia')},  
    {value: "sohag", label: t('Sohag')},  
    {value: "south-sinai", label: t('South Sinai')},  
    {value: "suez", label: t('Suez')},  
];


  // const [open, setOpen] = useState(null);

  // const handleOpen = (value) => {
  //   setOpen(open === value ? 0 : value);
  // };


  const [allBranches, setAllBranches] = useState([]);
  

  const branchsOptions = allBranches.map((branch) => ({
    value: branch?.branchName,
    label: branch?.branchName,
    id: branch?.id
  }));


  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedBranchId, setSelectedBranchId] = useState(null)
  const [selectedBranchData, setSelectedBranchData] = useState(null)



  const [branchName, setBranchName] = useState('');
  const [branchPhone, setBranchPhone] = useState('');  
  const [branchWhatsapp, setBranchWhatsapp] = useState('');  
  const [governorate, setGovernorate] = useState(null);
  const [area, setArea] = useState('');
  const [fullAddress, setFullAddress] = useState('');  


  const [isFormValid, setIsFormValid] = useState(null);
  const [formErrMsg, setFormErrMsg] = useState("");  
  const [branchNameErrMsg, setBranchNameErrMsg] = useState(""); 
  const [branchPhoneErrMsg, setBranchPhoneErrMsg] = useState(""); 
  const [branchWhatsappErrMsg, setBranchWhatsappErrMsg] = useState(""); 

  const [submitLoading, setSubmitLoading] = useState(false)

  


  useEffect(() => {    
    const getBranches = async () => {
      if (user) {
        const branches = await getDocs(query(collection(db, `users/${uidRef}/business/${businessID}/branches`), orderBy("createdAt", "desc")));
        setAllBranches(branches.docs.map((doc) => ({ ...doc.data() })));
      }
    };
    getBranches();
  }, [user, uidRef, businessID]);


  const handleBranchChange = (value) => {        
    setSelectedBranch(value);            
    setSelectedBranchId(value?.id)
  }

  const handleGovernorateChange = (value) => {        
    setGovernorate(value);                      
}

  useEffect(() => {
    if (selectedBranch) {
      const selectedBranchRef = doc(db, `users/${uidRef}/business/${businessID}/branches`, selectedBranchId);
      const selectedBranchUnsubscribe = onSnapshot(selectedBranchRef, (doc) => {
        setSelectedBranchData(doc.data())       
      });
      return () => selectedBranchUnsubscribe();
    }
  }, [uidRef, businessID, selectedBranchId, selectedBranch]);


  useEffect(() => {
    setBranchName(selectedBranchData?.branchName);
    setBranchPhone(selectedBranchData?.branchPhone);
    setBranchWhatsapp(selectedBranchData?.branchWhatsapp);
    setGovernorate(selectedBranchData?.governorate);
    setArea(selectedBranchData?.area);
    setFullAddress(selectedBranchData?.fullAddress)
  }, [selectedBranchData])


  const submitForm = async (e) => {
    e.preventDefault();      
    
    setSubmitLoading(current => true)
    if (isFormValid === false) setFormErrMsg(t('Branch name, Branch phone, Governorate are required'));

    if (user && isFormValid === true) {        

      const branchRef = doc( db, `users/${uidRef}/business/${businessID}/branches`, selectedBranchId );
      await setDoc(branchRef, {        
        editedBy: uidRef,            
        branchName: branchName,
        branchPhone: branchPhone,
        branchWhatsapp: branchWhatsapp,
        governorate: governorate,
        area: area,
        fullAddress: fullAddress,                              
        editedAt: serverTimestamp(),
      }, {
        merge: true
      }).then(() => console.log("Document updated"));
      localStorage.removeItem(`selectedBranch-${user?.uid}`);  
      navigate('/app');  
    }  

}





useEffect ( () => {
  !branchName || !branchPhone || !branchWhatsapp || !governorate ? setIsFormValid(current => false) : setIsFormValid(current => true) 
}, [branchName, branchPhone, branchWhatsapp, governorate] )  






  //console.log('branchName:', branchName)


  return (
    user === null || uidRef === undefined || businessID === undefined || allBranches?.length <= 0 ? (
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='fixed w-screen h-screen inset-0 z-50 flex justify-center items-center'><div className='spinner'></div></motion.div>
    ) : (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="my-6"
        > 
        <Header title={t('Edit branch')} />
        <div className="upper_container xs:mt-10 md:mt-10">
          <div className="w-full">
            <strong className='text-neutral/50 uppercase text-2xs block mb-3'>{t('Select Branch first')}</strong>
            <Select
                placeholder = {t('Select branch') + ' *'}
                value={selectedBranch}              
                onChange={handleBranchChange}
                options={branchsOptions}                
            />           
          </div>

          { selectedBranch ? (             
            <div className='mt-10'>
              <form>
                  <div className='mb-7'>
                      <strong className='text-neutral/50 uppercase text-2xs block'>{t('Branch Details')}</strong>
                      <div className="flex w-full mt-4">
                          <Input 
                          type='text' 
                          className={branchName?.length > 0 ? "bg-teal-600/10" : null} 
                          value={branchName || ''}
                          onChange={(e)=>setBranchName((e.target.value))} 
                          color="blue" 
                          size="lg" 
                          label={t('Branch name') + ' *'} 
                          onBlur={(e) => {
                              setBranchNameErrMsg(t('Branch name is required'))
                              if (branchName?.length <= 0) { 
                                  e.target.classList.add("bg-red-600/10");
                                  e.target.classList.remove("bg-teal-600/10")
                              } else {
                                  e.target.classList.add("bg-teal-600/10"); 
                                  e.target.classList.remove("bg-red-600/10")
                              }
                          }}  
                          required
                          />
                      </div>
                      {!branchName && !isFormValid ? (<p className="text-sm text-red-400">{branchNameErrMsg}</p>) : null}
                      <div className="flex w-full mt-3">
                          <Input 
                          type='number' 
                          className={branchPhone?.length > 0 ? "bg-teal-600/10" : null} 
                          value={branchPhone || ''} 
                          onChange={(e)=>setBranchPhone((e.target.value), setBranchWhatsapp(e.target.value))} 
                          color="blue" 
                          size="lg" 
                          label={t('Branch phone number') + ' *'} 
                          icon={<TbDeviceMobile fontSize={20} className={'text-neutral/50'} />} 
                          onBlur={(e) => {
                              setBranchPhoneErrMsg(t('Branch phone number is required'))
                              if (branchPhone?.length <= 0) { 
                                  e.target.classList.add("bg-red-600/10");
                                  e.target.classList.remove("bg-teal-600/10")
                              } else {
                                  e.target.classList.add("bg-teal-600/10"); 
                                  e.target.classList.remove("bg-red-600/10")
                              }
                          }}  
                          required
                          />
                      </div>
                      {!branchPhone && !isFormValid ? (<p className="text-sm text-red-400">{branchPhoneErrMsg}</p>) : null}
                      <div className="flex w-full mt-3">
                          <Input 
                          type='number' 
                          className={branchWhatsapp?.length > 0 ? "bg-teal-600/10" : null} 
                          value={branchWhatsapp || ''} 
                          onChange={(e)=>setBranchWhatsapp((e.target.value))} 
                          color="blue" 
                          size="lg" 
                          label={t('Branch Whatsapp number') + ' *'} 
                          icon={<TbBrandWhatsapp fontSize={20} className={'text-neutral/50'} />} 
                          onBlur={(e) => {
                              setBranchWhatsappErrMsg(t('Branch Whatsapp number is required'))
                              if (branchWhatsapp?.length <= 0) { 
                                  e.target.classList.add("bg-red-600/10");
                                  e.target.classList.remove("bg-teal-600/10")
                              } else {
                                  e.target.classList.add("bg-teal-600/10"); 
                                  e.target.classList.remove("bg-red-600/10")
                              }
                          }}  
                          required
                          />
                      </div>
                      {!branchWhatsapp && !isFormValid ? (<p className="text-sm text-red-400">{branchWhatsappErrMsg}</p>) : null}
                      <div className="flex w-full mt-3">
                          <Select
                              placeholder = {t('Governorate') + ' *'}
                              value={governorate || null}
                              onChange={handleGovernorateChange}
                              options={governorateOptions}           
                              className="border border-gray-400 rounded-md "                             
                          />                           
                      </div> 
                      <div className="flex w-full mt-3">
                          <Input type='text' className='' value={area || ''} onChange={(e)=>setArea((e.target.value))} color="blue" size="lg" label={t('Area')} />
                      </div>    
                      <div className="flex w-full mt-3">
                          <Textarea label={t('Full address')} value={fullAddress || ''} onChange={(e)=>setFullAddress((e.target.value))} />
                      </div>   


                      {
                          isFormValid === false && formErrMsg !== "" ? (
                          <div className="alert alert-warning shadow-lg mt-5">
                              <div>
                              <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                              <span>{formErrMsg}</span>
                              </div>
                          </div>
                          ) : null
                      }                         
                      
                                                      
                      
                      <div className='mt-7'>  

                          {
                              isFormValid === false ? (
                                  <motion.input 
                                  onClick={submitForm}
                                  type='submit' 
                                  value={t('Edit')} 
                                  className="              
                                  rounded-md 
                                  font-bold 
                                  uppercase 
                                  text-xs 
                                  w-full 
                                  py-5
                                  text-white 
                                  hover:text-white 
                                  focus:text-white 
                                  bg-primary 
                                  hover:bg-primary 
                                  focus:bg-primary      
                                  disabled:opacity-25
                                  cursor-not-allowed                  
                                  "
                                  disabled                                   
                                  />
                              ) : (
                                  <motion.input 
                                  onClick={submitForm}
                                  type='submit' 
                                  value={submitLoading ? t('Editing') : t('Edit')}
                                  className={`
                                  rounded-md 
                                  font-bold 
                                  uppercase 
                                  text-xs 
                                  w-full 
                                  flex 
                                  justify-center 
                                  items-center 
                                  py-5 
                                  text-white                         
                                  ${submitLoading === true ? 'bg-primary/50 cursor-wait ':'bg-primary hover:text-white focus:text-white hover:bg-primary focus:bg-primary cursor-pointer '}
                                  `}
                                  disabled={submitLoading}
                                  whileTap={{ scale: 0.9 }}                                    
                                  />                                            
                              )
                          }                          
                      </div> 
                  </div> 
                  
              </form>
          </div>            
          ) : null
           
          }
        </div>
      </motion.div>
    )
  )
}

export default EditBranch