import React, {useEffect} from 'react'
import { Link } from "react-router-dom";
import { Header, Member } from '../../components/app';
import { motion } from "framer-motion";
import { auth } from '../../utils/firebase-config';
import { GlobalState } from '../../context/StateContext';
import user_default_img from '../../assets/images/user.png';
import { useTranslation } from 'react-i18next';


const Members = () => {

  const user = auth.currentUser;
  const {t} = useTranslation();
  const firstNameGS = GlobalState()?.userData?.firstName;
  const lastNameGS = GlobalState()?.userData?.lastName;
  const typeGS = GlobalState()?.userData?.type;
  const profileImgGS = GlobalState()?.userData?.imgURL;

  useEffect( () => {
    const pageTitleUnsub = () => {
      document.title = t('Members');
    }
    return () => pageTitleUnsub();
  },[] )

  return (
    <>
      <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      >
        <Header title ={t('Members')} />

        <div className='xs:mt-10 md:mt-10'>
            <div className='flex flex-col justify-center items-center content-center pb-20'>
              {/* <Link to={'/app/edit-member'} className='block w-full mb-2'>
                <Member memberImg={profileImgGS ? profileImgGS : user_default_img} memberFullName={firstNameGS +" "+ lastNameGS} roleType={typeGS}/>
              </Link>
               */}
              <div className='block w-full mb-2'>
                <Member memberImg={profileImgGS ? profileImgGS : user_default_img} memberFullName={firstNameGS +" "+ lastNameGS} roleType={typeGS}/>
              </div>
            </div>

            {/* <Link to={'/app/add-member'}> */}
              <div 
              className='
                flex 
                justify-center 
                items-center
                fixed 
                bottom-8           
                left-0
                right-0 
                w-full
                px-8
              '
              >
                  <button 
                  className="
                    xs:w-full 
                    sm:w-2/6 
                    3xl:w-2/6
                    rounded-md 
                    font-bold 
                    uppercase 
                    text-xs 
                    
                    text-white 
                    hover:text-white 
                    focus:text-white 
                    bg-primary 
                    hover:bg-primary 
                    focus:bg-primary 
                    btn
                    btn-disabled          
                  "
                  // whileTap={{ scale: 0.9 }}
                  disabled
                  >
                    {t('Add new member')}
                  </button>
                

              </div>  
            {/* </Link>           */}
        </div>
      </motion.div>
    </>
  )
}


export default Members