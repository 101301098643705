import React from 'react';
import { motion } from "framer-motion";

const FourOFour = () => {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='fixed w-screen h-screen inset-0 z-50 flex flex-col justify-center items-center'>
      <strong className='text-7xl'>!</strong>
      <strong className='text-5xl'>404</strong>
      <small className='text-xl'>Page Not Found</small>
    </motion.div>
  )
}

export default FourOFour