import React, {useMemo, useState} from 'react';
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import BusinessMembersIcon from '../../assets/svgs/business_members_icon.svg';
import SessionsIcon from '../../assets/svgs/sessions_icon.svg';
import { MdAddCircle, MdReviews } from 'react-icons/md';
import { BiCopyAlt, BiCheckDouble } from 'react-icons/bi';
import { GlobalState } from '../../context/StateContext';
import { db, auth } from '../../utils/firebase-config';
import { collection, query, onSnapshot, orderBy, where } from "firebase/firestore";
import NewGetCurrentBranch from "../../helper/NewGetCurrentBranch"

const DashboardElements = () => {

    const { t } = useTranslation();    
    const baseUrl = window.location.origin;
    const [isCopied, setIsCopied] = useState(false);
    const todayRaw = new Date();
    const user = auth.currentUser;
    const uidRef = GlobalState()?.userData?.id;
    const businessID = GlobalState()?.businessData?.id;        

    const userType = GlobalState()?.userData?.type;    
    const currentSelectedBranchID = NewGetCurrentBranch();
    // const retrevedCurrentSelectedBranchID = GetCurrentBranch();
    // const [currentSelectedBranchID, setCurrentSelectedBranchID] = useState()

    const [sessionData, setSessionData] = useState([]);
    const [loading, setLoading] = useState(false);    

    // retrevedCurrentSelectedBranchID.then(function(currentSelectedBranchIDResult) {    
    //     try {
    //         setCurrentSelectedBranchID(currentSelectedBranchIDResult)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // })

    //const sessionsCollectionRef = `users/${uidRef}/business/${businessID}/branches/${currentSelectedBranchID}/sessions`;

    // useMemo(() => {
    //     console.log('inside usememo')
    //     if (user && localStorage.getItem(`selectedBranch-${user?.uid}`) !== null && localStorage.getItem(`selectedBranch-${user?.uid}`) !== undefined && localStorage.getItem(`selectedBranch-${user?.uid}`) !== '') {       
    //         setLoading(true);     
    //         const sessionsUnsub = onSnapshot( 
    //         query( collection( db, sessionsCollectionRef ),
    //         orderBy('endDate', 'asc'),
    //         where("isActive", "==", true),
    //         where("endDate", ">=", todayRaw),
    //         ),
    //         (querySnapshot) => {
    //             const sessions = querySnapshot.docs.map((doc) => {
    //             return {
    //                 ...doc.data(),
    //                 id: doc.id,
    //             };
    //             })
    //             setSessionData(sessions)                     
    //             setLoading(false);          
    //         }             
    //         );      
    //         return () => sessionsUnsub();     
    //     }    
    // }, [user, sessionsCollectionRef]);  
    
    // const SessionNumber = () => {
    //     if ( loading === false && sessionData.length >= 1 ) {
    //         return sessionData.length
    //     }
    //     return '0'      
    // }

    const copyBookingLink = async () => {
        const url = `${baseUrl}/app/booking/${user?.uid}`; // replace with your link
        const el = document.createElement('textarea');
        el.value = url;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
          }, 1000);
    };
    
    const CopyBookingLinkButton = () => {
    return (
        <motion.button
        onClick={copyBookingLink}
        className="flex-none w-16 cursor-pointer bg-gray-800 rounded-2xl p-5"
        whileTap={{ scale: 0.9 }}
        >
            {isCopied ? <BiCheckDouble fontSize={24} className={"text-green-400"} /> : <BiCopyAlt fontSize={24} className={"text-white"} />}
        </motion.button>
        );
    };

    

    return (
        <>                        
            <div className="overflow-auto relative flex flex-row items-center flex-nowrap gap-2 pb-5">                
                {userType === 'owner' || userType === 'admin' ?
                    <div className='p-4 bg-secondary rounded-2xl flex flex-col justify-between grow h-28 shadow-md basis-1/2'>
                        <div className='flex flex-row justify-between items-center'>
                            <div className=""><img className='w-3/4' src={BusinessMembersIcon} alt='' /></div>
                            {/* <div className="text-right"><span className='text-white'>1</span></div> */}
                        </div>
                        <div className='flex flex-row w-full gap-1 justify-center items-center'>
                            <motion.div className="grow bg-white/20 rounded-md px-2 py-1 text-center cursor-pointer" whileTap={{ scale: 0.9 }}><Link to={'/app/members'} className='text-white font-bold uppercase text-sm inline-block w-full'>{t('Members')}</Link></motion.div>
                            {/* <motion.div className="grow-0 bg-white/20 rounded-md px-2 py-1 text-center cursor-pointer" whileTap={{ scale: 0.9 }}><Link to={'/app/add-member'}><MdAddCircle fontSize={24} className={'text-white'} /></Link></motion.div> */}
                        </div>
                    </div>
                : null
                }  

                {
                    localStorage.getItem(`selectedBranch-${user?.uid}`) === null || localStorage.getItem(`selectedBranch-${user?.uid}`) === undefined || localStorage.getItem(`selectedBranch-${user?.uid}`) === '' ?

                    <div className='relative p-4 bg-primary rounded-2xl flex flex-col justify-between grow h-28 shadow-md basis-1/2'>
                        <div className='absolute z-10 top-0 left-0 w-full h-full flex justify-center items-stretch'>
                            <div className="w-full m-1 rounded-2xl backdrop-blur-sm bg-white/30 flex justify-center items-center">
                                {t('No branch selected!')}
                            </div>
                        </div>
                        <div className='flex flex-row justify-between items-center'>
                            <div className=""><img className='w-3/4' src={SessionsIcon} alt='' /></div>
                            {/* <div className="text-right"><span className='text-white'>{<SessionNumber/>}</span></div> */}
                        </div>
                        <div className='flex flex-row w-full gap-1 justify-center items-center'>
                            <div className="grow bg-white/20 rounded-md px-2 py-1 text-center cursor-pointer"><span className='text-white font-bold uppercase text-sm' >{t('Sessions')}</span></div>
                            <div className="grow-0 bg-white/20 rounded-md px-2 py-1 text-center cursor-pointer" ><MdAddCircle fontSize={24} className={'text-white'} /></div>
                        </div>
                    </div>
                    :
                    <div className='p-4 bg-primary rounded-2xl flex flex-col justify-between grow h-28 shadow-md basis-1/2'>
                        <div className='flex flex-row justify-between items-center'>
                            <div className=""><img className='w-3/4' src={SessionsIcon} alt='' /></div>
                            {/* <div className="text-right"><span className='text-white'>{<SessionNumber/>}</span></div> */}
                        </div>
                        <div className='flex flex-row w-full gap-1 justify-center items-center'>
                            <motion.div className="grow bg-white/20 rounded-md px-2 py-1 text-center cursor-pointer" whileTap={{ scale: 0.9 }}><Link to={'/app/sessions'} className='text-white font-bold uppercase text-sm inline-block w-full'>{t('Sessions')}</Link></motion.div>
                            <motion.div className="grow-0 bg-white/20 rounded-md px-2 py-1 text-center cursor-pointer" whileTap={{ scale: 0.9 }}><Link to={'/app/create-repeated-sessions'}><MdAddCircle fontSize={24} className={'text-white'} /></Link></motion.div>
                        </div>
                    </div>
                }               

                {/* <div className='p-4 bg-primary rounded-lg grid grid-cols-2 gap-4 w-60 shadow-md'>
                    <div className=""><img src={SessionsIcon} alt='' /></div>
                    <div className="text-right"><span className='text-white'>12</span></div>
                    <motion.div className="col-span-2 bg-white/20 rounded-md py-2 text-center" whileTap={{ scale: 0.9 }}><span className='text-white font-bold uppercase'><Link to={'/sessions'}>Sessions</Link></span></motion.div>
                </div> */}

            </div>
            <div className='flex justify-between items-center mb-5'>
                <Link to={`/app/reviews`} className='btn relative !p-4 bg-orange-500 hover:bg-orange-500 border-none !h-auto !rounded-2xl flex justify-start items-center w-full shadow-md'>
                    <MdReviews fontSize={24} className={"text-white"} />
                    <strong className='text-white font-bold uppercase text-sm mx-2'>{t('Reviews')}</strong>
                </Link>
            </div>
            <div className='flex justify-between items-center flex-nowrap gap-2 mb-5'>
                <motion.div className="grow bg-gray-800 rounded-2xl" whileTap={{ scale: 0.9 }}>
                    <Link to={`/app/booking/${user?.uid}`} target="_blank" className='p-5 text-white font-bold uppercase text-sm cursor-pointer inline-block w-full h-full'>{t('External Booking')}</Link>
                </motion.div>
                <CopyBookingLinkButton />
            </div>
            {/* {userType === 'owner' ?
                <div className='relative flex flex-col justify-between items-center rounded-2xl p-4 shadow-md bg-moneytalk'>
                    <div className='flex flex-row justify-between items-stretch gap-1 w-full'>
                        <div className='flex flex-col grow basis-6/12'>
                            <div className='text-center'>
                                <span className='uppercase font-semibold text-xs text-white/80'>Aug 2022</span>
                            </div>
                            <div className='text-center'>
                                <strong className='font-black text-2xl text-white'>£189,400</strong>
                            </div>
                        </div>

                        <div className='bg-black/10 h-auto grow-0' style={{ width: 1 }}></div>
                        
                        <div className='flex flex-col grow basis-6/12'>
                            <div className='text-center'>
                                <span className='uppercase font-semibold text-xs text-white/80'>{t('Today Earnings')}</span>
                            </div>
                            <div className='text-center'>
                                <strong className='font-black text-2xl text-white'>£16,277</strong>
                            </div>
                        </div>
                    </div>
                    <div className='mt-3 w-full'>
                        <motion.button className='bg-white/20 rounded-md px-2 py-2 text-center cursor-pointer w-full text-white font-bold uppercase text-sm' whileTap={{ scale: 0.9 }}>{t('All stats & withdrawal')}</motion.button>
                    </div>
                </div>
            : null
            } */}


            {/* <fieldset class="toggle-group" id="colorScheme">
                <label>
                    <input type="radio" name="color-scheme" id="color-scheme-light" value="0" data-sr />
                    Light
                </label>
                <label>
                    <input type="radio" name="color-scheme" value="auto" checked data-sr />
                    Auto
                </label>
                <label>
                    <input type="radio" name="color-scheme" id="color-scheme-dark" value="1" data-sr />
                    Dark
                </label>
            </fieldset>   */}
        </>
    )
}

export default DashboardElements