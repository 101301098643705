import React, { useState, useEffect } from 'react';
import { db, auth } from '../../utils/firebase-config.js';
import { collectionGroup, query, getDocs } from "firebase/firestore";  
import { Link, useNavigate } from "react-router-dom";
import quecks_logo from '../../assets/images/quecks_logo.png';
import { BsCreditCard2Front } from "react-icons/bs";
import { HiOutlineTicket } from "react-icons/hi";
import { CgSandClock } from "react-icons/cg";
import { GiMoneyStack } from "react-icons/gi";

const FLDashboard = () => {

  const navigate = useNavigate();
  const user = auth.currentUser;


  const [users, setUsers] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [sessionsRevnue, setSessionsRevnue] = useState();
  const [recheck, setRecheck] = useState(0)

  const getUsers = async () => {
    const users = await getDocs(query(collectionGroup(db, 'users')));
    setUsers(users.docs.map((doc) => ({ ...doc.data() })));
  };

  const getSessions = async () => {
    const sessions = await getDocs(query(collectionGroup(db, 'sessions')));
    setSessions(sessions.docs.map((doc) => ({ ...doc.data() })));
  };

  const getTickets = async () => {
    const tickets = await getDocs(query(collectionGroup(db, 'queues')));
    setTickets(tickets.docs.map((doc) => ({ ...doc.data() })));
  };

  const getBookings = async () => {
    const bookings = await getDocs(query(collectionGroup(db, 'booking')));
    setBookings(bookings.docs.map((doc) => ({ ...doc.data() })));
  };

  useEffect ( () => {
    const allSessionsFeesAmount = sessions.map( session => session.feesAmount )
    const sum = allSessionsFeesAmount.reduce((acc, val) => acc + val, 0);
    const formattedSum = sum.toLocaleString('en-GB', {style: 'currency', currency: 'GBP'});
    setSessionsRevnue (formattedSum )
  }, [sessions] )
  
  useEffect(() => {
    getUsers();
    getSessions();    
    getTickets();
    getBookings();
    
  }, [recheck]);
  
  

  return (
    <div className='m-7'>
      <Link to={'/1612'} className='flex flex-col justify-center items-center mb-7'>
        <img src={quecks_logo} alt='quecks' width={50} />
        <h1 className='font-semibold text-3xl text-primary pt-2'>Quecks 1612</h1>
      </Link>
      <div className='w-full'>
        <div className="w-full stats shadow mb-4">  
          <div className="stat">            
            <div className="stat-title">Users</div>
            <div className="stat-value">{users?.length}</div>
            <div className="stat-desc">↗︎ 400 (22%)</div>
            <Link to={'/1612/all-users'} className='bg-primary rounded-lg text-center text-white mt-3 mb-2 py-1'>
              All Users
            </Link>
          </div>                   
        </div>  
        <div className="w-full stats shadow mb-4">  
          <div className="stat">
            <div className="stat-figure text-secondary">
              <BsCreditCard2Front fontSize={50} className={"text-black/75"} />
            </div>
            <div className="stat-title">Sessions</div>
            <div className="stat-value">{sessions?.length}</div>
            <div className="stat-desc">↗︎ 400 (22%)</div>
          </div>                   
        </div>  
        <div className="w-full stats shadow mb-4">  
          <div className="stat">
            <div className="stat-figure text-secondary">
              <HiOutlineTicket fontSize={50} className={"text-black/75"} />
            </div>
            <div className="stat-title">Tickets</div>
            <div className="stat-value">{tickets?.length}</div>
            <div className="stat-desc">↗︎ 400 (22%)</div>
          </div>                   
        </div>    
        <div className="w-full stats shadow mb-4">  
          <div className="stat">
            <div className="stat-figure text-secondary">
              <CgSandClock fontSize={50} className={"text-black/75"} />
            </div>
            <div className="stat-title">Bookings</div>
            <div className="stat-value">{bookings?.length}</div>
            <div className="stat-desc">↗︎ 400 (22%)</div>
          </div>                   
        </div>
        <div className="w-full stats shadow mb-4">  
          <div className="stat">
            <div className="stat-figure text-secondary">
              <GiMoneyStack fontSize={50} className={"text-black/75"} />
            </div>
            <div className="stat-title">Sessions Revinue</div>
            <div className="stat-value">{sessionsRevnue}</div>
            <div className="stat-desc">↗︎ 400 (22%)</div>
          </div>                   
        </div>
        <div className='flex justify-center items-center mt-4 mb-14'>
          <div className="indicator">
            {
              recheck > 0 && <span className="indicator-item badge badge-secondary">{recheck}</span> 
            }            
            <button className="btn" onClick={ () => setRecheck( current => current + 1 ) }>Re-Check</button>
          </div>          
        </div>        
      </div>
    </div>
  )
}

export default FLDashboard