import React, { useState, useEffect } from 'react';
import { db, auth, storage } from '../../utils/firebase-config';
import {useTranslation} from 'react-i18next';
import { updateProfile } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import { GlobalState } from '../../context/StateContext';
import { Header } from '../../components/app';
import { motion } from "framer-motion";
import { Input } from "@material-tailwind/react";
import { TbDeviceMobile } from 'react-icons/tb';
import { BsPersonBadge, BsPersonCircle } from 'react-icons/bs';
import { CgRename } from "react-icons/cg";
import { MdAlternateEmail } from "react-icons/md";

const ProfileSettings = (props) => {

    const {t} = useTranslation();
    const navigate = useNavigate();    
    const user = auth.currentUser;
    
    const [uid, setUid] = useState(null)
    const [phone, setPhone] = useState(null)    
    const [email, setEmail] = useState(null)    
      

    useEffect( () => {
        setUid(user.uid);
        setPhone(user.phoneNumber);          
        setEmail(user.email);   

    }, [user] )

    
    const firstNameGS = GlobalState()?.userData?.firstName
    const lastNameGS = GlobalState()?.userData?.lastName
    const profileImgGS = GlobalState()?.userData?.imgURL

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [progress, setProgress] = useState(0);
    const [profileImg, setProfileImg] = useState('')
    

    const [isFormValid, setIsFormValid] = useState(null);
    const [formErrMsg, setFormErrMsg] = useState("");  
    const [firstNameErrMsg, setFirstNameErrMsg] = useState("");  
    const [lastNameErrMsg, setLastNameErrMsg] = useState("");    
    const [uploadImgFileName, setUploadImgFileName] = useState(null);    

    useEffect( () => {
        setFirstName(firstNameGS)
        setLastName(lastNameGS)
        setProfileImg(profileImgGS)
    }, [firstNameGS, lastNameGS, profileImgGS] )     



    useEffect ( () => {
        !firstName || !lastName ? setIsFormValid(current => false) : setIsFormValid(current => true) 
    }, [firstName, lastName] )


    const uploadProfileImgHandler = (e) => {        
        setUploadImgFileName(e.currentTarget.files[0]);
        const file = e.currentTarget.files[0];
        uploadFiles(file);
    }


    const uploadFiles = (file) => {        
        if (!file) return;
        const sotrageRef = ref(storage, `users/${file.name + moment().format()}`);
        const uploadTask = uploadBytesResumable(sotrageRef, file);

        uploadTask.on(
        "state_changed",
        (snapshot) => {
            const prog = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgress(prog);
        },
        (error) => console.log(error),
        () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {            
                setProfileImg(downloadURL);
            });
        }
        );
    };

   

    const submitForm = async (e) => {
        e.preventDefault();

        if (isFormValid === false) setFormErrMsg(t('First name, Last name are required'))

        if (user && isFormValid === true) {
            await setDoc(doc(db, "users", uid), {
                firstName,
                lastName,
                imgURL: profileImg,
            }, {
                merge: true
            })

            await updateProfile(user, {
                displayName: firstName + ' ' + lastName,
                photoURL: profileImg,
            }).then(() => {
                // Profile updated!
                //alert(user.displayName + 'Updated!')
            }).catch((error) => {
                // An error occurred
                console.log(error)
            });

            navigate('/app');            
        }        
    }
    


    return (
        <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            //animate={{ opacity: 1, transition: { duration: 1 } }}
            exit={{ opacity: 0 }}               
            >                
                
                <Header title = {t('Profile Settings')} />
                <div className='upper_container xs:mt-10 md:mt-10'>                    
                    <div className='token_form mt-10'>
                        <form>
                            <div className='mb-7'>
                                <strong className='text-neutral/50 uppercase text-2xs block'>{t('Personal Details')}</strong>
                                <div className="flex w-full mt-4">
                                    <Input type='text' 
                                    className={firstName.length >= 3 ? "bg-teal-600/10" : null}
                                    id='firstName' 
                                    value={firstName} 
                                    onChange={(e)=>setFirstName((e.target.value))} 
                                    onBlur={(e) => {
                                        setFirstNameErrMsg(t('First name is required'))
                                        if (firstName.length <= 0) { 
                                        e.target.classList.add("bg-red-600/10");
                                        e.target.classList.remove("bg-teal-600/10")
                                        } else {
                                        e.target.classList.add("bg-teal-600/10"); 
                                        e.target.classList.remove("bg-red-600/10")
                                        }
                                    }} 
                                    color="blue" size="lg" label= {t('First name')} 
                                    icon={<CgRename fontSize={20} className={"text-neutral/50"} />}
                                    required />
                                </div>
                                {!firstName && !isFormValid ? (<p className="text-sm text-red-400">{firstNameErrMsg}</p>) : null}
                                <div className="flex w-full mt-3">
                                    <Input type='text' 
                                    className={lastName.length >= 3 ? "bg-teal-600/10" : null}
                                    id='lastName' 
                                    value={lastName} 
                                    onChange={(e)=>setLastName((e.target.value))} 
                                    onBlur={(e) => {
                                        setLastNameErrMsg(t('Last name is required'))
                                        if (lastName.length <= 0) { 
                                        e.target.classList.add("bg-red-600/10");
                                        e.target.classList.remove("bg-teal-600/10")
                                        } else {
                                        e.target.classList.add("bg-teal-600/10"); 
                                        e.target.classList.remove("bg-red-600/10")
                                        }
                                    }} 
                                    color="blue" size="lg" label= {t('Last name')} 
                                    icon={<CgRename fontSize={20} className={"text-neutral/50"} />}
                                    required />
                                </div>
                                {!lastName && !isFormValid ? (<p className="text-sm text-red-400">{lastNameErrMsg}</p>) : null}
                                <div className="flex w-full mt-3">
                                    <Input type='text' className='' value={`${phone}`} color="blue" size="lg" label={t('Phone number')} icon={<TbDeviceMobile fontSize={20} className={'text-neutral/50'} />} disabled={true} />
                                </div>
                                <div className="flex w-full mt-3">
                                    <Input type='text' className='' value={`${email}`} color="blue" size="lg" label={t('E-mail')} icon={<MdAlternateEmail fontSize={20} className={'text-neutral/50'} />} disabled={true} />
                                </div>
                                <div className="flex w-full">                                    
                                    <div className="flex flex-row justify-center items-center gap-3 w-full mt-3 border border-grey-400 rounded-md p-3">
                                        <div>
                                            {profileImg ?
                                                <img className="mask mask-squircle w-12 h-12 object-cover" src={profileImg} alt='' />
                                            :
                                                <div className="mask mask-squircle w-12 h-12 object-cover bg-black/10 flex justify-center items-center">
                                                    <strong className='text-white text-xs'>{progress === 0 ? <BsPersonCircle fontSize={20} /> : progress + '%'}</strong>
                                                </div>
                                            }
                                        </div>
                                        <Input type='file' 
                                        onChange={uploadProfileImgHandler} 
                                        className='block w-full text-sm text-gray-500 file:mr-4 file:py-0 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100' 
                                        color="blue" 
                                        size="lg" 
                                        label={t('Personal image')} 
                                        accept="image/*"                                                                                
                                        icon={<BsPersonBadge fontSize={20} className={'text-neutral/50'} />} />
                                    </div>
                                </div>    
                                {
                                    isFormValid === false && formErrMsg !== "" ? (
                                        <div className="alert alert-warning shadow-lg mt-5">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                                                <span>{formErrMsg}</span>
                                            </div>
                                        </div>
                                    ) : null
                                }                          
                                <div className='mt-7'>
                                    { uploadImgFileName ? (
                                        progress < 100 ? (
                                            <button 
                                            onClick={submitForm}
                                            type="button" 
                                            className="
                                            rounded-md
                                            font-bold
                                            uppercase
                                            text-xs
                                            w-full
                                            py-5
                                            text-white
                                            hover:text-white
                                            focus:text-white
                                            bg-primary
                                            hover:bg-primary
                                            focus:bg-primary
                                            disabled:opacity-25
                                            cursor-not-allowed
                                            "                                            
                                            disabled                                                                                                          
                                            >                                    
                                                {t('Save')}
                                            </button>
                                        ) : (
                                            <motion.button 
                                            onClick={submitForm}
                                            type="button" 
                                            className="
                                            rounded-md
                                            font-bold
                                            uppercase
                                            text-xs
                                            w-full
                                            py-5
                                            text-white
                                            hover:text-white
                                            focus:text-white
                                            bg-primary
                                            hover:bg-primary
                                            focus:bg-primary
                                            disabled:opacity-25
                                            cursor-pointer
                                            "
                                            whileTap={{ scale: 0.9 }}                                                                                                                                                        
                                            >                                    
                                                {t('Save')}
                                            </motion.button>
                                        )
                                    ) : (
                                        <motion.button 
                                        onClick={submitForm}
                                        type="button" 
                                        className="
                                        rounded-md
                                        font-bold
                                        uppercase
                                        text-xs
                                        w-full
                                        py-5
                                        text-white
                                        hover:text-white
                                        focus:text-white
                                        bg-primary
                                        hover:bg-primary
                                        focus:bg-primary
                                        disabled:opacity-25
                                        cursor-pointer
                                        "
                                        whileTap={{ scale: 0.9 }}                                                                                                                                                        
                                        >                                    
                                            {t('Save')}
                                        </motion.button>
                                    ) }                                                                                                       
                                </div> 
                            </div>                             
                        </form>
                    </div>
                </div>
        </motion.div>
    )
}

export default ProfileSettings