import React, { useState, useRef, useEffect } from 'react';
import { Header } from '../../components/app';
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Input } from "@material-tailwind/react";
//import moment from 'moment';
import Dayjs from "dayjs";
import { BiPound } from 'react-icons/bi';
import { CgTimelapse } from 'react-icons/cg';
import { IoTimerSharp } from 'react-icons/io5';
import { BsFillCreditCard2FrontFill, BsSpeakerFill } from 'react-icons/bs';
import Sheet, { SheetRef } from 'react-modal-sheet';
import Lottie from "lottie-react";
import { db, auth } from '../../utils/firebase-config';
import { doc, setDoc, addDoc, collection, serverTimestamp, getDocs, query, getDoc, onSnapshot } from "firebase/firestore";
import { GlobalState } from '../../context/StateContext';
//import GetCurrentBranch from '../../helper/GetCurrentBranch'
import NewGetCurrentBranch from "../../helper/NewGetCurrentBranch";
import LoadingAnimation from '../../assets/jsons/lottie/loading.json';
// import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import transition from "react-element-popper/animations/transition";
import "react-multi-date-picker/styles/layouts/mobile.css"
import { Calendar } from "react-multi-date-picker";
import arabic_ar from "react-date-object/locales/arabic_ar";
import { eachDayOfInterval, endOfMonth, format } from "date-fns";
import { MultiSelect } from "react-multi-select-component";




const CreateSessionRepeat = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const user = auth.currentUser;
    const uidRef = GlobalState()?.userData?.id;
    const businessID = GlobalState()?.businessData?.id;
    const currentSelectedBranchID = NewGetCurrentBranch();
    // const retrevedCurrentSelectedBranchID = GetCurrentBranch();
    // const [currentSelectedBranchID, setCurrentSelectedBranchID] = useState()    

    // retrevedCurrentSelectedBranchID.then(function(currentSelectedBranchIDResult) {    
    //   try {
    //     setCurrentSelectedBranchID(currentSelectedBranchIDResult)
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }) 

    
    // console.log('currentSelectedBranchID',currentSelectedBranchID)

    const [startDates, setStartDates] = useState([]);
    const [hours, setHours] = useState(0)
    // const [startDate, setStartDate] = useState(Dayjs().toDate());
    // const [endDate, setEndDate] = useState(Dayjs().toDate());
    const [ticketKind, setTicketKind] = useState('free');
    const [depositAmount, setDepositAmount] = useState(0);
    const [feesAmount, setFeesAmount] = useState(0);    
    const [queuePeak, setQueuePeak] = useState(0);
    // const [queueLenth, setQueueLenth] = useState(null);
    // const [remaining, setRemaining] = useState(null);
    // const [completed, setCompleted] = useState(null);
    // const [nowTicket, setNowTicket] = useState(null);
    const [avgTime, setAvgTime] = useState(null);
    const [isPolicyOpened, setIsPolicyOpened] = useState(false);

    const [isLoading, setIsloading] = useState(true);
    const [branchData, setBranchData] = useState([]);

    const[repeatedSessions, setRepeatedSessions] = useState(null)

    const [submitLoading, setSubmitLoading] = useState(false)
    
    
    const sheetRef = useRef();


    //new Date().toLocaleString('en-US', { timeZone: 'Africa/Cairo' });
    
    //console.log(ticketKind + '--' + depositAmount + '--' + feesAmount + '--' + queuePeak)


    const openPolicyHandler = () => {
      setIsPolicyOpened( current => true )
    }

    useEffect(() => {
      if (user) {
        setIsloading(false);
        if (isLoading === false) {
          if(!currentSelectedBranchID) return;
          const unsub = onSnapshot(
            doc(db, `users/${uidRef}/business/${businessID}/branches/${currentSelectedBranchID}`),
            (doc) => {            
              try {
                setBranchData(doc.data());
              } catch (error) {
                console.log(error)
              }
            }
          );
          return () => unsub();
        }
      }
    }, [user, uidRef, businessID, currentSelectedBranchID, isLoading ]);

    // console.log('branchData', branchData)

    // useEffect( () => {
    //   const sessionInitsUnsubscribe = () => {
    //     setQueueLenth(0)
    //     setRemaining(0);
    //     setCompleted(0);
    //     setNowTicket(0);
    //   }
    //   return sessionInitsUnsubscribe;
    // }, [] )



    const submitForm = (e) => {
      e.preventDefault();

      if (user ) {
        setSubmitLoading(current => true)
        const branchDocRef = doc(db, `users/${uidRef}/business/${businessID}/branches`, currentSelectedBranchID);        
        const branchColRef = collection(branchDocRef, "sessions")    

        repeatedSessions.forEach(async (session) => {
          await addDoc(
            branchColRef,
            session,
            { merge: false }
          )
          .then((docRef) => {
            setDoc(
              docRef,
              {
                id: docRef.id,
              },
              { merge: true }
            );
          })
          .catch((error) => console.error("Error adding document: ", error));
          navigate('/app/sessions');
        });        
      }
    }
    
    //console.log(sessionName);
    //console.log('(why) => ',typeof moment(startDate).format('ddd MMM/DD/YYYY'))
    //console.log('(why) => ',moment(startDate).format('MM/DD/YYYY HH:mm:ss'))

    // console.log('start>>>', startDate);
    // console.log('endDate>>>', endDate);

    const weekDays = [t('Sun'), t('Mon'), t('Tue'), t('Wed'), t('Thu'), t('Fri'), t('Sat')]
    const months = [t('Jan'), t('Feb'), t('Mar'), t('Apr'), t('May'), t('Jun'), t('Jul'), t('Aug'), t('Sep'), t('Oct'), t('Nov'), t('Dec')]
    const formatt = "ddd, DD MMM YYYY - h:mm a";
    
    // const initialDates = endDate;
    


    // console.log("dates: ", dates);
    


    //const [selectedDays, setSelectedDays] = useState([]);

    // Get the array of every day in the current month
    // const daysInMonth = eachDayOfInterval({
    //   start: new Date(),
    //   end: endOfMonth(new Date()),
    // });

    // Create an array of objects representing every day in the month
    // const options = daysInMonth.map((day) => {
    //   return {
    //     label: `${format(day, "EEEE, MMM d")} (${format(day, "d")})`,
    //     value: day.getDate(),
    //   };
    // });

    // Handle selection change
    // const handleSelect = (selected) => {
    //   setSelectedDays(selected);
    // };


    // useEffect( () => {
    //   if ( dates?.length > 0 ) {
    //     setStartDate( new Date(dates[0]?.unix * 1000) )
    //   }
    // }, [dates] )

    
    


    useEffect( () => {
      const datesArray = startDates.map((date) => ({
        branchid: currentSelectedBranchID,
        startDate: new Date(date?.unix * 1000),                
        endDate: new Date((date?.unix + hours * 3600) * 1000),
        sessionName:  Dayjs(new Date(date?.unix * 1000)).format('ddd, DD MMM YYYY'),
        ticketKind: ticketKind,
        depositAmount: Number(depositAmount),
        feesAmount: Number(feesAmount),
        queuePeak: Number(queuePeak),
        queueLenth: 0,
        remaining: 0,
        completed: 0,
        absence: 0,
        nowTicket: 0,
        avgTime: Number(avgTime),
        isActive: true,
        started: false,
        branchPhoneRef: branchData?.branchPhone,
        branchWhatsappRef: branchData?.branchWhatsapp,
        createdBy: user.uid,
        createdAt: serverTimestamp(),
      }));
      setRepeatedSessions(datesArray);
    }, [startDates, hours, avgTime, currentSelectedBranchID, ticketKind, depositAmount, feesAmount, queuePeak, branchData?.branchPhone, branchData?.branchWhatsapp, user.uid] )


    // console.log("selectedDays: ", selectedDays);

    // console.log("test:", test);

    // console.log("startDate: ", startDate)
    // console.log("endDate: ", endDate)
    //console.log(dates.map(date => new Date (date?.unix * 1000)) )

    // console.log(repeatedSessions)

    // console.log(typeof hours)

    //console.log(startDates[1]?.hour)

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Header title={t('Create New Sessions')} />        
        <div className="xs:mt-10 md:mt-10">
        <div className="tabs tabs-boxed mb-7">          
          <Link to={'/app/create-repeated-sessions'} className="tab tab-active w-1/2 !text-white"><strong className='text-white'>{t('Multi Sessions')}</strong></Link> 
          <Link to={'/app/create-session'} className="tab w-1/2">{t('Single Session')}</Link>
        </div>
          <form onSubmit={submitForm}>
            <div className="mb-7">  
              <strong className="text-neutral/50 uppercase text-2xs pb-1 block">
                {t('Days and start time')}
              </strong>          
              <p className="text-neutral/90 text-md pb-3 block">{t('Choose similar days that the start time and the period until each session ends.')}</p>
              <Calendar
                value={startDates}
                onChange={(date) => setStartDates(date)}
                multiple
                //sort
                format={formatt}
                // dateSeparator=" & " 
                weekDays={weekDays}
                months={months}
                // calendarPosition="bottom-center"
                plugins={[<TimePicker position="top" hideSeconds />]}
                className='!w-full'
                
              />
            </div>
          
            {/* <MultiSelect
              options={options}
              value={selectedDays}
              onChange={handleSelect}
              labelledBy={"Select Days"}
            /> */}
            
          
            {/* {selectedDays.map((selectedDay, index) => (
              <small key={index}>{selectedDay.value}<br/></small>
            ))} */}
          
            {/* {dates.map((date, index) => (
              <li key={index}>{date.format()}</li>
            ))} */}
            {/* {startDates.length > 0 ? startDates[0]?.format() : null} */}

         
                    
            <div className="mb-7">
              <strong className="text-neutral/50 uppercase text-2xs pb-3 block">
                {t('Sessions end after')}
              </strong>
              <div className='flex flex-row items-center justify-between'>
                <Input
                  type="number"
                  className=""
                  onChange={(e) => setHours(Number(e.target.value))}
                  color="blue"
                  size="lg"
                  label={t('Sessions period until it ends')}        
                  containerProps={{ className: "min-w-[100px] w-full" }}   
                  required              
                />
                <div className='flex flex-row items-center w-30 px-2 border rounded-md h-11 border-black/25 ltr:ml-1 rtl:mr-1'>
                  <IoTimerSharp
                      fontSize={20}
                      className={"text-neutral/50"}
                    />
                    <strong>{t('Hours')}</strong>
                </div>
              </div>                                       
            </div>
            <div className="mb-7">
              <div className="flex justify-between justify-items-center mb-1">
                <div>
                  <strong className="text-neutral/50 uppercase text-2xs">
                    {t('Ticket configration')}
                  </strong>
                </div>
                <div>
                  <motion.div
                    whileTap={{ scale: 0.9 }}
                    className="px-3 py-1 rounded-md bg-thirdparty text-primary text-xs font-bold capitalize cursor-pointer"
                    onClick={openPolicyHandler}
                  >
                    {t('Ticket Policy')}
                  </motion.div>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div className="form-control">
                  <label className="label cursor-pointer">
                    <input
                      type="radio"
                      name="tokenoption"
                      className="radio checked:bg-primary ltr:mr-3 rtl:ml-3"
                      value="free"
                      onClick={() => setTicketKind("free")}
                      defaultChecked
                    />
                    <span className="label-text">{t('Free Ticket')}</span>
                  </label>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div className="form-control">
                  <label className="label cursor-pointer">
                    <input
                      type="radio"
                      name="tokenoption"
                      className="radio checked:bg-primary ltr:mr-3 rtl:ml-3"
                      value="deposit"
                      onClick={() => setTicketKind("deposit")}
                      disabled
                    />
                    <span className="label-text">{t('Deposit')}</span>
                  </label>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div className="form-control">
                  <label className="label cursor-pointer">
                    <input
                      type="radio"
                      name="tokenoption"
                      className="radio checked:bg-primary ltr:mr-3 rtl:ml-3"
                      value="full-fees"
                      onClick={() => setTicketKind("full-fees")}
                      disabled
                    />
                    <span className="label-text">{t('Full Charge Fees')}</span>
                  </label>
                </div>
              </div>              
              <div className="flex w-full mt-3">
                <Input
                  type="number"
                  className=""
                  color="blue"
                  size="lg"
                  label={t('Fees amount')}
                  icon={<BiPound fontSize={20} className={"text-neutral/50"} />}
                  onChange={(e) => setFeesAmount(e.target.value)}
                />
              </div>
              <div className="flex w-full mt-5">
                <Input
                  type="number"
                  className=""
                  color="blue"
                  size="lg"
                  label={t('Deposit amount')}
                  icon={<BiPound fontSize={20} className={"text-neutral/50"} />}
                  onChange={(e) => setDepositAmount(e.target.value)}
                  disabled={true}
                />
              </div>
            </div>
            <div className="mb-7">
              <strong className="text-neutral/50 uppercase text-2xs block">
                {t('Session options')}
              </strong>              
              <div className="flex w-full mt-3">
                <Input
                  type="number"
                  className=""
                  color="blue"
                  size="lg"
                  label={t('Queue Peak')}
                  icon={
                    <BsSpeakerFill
                      fontSize={20}
                      className={"text-neutral/50"}
                    />
                  }
                  onChange={(e) => setQueuePeak(e.target.value)}
                />
              </div>
              <div className="flex flex-col w-full mt-3">
                <div className='flex flex-row items-center justify-between'>
                  <Input
                    type="number"
                    className=""
                    onChange={(e) => setAvgTime(e.target.value)}
                    color="blue"
                    size="lg"
                    label={t('Estimate the time for each entrance')}      
                    required              
                  />
                  <div className='flex flex-row items-center w-30 px-2 border rounded-md h-11 border-black/25 ltr:ml-1 rtl:mr-1'>
                    <IoTimerSharp
                        fontSize={20}
                        className={"text-neutral/50"}
                      />
                      <strong>{t('Min')}</strong>
                  </div>
                </div>
                <div className="flex flex-row justify-start items-stretch mt-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    className="stroke-info flex-shrink-0 w-4 h-4 mr-1 relative"
                    style={{ top: 2 }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <span className="text-primary/80 text-sm">
                    {t('How long will you stay with each person? (In minutes)')}
                  </span>
                </div>
              </div>
            </div>
            <div className="mb-7">
              {/* <input type='submit' value='Create Session' className="btn btn-primary w-full text-white" /> */}
              <button
                type="submit"                
                className={`
                rounded-md 
                font-bold 
                uppercase 
                text-xs 
                w-full 
                flex 
                justify-center 
                items-center 
                py-5 
                text-white                         
                ${submitLoading === true ? 'bg-primary/50 cursor-wait ':'bg-primary hover:text-white focus:text-white hover:bg-primary focus:bg-primary cursor-pointer '}
                `}
                disabled={
                    submitLoading
                }
                //whileTap={{ scale: 0.9 }}
              >
              {submitLoading ? (<Lottie animationData={LoadingAnimation} style={{ width: 19, height: 19 }} loop={true} />) : t('Create Sessions')}                  
              </button>
            </div>
            <Sheet
              isOpen={isPolicyOpened}
              onClose={() => setIsPolicyOpened(false)}
              ref={sheetRef}
              snapPoints={[600, 400, 100, 0]}
              initialSnap={1}
              onSnap={(snapIndex) =>
                console.log("> Current snap point index:", snapIndex)
              }
            >
              <Sheet.Container>
                <Sheet.Header />
                <Sheet.Content>
                  <div className="p-5">
                    <strong className="text-neutral/50 uppercase text-2xs block">
                      {t('Free Ticket Policy')}
                    </strong>
                    <div className="pt-3">
                      <ul className="steps steps-vertical">
                        <li className="step !text-left rtl:!text-right" data-content="●">
                          {t('Any Ticket can join.')}
                        </li>
                        <li className="step !text-left rtl:!text-right" data-content="●">
                          {t('Tickets are generated by the owner or adminstrators.')}
                        </li>
                        <li className="step !text-left rtl:!text-right" data-content="●">
                          {t('No penalty charge for none show tickets.')}
                        </li>
                        <li className="step !text-left rtl:!text-right" data-content="●">
                          {t('You can prevent same none show ticket to not use this service in the future.')}
                        </li>
                      </ul>
                    </div>
                  </div>
                </Sheet.Content>
              </Sheet.Container>
              <Sheet.Backdrop />
            </Sheet>
          </form>
        </div>
      </motion.div>
    );
}

export default CreateSessionRepeat