import { t } from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';

const SessionInfoCap = (props) => {


    



    const session = {
        name: props.name,
        date: props.date,
        startat: props.startat,
        endat: props.endat,
        url: props.url,
        remainingTotal: props.remainingTotal,
        remainingValue: props.remainingValue,
        remainingTime: props.remainingTime
        // date: moment().format('ddd, DD MMM YYYY'),
        // startat: moment().format('h:mm a'),
        // endat: moment().format('h:mm a')
    };

    return (
        <>
            <div className='header text-center'>
                <div className='name badge badge-sm border-none bg-thirdparty text-neutral/70 p-3 mb-3'>
                    <Link to={`/app${session?.url}`}><strong>{session?.name}</strong></Link>
                </div>
                <div className='title mb-2'>
                    <span className='text-3xl font-bold'>{session?.date}</span>
                </div>
                <div className='start_to_end'>
                    <p className='text-sm text-neutral/70'>{session?.startat} {t('to')} {session?.endat}</p>
                </div>
            </div>
            <div className='time_remaning mt-3 w-full'>
                <progress className="progress progress-primary w-full" value={session?.remainingValue} max={session?.remainingTotal}></progress>
                <p className='text-xs text-neutral/70'>{session?.remainingTime}</p>
            </div>
        </>
    )
}

export default SessionInfoCap