import React, { useState, useEffect, useMemo } from 'react';
import { db, auth } from '../../utils/firebase-config.js';
import { onSnapshot, doc, query, collection, orderBy, where, limit, setDoc, getDocs} from "firebase/firestore";
import { useTranslation } from 'react-i18next';
import { GlobalState } from '../../context/StateContext';
//import GetCurrentBranch from '../../helper/GetCurrentBranch';
import NewGetCurrentBranch from '../../helper/NewGetCurrentBranch.js';
import { Header, SessionInfoCap } from '../../components/app';
import { motion } from "framer-motion";
import { RiSettings3Fill } from 'react-icons/ri';
import { ImNext2 } from 'react-icons/im';
import { BsViewList } from 'react-icons/bs';
import { MdPersonOff, MdNotifications, MdQueryStats, MdOutlineFileDownload } from 'react-icons/md';
//import { TbQrcode } from 'react-icons/tb';
//import { IoCopyOutline } from 'react-icons/io5';
import { Link, useParams } from 'react-router-dom';
import Dayjs from "dayjs";
import Lottie from "lottie-react";
import queueImg from "../../assets/jsons/lottie/queue.json";
// import moment from 'moment';
// import { 
//   differenceInMinutes, 
//   secondsToMinutes, 
//   secondsToHours, 
//   differenceInHours, 
//   format, 
//   parseISO, 
//   formatISO, 
//   fromUnixTime, 
//   compareAsc,
//   compareDesc,
//   differenceInSeconds
//  } from 'date-fns'

const SessionWatcher = () => {

  const { t } = useTranslation();
  const params = useParams();
  const user = auth.currentUser;
  const uidRef = GlobalState()?.userData?.id;
  const businessID = GlobalState()?.businessData?.id;
  const currentSelectedBranchID = NewGetCurrentBranch();
  // const retrevedCurrentSelectedBranchID = GetCurrentBranch();
  // const [currentSelectedBranchID, setCurrentSelectedBranchID] = useState();
  const [isLoading, setIsloading] = useState(true);
  const [sessionData, setSessionData] = useState({});    
  const [queus, setQueues] = useState([]);    
  const [nowTicket, setNowTicket] = useState([]);    
  const [firstOfEnqueue, setFirstOfEnqueue] = useState({});    
  const [lastQueue, setLastQueue] = useState({});  

  const nowTime = parseInt( new Date().getTime() / 1000 ); 
  const nowTimeRow = new Date();     
  const sessionStartDateTime = sessionData?.startDate?.seconds     
  const sessionEndDateTime = sessionData?.endDate?.seconds     
  const sessionEndDateTimeFull = new Date(sessionData?.endDate?.seconds * 1000)
  //const formattedEndDate = new Date(sessionEndDateTimeFull);   
  const sessionRemainingEquation = parseInt ( (sessionEndDateTime - nowTime) / 60  )
  const [sessionRemainingHr, setSessionRemainingHr] = useState("")
  const [sessionRemainingMin, setSessionRemainingMin] = useState("")


  const [booking, setBooking] = useState([]);

  
  // console.log('nowTimeRow:', nowTimeRow)
  // console.log('sessionEndDateTimeFull:', sessionEndDateTimeFull)

  useEffect ( () => {
    setSessionRemainingHr( Number(parseInt (sessionRemainingEquation / 60)) )
    setSessionRemainingMin( Number(sessionRemainingEquation % 60) )      
  }, [sessionRemainingEquation, firstOfEnqueue] )


  const totalSessionMinutes = (sessionEndDateTime - nowTime) / 60;
  const remainingMinutes = sessionRemainingHr * 60 + sessionRemainingMin;
  const remainingPercentage = (remainingMinutes / totalSessionMinutes) * 100;


  //console.log('totalSessionMinutes: ', totalSessionMinutes)



  console.log("booking: ", booking)


  //console.log('currentSelectedBranchID: ', currentSelectedBranchID)
  // retrevedCurrentSelectedBranchID.then(function(currentSelectedBranchIDResult) {    
  //   try {
  //     setCurrentSelectedBranchID(currentSelectedBranchIDResult)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }) 

  //console.log('queus: ', queus)


  const sessionId = params.id;
  
  const [ isStackOpen, setIsStackOpen ] = useState (false);
  //const [stackMotionValue, togglestackMotionValue] = useState(1)    

  const stackHandler = () => {
      setIsStackOpen( current => !isStackOpen );
      //isStackOpen ? togglestackMotionValue(1) : togglestackMotionValue(10)        
  }

  
  // console.log("nowTime", nowTime)
  // console.log("start", sessionStartDateTime)
    

  useEffect(() => {
    if (user && localStorage.getItem(`selectedBranch-${user?.uid}`) !== null && localStorage.getItem(`selectedBranch-${user?.uid}`) !== undefined && localStorage.getItem(`selectedBranch-${user?.uid}`) !== '' ) {
        setIsloading(false)
        if (isLoading === false) {
            const unsub = onSnapshot(doc(db, `users/${uidRef}/business/${businessID}/branches/${currentSelectedBranchID}/sessions/${sessionId}`), (doc) => {
                //console.log("Current data: ", doc.data());
                setSessionData(doc.data())
            });
            return () => unsub();
        }  
        
    }
       
  }, [user, uidRef, businessID, currentSelectedBranchID, isLoading, sessionId]);  


  useEffect(() => {
    if (user && localStorage.getItem(`selectedBranch-${user?.uid}`) !== null && localStorage.getItem(`selectedBranch-${user?.uid}`) !== undefined && localStorage.getItem(`selectedBranch-${user?.uid}`) !== '' ) {
      const queuesUnsub = onSnapshot( 
          query( collection( db, `users/${uidRef}/business/${businessID}/branches/${currentSelectedBranchID}/sessions/${sessionId}/queues` ),
            where("ticketMove", "==", 'enqueue'),
            orderBy("ticketNumber", "asc"),            
            limit(3)
          ),
          (querySnapshot) => {
            const queuesTickets = querySnapshot.docs.map((doc) => {
              return {
                ...doc.data(),
                id: doc.id,
              };
            });
            setQueues(queuesTickets);
          }
        );      
      return () => queuesUnsub();
    }
  }, [user, businessID, currentSelectedBranchID, uidRef, sessionId]);


  useEffect( ()=> {
    const firstOfQueueId = queus.find(element => {
      return element.id === queus[0]?.id;
    });
    setFirstOfEnqueue(firstOfQueueId)
  }, [queus] )


  useEffect ( () => {
    if (sessionData?.started === true) {
      if (user && localStorage.getItem(`selectedBranch-${user?.uid}`) !== null && localStorage.getItem(`selectedBranch-${user?.uid}`) !== undefined && localStorage.getItem(`selectedBranch-${user?.uid}`) !== '') {
        const queuesUnsub = onSnapshot( 
            query( collection( db, `users/${uidRef}/business/${businessID}/branches/${currentSelectedBranchID}/sessions/${sessionId}/queues` ),
              where("isNowIn", "==", true),                      
              limit(2)
            ),
            (querySnapshot) => {
              const nowTicketInQueue = querySnapshot.docs.map((doc) => {
                return {
                  ...doc.data(),
                  id: doc.id,
                };
              });
              setNowTicket(nowTicketInQueue);
            }
          );
        return () => queuesUnsub();
      }
    }
  }, [sessionData?.started, businessID, currentSelectedBranchID, sessionId, uidRef, user] )

  useEffect ( () => {
    setLastQueue(queus[queus.length - 1])
  }, [queus] );


  useEffect(() => {    
    if (user) {
      if (isLoading === false) {
        const pending_tickets = `users/${uidRef}/business/${businessID}/branches/${currentSelectedBranchID}/sessions/${sessionId}/booking`; 
        const q = query(collection(db, pending_tickets),  
          orderBy('createdAt', 'asc'),     
          where("ticketStatus", "==", "pending"),    
        );

        // const bookingUnsub = await getDocs(q);
        // bookingUnsub.forEach((doc) => {
        //   // doc.data() is never undefined for query doc snapshots
        //   console.log(doc.id, " => ", doc.data());
        // });

        const bookingUnsub = onSnapshot(q, (snapshot) => {          
          setBooking(snapshot.docs.map(doc => ({...doc.data(), id: doc.id})));           
        })
              
        return () => bookingUnsub();   
      }
    }   
  }, [businessID, currentSelectedBranchID, sessionId, uidRef, user, isLoading]);  






  //console.log(sessionData)
  // console.log('Now Ticket:', nowTicket[0])
  // console.log('first Of Enqueue', firstOfEnqueue)  
  // console.log('last queue', lastQueue)
  
  //console.log('f', firstOfEnqueue.firstName)
  //console.log('queus', queus[0]?.ticketMove)
  //console.log('started', sessionData?.started)
  // console.log('Queue Lenth:', queus.length)
  // console.log('Queue 1st:', queus[0])
  // console.log('1st of the queue:', firstOfEnqueue)

  
  //firstOfEnqueue ? console.log('i') : console.log('n')


  const IfNoTicketsYetComponent = () => {
    return(        
      <div className="rounded-md mt-3 bg-yellow-500 p-5 shadow-yellow-400 shadow-2xl w-full">
        <div className='flex flex-col text-center justify-center items-center'>
          <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-10 w-10" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
          <span className='font-bold'>{t('Add at least on ticket to begin!')}</span>
        </div>
      </div>
    )
  }
  
      

  const startSessionHandler = async () => {  
    if ( firstOfEnqueue && queus.length > 0 ) {
      //const firstOfQueueId = queus[0]?.id;
      const enqueueInit = firstOfEnqueue?.id;
      const firstOfQueueIdDocRef = doc( db, `users/${uidRef}/business/${businessID}/branches/${currentSelectedBranchID}/sessions/${sessionId}/queues`, enqueueInit );
      await setDoc(
        firstOfQueueIdDocRef,
        {                         
          ticketMove: 'dequeue',
          isNowIn: true,             
        },
        { merge: true }
      ) 
      const sessionDocRef = doc( db, `users/${uidRef}/business/${businessID}/branches/${currentSelectedBranchID}/sessions`, sessionId );
      await setDoc(
        sessionDocRef,
        {                         
          started: true,
        },
        { merge: true }
      ) 
    }    
  }
  
  // const nextActionHandler = async () => {  
    
  //   const nowTicketToClear = doc( db, `users/${uidRef}/business/${businessID}/branches/${currentSelectedBranchID}/sessions/${sessionId}/queues`, nowTicket[0]?.id );
  //   await setDoc(
  //     nowTicketToClear,
  //     {                                    
  //       isNowIn: false,             
  //     },
  //     { merge: true }
  //   ) 

  //   const ticketToDequeue = doc( db, `users/${uidRef}/business/${businessID}/branches/${currentSelectedBranchID}/sessions/${sessionId}/queues`, firstOfEnqueue?.id );
  //   await setDoc(
  //     ticketToDequeue,
  //     {              
  //       ticketMove: 'dequeue',                   
  //       isNowIn: true,             
  //     },
  //     { merge: true }
  //   ) 

  //   const sessionDocRef = doc( db, `users/${uidRef}/business/${businessID}/branches/${currentSelectedBranchID}/sessions`, sessionId );
  //     await setDoc(
  //       sessionDocRef,
  //       {                   
  //         nowTicket: Number(nowTicket[0]?.ticketNumber + 1),             
  //         remaining: Number(sessionData?.remaining - 1),
  //         completed: Number(sessionData?.completed + 1),
  //       },
  //       { merge: true }
  //     ) 

  // }

  const nextActionHandler = async () => {
    const confirmationOverlay = document.getElementById('confirmationOverlay');
    const confirmButton = document.getElementById('confirmButton');
    const cancelButton = document.getElementById('cancelButton');
  
    confirmationOverlay.style.display = 'block';
  
    const confirmationPromise = new Promise((resolve, reject) => {
      confirmButton.addEventListener('click', () => resolve(true));
      cancelButton.addEventListener('click', () => resolve(false));
    });
  
    const confirmed = await confirmationPromise;
  
    confirmationOverlay.style.display = 'none';
  
    if (confirmed) {
      // Proceed with ticket clearing and dequeuing
      const nowTicketToClear = doc(db, `users/${uidRef}/business/${businessID}/branches/${currentSelectedBranchID}/sessions/${sessionId}/queues`, nowTicket[0]?.id);
      await setDoc(nowTicketToClear, {
        isNowIn: false,
      }, {
        merge: true
      });
  
      const ticketToDequeue = doc(db, `users/${uidRef}/business/${businessID}/branches/${currentSelectedBranchID}/sessions/${sessionId}/queues`, firstOfEnqueue?.id);
      await setDoc(ticketToDequeue, {
        ticketMove: 'dequeue',
        isNowIn: true,
      }, {
        merge: true
      });
  
      const sessionDocRef = doc(db, `users/${uidRef}/business/${businessID}/branches/${currentSelectedBranchID}/sessions`, sessionId);
      await setDoc(sessionDocRef, {
        nowTicket: Number(nowTicket[0]?.ticketNumber + 1),
        remaining: Number(sessionData?.remaining - 1),
        completed: Number(sessionData?.completed + 1),
      }, {
        merge: true
      });
    }
  };
  

  const absenceActionHandler = async () => {  
    
    const nowTicketToClear = doc( db, `users/${uidRef}/business/${businessID}/branches/${currentSelectedBranchID}/sessions/${sessionId}/queues`, nowTicket[0]?.id );
    await setDoc(
      nowTicketToClear,
      {                                    
        isNowIn: false,             
      },
      { merge: true }
    ) 

    const ticketToDequeue = doc( db, `users/${uidRef}/business/${businessID}/branches/${currentSelectedBranchID}/sessions/${sessionId}/queues`, firstOfEnqueue?.id );
    await setDoc(
      ticketToDequeue,
      {              
        ticketMove: 'dequeue',                   
        isNowIn: true,             
      },
      { merge: true }
    ) 

    const sessionDocRef = doc( db, `users/${uidRef}/business/${businessID}/branches/${currentSelectedBranchID}/sessions`, sessionId );
      await setDoc(
        sessionDocRef,
        {           
          nowTicket: Number(nowTicket[0]?.ticketNumber + 1),                    
          remaining: Number(sessionData?.remaining - 1),
          absence: Number(sessionData?.absence + 1),
        },
        { merge: true }
      ) 

  }

  const QueuesOnListComponent = () => {
    return (
      queus.length > 0 ? (
      queus.map( (toQueue) => (
        <div
          key={toQueue?.id}
          className={`card bg-accent text-primary-content rounded-md ${
            isStackOpen ? "mt-2 !bg-primary/20 " : "shadow-md "
          }`}
        >
          <div className="card-body p-5 flex flex-row justify-between items-center">
            <div className="flex flex-row justify-start items-center gap-1">
              <button
                className="relative z-10 cursor-not-allowed bg-white rounded-md p-2 opacity-50"                
                disabled
              >
                <MdNotifications fontSize={16} className={"text-neutral"} />
              </button>
              {/* <button
                className="relative z-10 cursor-pointer bg-white rounded-md p-2"
                onClick={() => alert("33-link")}
              >
                <IoCopyOutline fontSize={16} className={"text-neutral"} />
              </button>
              <button
                className="relative z-10 cursor-pointer bg-white rounded-md p-2"
                onClick={() => alert("33-qr")}
              >
                <TbQrcode fontSize={16} className={"text-neutral"} />
              </button> */}
            </div>
            <div className="flex flex-row items-center">
              <span className="text-neutral text-sm">{`${toQueue?.firstName} ${toQueue?.lastName}`}</span>
              <strong className="ltr:pl-2 rtl:pr-2 font-bold text-xl">{toQueue?.ticketNumber}</strong>
            </div>
          </div>
        </div>
      ) ) )
      : null      
    )    
  }  

  const CurrentQueueComponent = () => {
    return (
      sessionData?.started === false ?
      <div className="flex flex-col items-center rounded-md my-8 p-4 shadow-2xl shadow-orange-100">
        <Lottie animationData={queueImg} loop={true} />
        {
          firstOfEnqueue && queus.length > 0 && nowTime >= sessionStartDateTime ? (            
            <motion.button
              className="   
                  text-center    
                  mt-4              
                  rounded-md 
                  font-bold 
                  uppercase 
                  text-xs 
                  w-full 
                  py-5 
                  text-white 
                  bg-yellow-800     
                  cursor-pointer                   
                  "
              whileTap={{ scale: 0.9 }}
              onClick={startSessionHandler}          
            >
              {t('Start Session')}
            </motion.button>
          ) : !firstOfEnqueue && queus.length <= 0 ? (
            <IfNoTicketsYetComponent />
          ) : (
            <button
              className="   
                  text-center    
                  mt-4              
                  rounded-md 
                  font-bold 
                  uppercase 
                  text-xs 
                  w-full 
                  py-5 
                  text-white 
                  bg-gray-300     
                  cursor-not-allowed                   
                  "                                    
            >
              {t('Will be able to start at')} {Dayjs(sessionData.startDate?.seconds * 1000).format("h:mm A")}
            </button>
          )
        }        
      </div>        
      :
      <div className="current mt-10 rounded-md bg-accent flex flex-row justify-between items-stretch">
        <div className="data p-5 flex-1">
          <strong className="text-base">{nowTicket && nowTicket[0]?.firstName} {nowTicket && nowTicket[0]?.lastName}</strong>
          <div className="flex flex-row items-start my-2">
            <span className="text-neutral/30 text-xs">#</span>
            <strong className="text-7xl">{nowTicket && nowTicket[0]?.ticketNumber}</strong>
          </div>
          {/* <div className="w-full">
            <p className="text-xs text-neutral/70 -mb-2">-12 min.</p>
            <progress
              className="progress progress-primary w-full h-1"
              value="85"
              max="100"
            ></progress>
          </div> */}
        </div>
        <div className="actions p-3 flex flex-col justify-center rounded-md bg-primary/10 px-6">
          {
            firstOfEnqueue && lastQueue ? (
              <>
                <button className="btn bg-neutral/50 border-0 text-white text-xs font-bold btn-sm" onClick={absenceActionHandler}>
                  <MdPersonOff fontSize={18} className={"text-white"} />
                  <p>{t('Absence')}</p>
                </button>
                <button className="btn btn-disabled bg-neutral/50 border-0 text-white text-xs font-bold btn-sm my-2 cursor-not-allowed opacity-50">
                  <MdNotifications fontSize={18} className={"text-white"} />
                  {t('Recall')}
                </button>
                <button className="btn btn-primary btn-sm text-white text-xs font-bold !min-h-12" onClick={nextActionHandler}>
                  <ImNext2 fontSize={18} className={"text-white"} />                  
                  {t('Next')}
                </button>
              </>
            ) : (
              <button className="btn bg-neutral/50 border-0 text-white text-xs font-bold btn-sm my-2">
                <MdNotifications fontSize={18} className={"text-white"} />
                {t('Recall')}
              </button>  
            )
          }          
        </div>
      </div>
    );
  }

  // const remainingValueHandler = () => {
  //   // const nowTime = new Date().getTime() / 1000;
  //   // const sessionEndDateTime = sessionData.startDate?.seconds

  //   // const result = differenceInHours(
  //   //   nowTime,
  //   //   sessionEndDateTime
  //   // )

  //   // const subsc = nowTime - sessionEndDateTime

  //   // const output = new Date(subsc * 1000);

  //   // const nowTimed = fromUnixTime(nowTime)


  //   const result = differenceInMinutes(
  //     new Date(2022, 12, 22, 12, 30, 0),
  //     new Date(2022, 12, 22, 9, 30, 0)
  //   )

  //   //console.log(typeof result)
    

  //   return result;
    
     
  // }

  // console.log(remainingValueHandler())








    // const nowTime = parseInt( new Date().getTime() / 1000 );
    // const sessionStartDateTime = sessionData.startDate?.seconds
    // const sessionEndDateTime = sessionData.endDate?.seconds 

    // const eq = parseInt ( (( nowTime ) / (sessionEndDateTime)) * 100 )
    // const eq1 = parseInt ( (sessionEndDateTime - nowTime) / 60  )

    // const hr = parseInt (eq1 / 60); 
    // const min = eq1 % 60;



    // console.log( hr );
    // console.log( min ); 
    

    return (
      isLoading === true || uidRef === undefined || businessID === undefined || currentSelectedBranchID === null || sessionData === undefined || sessionStartDateTime === undefined || sessionEndDateTime === undefined || sessionEndDateTimeFull === 'Invalid Date' || isNaN(sessionRemainingEquation) || isNaN(sessionRemainingHr) || isNaN(sessionRemainingMin) || isNaN(totalSessionMinutes) || isNaN(remainingMinutes) || isNaN(remainingPercentage) ? (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='fixed w-screen h-screen inset-0 z-50 flex justify-center items-center'><div className='spinner'></div></motion.div>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          //animate={{ opacity: 1, transition: { duration: 1 } }}
          exit={{ opacity: 0 }}
        >
          <Header
            title={t('Session Watcher')}
            extraNavAction={
              <Link to={"/app/edit-session"} state={{ 
                passedSessionId: sessionId, 
                passedStartDate: sessionData?.startDate,
                passedEndDate: sessionData?.endDate, 
                passedDepositAmount: sessionData?.depositAmount ,
                passedFeesAmount: sessionData?.feesAmount,
                passedSessionName: sessionData?.sessionName,
                passedQueuePeak: sessionData?.queuePeak,
                passedAvgTime: sessionData?.avgTime,
                passedIsSessionActive: sessionData?.isActive
                }} >
                <RiSettings3Fill
                  fontSize={20}
                  className={"text-primary cursor-pointer"}
                />
              </Link>
            }
          />
          <div className="upper_container xs:mt-10 md:mt-10 ">

          <div className="c_overlay" id="confirmationOverlay">
            <div className="c_overlay-content">
              <h2>{t('Are you sure you want to proceed?')}</h2>
              <div className="c_button-container">
                <button className="btn c_btn-confirm" id="confirmButton">{t('Confirm')}</button>
                <button className="btn c_btn-cancel" id="cancelButton">{t('Cancel')}</button>
              </div>
            </div>
          </div>



          { nowTimeRow > sessionEndDateTimeFull ? (
            <div className='w-full flex flex-col items-center'>
              <SessionInfoCap
                  name={sessionData?.sessionName}
                  date={Dayjs(sessionData.startDate?.seconds * 1000).format(
                    "ddd, DD MMM YYYY"
                  )}
                  startat={Dayjs(sessionData.startDate?.seconds * 1000).format(
                    "h:mm A"
                  )}
                  endat={Dayjs(sessionData.endDate?.seconds * 1000).format(
                    "h:mm A"
                  )}              
                  url={`/session/watcher/${sessionId}`}
                  remainingValue={ remainingPercentage }              
                />
                <div className='w-full rounded-md bg-red-100 text-center  py-5'>
                  <strong className='text-red-500 text-2xl'>{t('Session time ended')}</strong>
                </div>
            </div>
          ) : (
            isLoading === false && sessionData && (
              <SessionInfoCap
                name={sessionData?.sessionName}
                date={Dayjs(sessionData.startDate?.seconds * 1000).format(
                  "ddd, DD MMM YYYY"
                )}
                startat={Dayjs(sessionData.startDate?.seconds * 1000).format(
                  "h:mm A"
                )}
                endat={Dayjs(sessionData.endDate?.seconds * 1000).format(
                  "h:mm A"
                )}              
                url={`/session/watcher/${sessionId}`}
                remainingValue={sessionData.started === true ? (isNaN(remainingPercentage) ? null : remainingPercentage) : null}
                remainingTotal={isNaN(totalSessionMinutes) ? 0 : totalSessionMinutes}              
                remainingTime={
                  sessionData.started === true
                    ? `${isNaN(sessionRemainingHr) ? '' : sessionRemainingHr}:${isNaN(sessionRemainingMin) ? '' : sessionRemainingMin} ${t('Remaining to end')}`
                    : null
                }
              />
            )
          ) }          

            {/* {
              queus.length > 0 || firstOfEnqueue ? (
                'true'
              ) : nowTicket[0] ? (
                'now'
              ) : (
                '0'
              )
            }

            {
              queus.length <= 0 || !firstOfEnqueue ? (
                'no queue and no to enqueue'
              ):null
            } */}

            {
              sessionData?.started === true && !nowTicket[0] && queus.length <= 0 && !firstOfEnqueue && !lastQueue ? (
                'ended, no queue and no to enqueue, started = true'
              ) : (
                <>
                  <CurrentQueueComponent />          
            
                  {
                    queus.length > 0 ? (
                    <>
                      <div
                        className={`w-full mt-4 cursor-pointer ${
                          isStackOpen ? "" : "stack"
                        }`}
                        onClick={stackHandler}
                      >          
                        <QueuesOnListComponent />                                               
                      </div>
                      
                    </>      
                    ) : null     
                  }                    

                  <div className="mt-7 p-5 bg-accent backdrop-blur-md rounded-md flex flex-row justify-between items-center">
                    <div className="">
                      <strong className="text-neutral/50 uppercase text-2xs">
                        {t('Remaining')}
                      </strong>
                    </div>
                    <div className="flex flex-row items-center">
                    {
                      queus.length > 0 ? (
                        <strong className="ltr:pl-3 rtl:pr-3 font-bold text-xl">{sessionData?.remaining}</strong>
                      ) : (
                        <Link to={"/app/create-ticket"} state={{ passedSessionId: sessionId, tabValue: "create_ticket" }} className="text-primary text-sm">
                          {t('Create Ticket')}
                        </Link>
                      )
                    }              
                    </div>
                  </div>  

                  <div className='flex justify-start items-start mt-2'>
                    <Link to={`/app/session/all-tickets/${sessionId}`}>
                      <motion.div whileTap={{ scale: 0.9 }} className="btn btn-active btn-link ">
                        <BsViewList fontSize={18} className={"text-primary ltr:mr-1 rtl:ml-1"} />
                        {t('View Remaining')}
                      </motion.div>                               
                    </Link>
                    <Link to={`/app/session/session-stats/${sessionId}`} state={{ passedSessionId: sessionId, passedSessionData: sessionData }}>
                      <motion.div whileTap={{ scale: 0.9 }} className="btn btn-active btn-link ">
                        <MdQueryStats fontSize={18} className={"text-primary ltr:mr-1 rtl:ml-1"} />
                        {t('Session Stats')}
                      </motion.div>                  
                    </Link>
                    <Link to={`/app/session/session-downloads/${sessionId}`} 
                    state={{ passedSessionId: sessionId, passedSessionData: sessionData }}
                    >
                      <motion.div whileTap={{ scale: 0.9 }} className="btn btn-active btn-link ">
                        <MdOutlineFileDownload fontSize={18} className={"text-primary ltr:mr-1 rtl:ml-1"} />
                        {t('Download center')}
                      </motion.div>                  
                    </Link>
                  </div>               
                </>
              )
            }

            
          </div>
          {
            queus.length > 0 ? (
              <div className="py-16"></div>
            ) : null
          }
          {
            sessionData?.queuePeak === 0 || sessionData?.queueLenth < sessionData?.queuePeak ? (
              <div
              className='
                flex 
                justify-between 
                items-center
                gap-2
                fixed                 
                inset-x-0
                bottom-8
                z-10 
                container xs:w-full sm:w-2/6 3xl:w-2/6 
                px-4 
                '
              >
                <Link to={"/app/create-ticket"} state={{ passedSessionId: sessionId, tabValue: "create_ticket" }} className='flex-1'>                                      
                  <motion.button 
                  className="
                    w-full
                    rounded-md 
                    font-bold 
                    uppercase 
                    text-xs 
                    py-5 
                    text-white 
                    hover:text-white 
                    focus:text-white 
                    bg-primary 
                    hover:bg-primary 
                    focus:bg-primary           
                  "
                  whileTap={{ scale: 0.9 }}
                  >
                    {t('Create Ticket')}
                  </motion.button>                                  
                </Link>
                {
                  booking.length > 0 ? (
                    <Link to={"/app/create-ticket"} state={{ passedSessionId: sessionId, tabValue: "pending_tickets" }} className='flex-1'>
                      <motion.button
                      className="
                        w-full
                        rounded-md 
                        font-bold 
                        uppercase 
                        text-xs 
                        py-5 
                        text-white 
                        hover:text-white 
                        focus:text-white 
                        bg-primary 
                        hover:bg-primary 
                        focus:bg-primary           
                      "
                      >
                        {t('Bookings')}
                        <div className="badge badge-[#dedede] rounded-lg pl-2 pr-2 ltr: ml-2 rtl:mr-2">
                          <span className="font-bold text-white">{booking.length}</span>
                        </div>                  
                      </motion.button>
                    </Link>
                  ) : null
                }
              </div>
            ) : (
              <div className='
                mx-auto
                flex 
                flex-col 
                justify-center 
                items-center
                fixed 
                inset-x-0 
                bottom-8                             
                z-10 
                bg-red 
                xs:w-full 
                sm:w-2/6 
                3xl:w-2/6
                px-4
              '>
                <div className="alert alert-warning rounded-md ">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                    <span>{t('The session peak is reached!')}</span>
                  </div>
                </div>
                <Link to={"/app/edit-session"} state={{ 
                  passedSessionId: sessionId, 
                  passedStartDate: sessionData?.startDate,
                  passedEndDate: sessionData?.endDate, 
                  passedDepositAmount: sessionData?.depositAmount ,
                  passedFeesAmount: sessionData?.feesAmount,
                  passedSessionName: sessionData?.sessionName,
                  passedQueuePeak: sessionData?.queuePeak,
                  passedAvgTime: sessionData?.avgTime,
                  passedIsSessionActive: sessionData?.isActive
                  }} 
                  className='w-full mt-2'
                  >
                  
                      <motion.button 
                      className="
                        w-full
                        rounded-md 
                        font-bold 
                        uppercase 
                        text-xs 
                        py-5 
                        text-white 
                        hover:text-white 
                        focus:text-white 
                        bg-black 
                        hover:bg-black 
                        focus:bg-black           
                      "
                      whileTap={{ scale: 0.9 }}
                      >
                        {t('Change session settings')}
                      </motion.button>                
                  
                </Link>
              </div>
            )
          }        
        </motion.div>
      )
    );
}

export default SessionWatcher