import React, { useCallback, useState, useEffect } from "react";
import { db, auth } from "../../utils/firebase-config.js";
import { useTranslation } from "react-i18next";
import {
  onSnapshot,
  doc,
  collection,
  addDoc,
  serverTimestamp,
  setDoc,
  query,
  where,
  orderBy
} from "firebase/firestore";
import { GlobalState } from "../../context/StateContext";
//import GetCurrentBranch from "../../helper/GetCurrentBranch";
import NewGetCurrentBranch from "../../helper/NewGetCurrentBranch.js";
import { Header, SessionInfoCap } from "../../components/app";
import { motion } from "framer-motion";
import Dayjs from "dayjs";
import { Link, useLocation } from "react-router-dom";
import {
  Input, 
  Textarea,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel
 } from "@material-tailwind/react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Lottie from "lottie-react";
import {QRCodeSVG} from 'qrcode.react';
import { BiPound } from "react-icons/bi";
import { BsWhatsapp, BsCheckAll } from "react-icons/bs";
import { CgRename } from "react-icons/cg";
import { TbDeviceMobile, TbQrcode } from "react-icons/tb";
import { IoCopyOutline, IoTicketOutline } from "react-icons/io5";
import { RiPhoneFill } from "react-icons/ri";
import BackToSessionIcon from "../../assets/svgs/back_to_session_icon.svg";
import TopBattern from "../../assets/svgs/top_pattern.svg";
import LoadingAnimation from '../../assets/jsons/lottie/loading.json'
import empty_box from "../../assets/jsons/lottie/empty_box.json";

const CreateTicket = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { passedSessionId, tabValue } = location.state;
  const user = auth.currentUser;
  const uidRef = GlobalState()?.userData?.id;
  const businessID = GlobalState()?.businessData?.id;
  const businessImg = GlobalState()?.businessData?.businessImg;
  const businessName = GlobalState()?.businessData?.businessName;
  const businessDesc = GlobalState()?.businessData?.businessDesc;
  const currentSelectedBranchID = NewGetCurrentBranch();
  const businessCategory = GlobalState()?.businessData?.businessCategory?.value;
  // const retrevedCurrentSelectedBranchID = GetCurrentBranch();
  // const [currentSelectedBranchID, setCurrentSelectedBranchID] = useState();
  const [isLoading, setIsloading] = useState(true);
  const [sessionData, setSessionData] = useState([]);
  const [thisTicketId, setThisTicketId] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [amountPaid, setAmountPaid] = useState("");
  const [amountDue, setAmountDue] = useState("");
  const [ticketNote, setTicketNote] = useState("");
  const [ticketNumber, setTicketNumber] = useState(null);
  const [followUp, setFollowUp] = useState(false)

  const [isFormValid, setIsFormValid] = useState(null);
  const [formErrMsg, setFormErrMsg] = useState("");  
  const [firstNameErrMsg, setFirstNameErrMsg] = useState("");  
  const [lastNameErrMsg, setLastNameErrMsg] = useState("");  
  const [phoneErrMsg, setPhoneErrMsg] = useState("");  

  const businessDomainName = GlobalState()?.businessData?.domainName; 
  const businessDomainNameActive = GlobalState()?.businessData?.isDomainNameActive; 
  const [rootDomainName, setRootDomainName] = useState(null)

  const [copied, setCopied] = useState(false);    
  const [showQrCode, setShowQrCode] = useState(false);    
  const [qRid, setQRid] = useState("");    
  const [qRfirstName, setQRfirstName] = useState("");    
  const [qRlastName, setQRlastName] = useState("");    
  const [qRTicketNumber, setQRTicketNumber] = useState("");  

  const [submitLoading, setSubmitLoading] = useState(false)
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false)
  const [bookingTicketId, setBookingTicketId] = useState("")



  const [booking, setBooking] = useState([]);
  

  //const [ticketURL, setTicketURL] = useState("");
  //const [isCompleted, setIsCompleted] = useState(false);
  //const [completedDateTime, setCompletedDateTime] = useState(null);
  // const [isAbsense, setIsAbsense] = useState(false);
  // const [isNowIn, setIsNowIn] = useState(false);
  // const [ticketStatus, setTicketStatus] = useState('accepted');


  
  

  useEffect ( () => {
    const amountDueClacUnsubscribe = () => {
      setAmountDue(Number(sessionData?.feesAmount) - Number(amountPaid))
    }
    return amountDueClacUnsubscribe();
  }, [sessionData?.feesAmount, amountPaid] )

  

  // retrevedCurrentSelectedBranchID.then(function (
  //   currentSelectedBranchIDResult
  // ) {
  //   try {
  //     setCurrentSelectedBranchID(currentSelectedBranchIDResult);
  //   } catch (error) {
  //     console.log(error)
  //   }
  // });

  // console.log('currentSelectedBranchID: ', currentSelectedBranchID)

  const sessionId = passedSessionId;


  useEffect(() => {    
    if (user) {
      if (isLoading === false) {
        const pending_tickets = `users/${uidRef}/business/${businessID}/branches/${currentSelectedBranchID}/sessions/${sessionId}/booking`; 
        const q = query(collection(db, pending_tickets),  
          orderBy('createdAt', 'asc'),     
          where("ticketStatus", "==", "pending"),    
        );

        // const bookingUnsub = await getDocs(q);
        // bookingUnsub.forEach((doc) => {
        //   // doc.data() is never undefined for query doc snapshots
        //   console.log(doc.id, " => ", doc.data());
        // });

        const bookingUnsub = onSnapshot(q, (snapshot) => {          
          setBooking(snapshot.docs.map(doc => ({...doc.data(), id: doc.id})));           
        })
              
        return () => bookingUnsub();   
      }
    }   
  }, [businessID, currentSelectedBranchID, sessionId, uidRef, user, isLoading]);  


  useEffect(() => {
    if (user) {
      setIsloading(false);
      if (isLoading === false) {
        const unsub = onSnapshot(
          doc(db, `users/${uidRef}/business/${businessID}/branches/${currentSelectedBranchID}/sessions/${sessionId}`),
          (doc) => {            
            setSessionData(doc.data());
          }
        );
        return () => unsub();
      }
    }
  }, [user, uidRef, businessID, currentSelectedBranchID, isLoading, sessionId]);


  const queueLenth = sessionData?.queueLenth;
  const nowTicket = sessionData?.nowTicket;

  useEffect ( () => {
    !firstName || !lastName || !phoneNumber || phoneNumber.length < 11 ? setIsFormValid(current => false) : setIsFormValid(current => true) 
  }, [firstName, lastName, phoneNumber] )


  useEffect(() => {
    setTicketNumber(Number(queueLenth + 1));
  }, [queueLenth]);

  // console.log('nowTicket:', nowTicket)
  // console.log('queueLenth', queueLenth)

  const [afterCreateTicket, setAfterCreateTicket] = useState(false);

  useEffect ( () => {
    if (businessDomainName && businessDomainNameActive === true) {
    setRootDomainName(businessDomainName)    
    } else {
      setRootDomainName(process.env.REACT_APP_DOMAIN)
    } 
  }, [businessDomainName, businessDomainNameActive] )



  const nowTime = parseInt( new Date().getTime() / 1000 );      
  const sessionEndDateTime = sessionData?.endDate?.seconds     
  const sessionRemainingEquation = parseInt ( (sessionEndDateTime - nowTime) / 60  )
  const [sessionRemainingHr, setSessionRemainingHr] = useState("")
  const [sessionRemainingMin, setSessionRemainingMin] = useState("")

  useEffect ( () => {
    const sessionRemainingTimeUnsub = () => {            
      setSessionRemainingHr( Number(parseInt (sessionRemainingEquation / 60)) )
      setSessionRemainingMin( Number(sessionRemainingEquation % 60) )                  
    } 
    return sessionRemainingTimeUnsub;
  } )


  const totalSessionMinutes = (sessionEndDateTime - nowTime) / 60;
  const remainingMinutes = sessionRemainingHr * 60 + sessionRemainingMin;
  const remainingPercentage = (remainingMinutes / totalSessionMinutes) * 100;




  const submitForm = async (e) => {
    e.preventDefault();    
    
    if (isFormValid === false) setFormErrMsg(`First name, Last name, Phone number are required`)
    
    if (user && isFormValid === true) {
      setSubmitLoading(current => true)
      const sessionDocRef = doc( db, `users/${uidRef}/business/${businessID}/branches/${currentSelectedBranchID}/sessions`, sessionId );
      const queueColRef = collection(sessionDocRef, "queues");

      await addDoc(
        queueColRef,
        {
          uidRef: uidRef,
          businessid: businessID,
          branchid: currentSelectedBranchID,
          sessionid: sessionId,
          createdBy: user.uid,
          firstName: firstName,
          lastName: lastName,
          phoneNumber: phoneNumber,
          amountPaid: Number(amountPaid),
          amountDue: Number(amountDue),
          ticketNote: ticketNote,
          ticketNumber: ticketNumber,
          isCompleted: false,
          isAbsense: false,
          isNowIn: false,
          followUp: followUp,
          ticketStatus: 'accepted',
          bookingTicketId: bookingTicketId, 
          ticketMove: 'enqueue',
          nowSessionTicket: sessionData.nowTicket,
          businessImg: businessImg,
          businessName: businessName,
          businessDesc: businessDesc,
          createdAt: serverTimestamp(),
        },
        { merge: false }
      )
      .then((docRef) => {
        setDoc(
          docRef,            
          {
            id: docRef.id,              
          },
          { merge: true },          
        );
        setThisTicketId(docRef.id)
      })           
      .then(() => {
        setIsApproveModalOpen(false)
        setAfterCreateTicket(true);        
      })
      .then( async() => {
        // updated the this booking ticket        
        await setDoc(doc(db, `users/${uidRef}/business/${businessID}/branches/${currentSelectedBranchID}/sessions/${sessionId}/booking`, bookingTicketId), {
          ticketStatus: 'approved',
            approvedTime: serverTimestamp(),
        },{ merge: true });
      } )      
      .catch((error) => console.error("Error adding document: ", error));      
      

      // Update Session nessesary data
      if (nowTicket === 0) {
        await setDoc(
          sessionDocRef,
          {                         
            nowTicket: ticketNumber,
            queueLenth: Number(sessionData?.queueLenth + 1),
            remaining: Number(sessionData?.remaining + 1)
          },
          { merge: true }
        )  
      } else {
        await setDoc(
          sessionDocRef,
          {                         
            queueLenth: Number(sessionData?.queueLenth + 1) ,
            remaining: Number(sessionData?.remaining + 1)             
          },
          { merge: true }
        ) 
      }        
                        
    }   
         
  };

  const createAnotherTicketHandler = useCallback(() => {
    // reset form fields
    setAfterCreateTicket(false);    
    setFirstName( (current) => "" )
    setLastName( (current) => "" )
    setPhoneNumber( (current) => "" )
    setAmountPaid( (current) => "" )
    setAmountDue( (current) => "" ) 
    setTicketNote( (current) => "" ) 
    setThisTicketId( (current) => "" )
    setSubmitLoading(current => false)
    //window.location.reload();
  }, []);

  const CopiedToast = () => {
    
    return (
      <>
        <div className="fixed inset-x-0 bottom-0 z-[200] flex justify-center items-center p-1 rounded-md bg-success mb-4 mx-40 drop-shadow-xl">        
            <span className='text-white uppercase font-semibold text-xs'>{t('Copied')}</span>
        </div> 
        {
          setTimeout(() => {
            setCopied( false)
          }, 1000)
        }
      </>
    );
    
  }


  const showQrCodeHandler = (id, firstName, lastName, ticketNumber) => {              
    setShowQrCode( true)
    setQRfirstName (firstName)
    setQRlastName (lastName)
    setQRTicketNumber (ticketNumber)
    setQRid(id)
    
    console.log("qr=> ", id, firstName, lastName)        
  }

  const showQrCodeReseter = () => {
    setShowQrCode( false)
    setQRfirstName ("")
    setQRlastName ("")
    setQRTicketNumber ("")
    setQRid("")
  }


  const approveThisTicket = (id, firstName, lastName, phoneNumber, amountDue, amountPaid, ticketStatus) => {
    setIsApproveModalOpen(true)
    setBookingTicketId(id)
    setFirstName(firstName)
    setLastName(lastName)
    setPhoneNumber(phoneNumber)
    setAmountPaid(amountPaid)
  }

  const closeApproveModal = () => {
    setIsApproveModalOpen(false)
    // reset form fields
    // setAfterCreateTicket((current) => false);    
    // setFirstName( (current) => "" )
    // setLastName( (current) => "" )
    // setPhoneNumber( (current) => "" )
    // setAmountPaid( (current) => "" )
    // setAmountDue( (current) => "" ) 
    // setTicketNote( (current) => "" ) 
    // setThisTicketId( (current) => "" )
    // setSubmitLoading(current => false)
    // setBookingTicketId("")
  }


  // console.log('this ticket id', thisTicketId)
  // console.log('isFormValid: ', isFormValid)
  // console.log('submitLoading: ', submitLoading)


  // console.log("fn>>>", firstName)
  // console.log("ln>>>", lastName)
  // console.log("isApproveModalOpen>>>", isApproveModalOpen)

  const followUpHandler = () => {
    setFollowUp(current => !current)
  }





  return (
    isLoading === true || uidRef === undefined || businessID === undefined || currentSelectedBranchID === null || sessionData === undefined || sessionEndDateTime === undefined || isNaN(sessionRemainingEquation) || isNaN(sessionRemainingHr) || isNaN(sessionRemainingMin) || isNaN(totalSessionMinutes) || isNaN(remainingMinutes) || isNaN(remainingPercentage) ? (
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='fixed w-screen h-screen inset-0 z-50 flex justify-center items-center'><div className='spinner'></div></motion.div>
    ) : (
      <>
        {copied === true ? CopiedToast() : null}
        { showQrCode === true ?
          <div className='fixed inset-0 z-[100] flex flex-col justify-center items-center rounded-md shadow-2xl backdrop-blur-md bg-white/80 mx-4 md:mx-80 my-40'>            
            <div className="relative flex flex-row items-start my-2">
              <span className="text-black/60 text-xl absolute -top-3 -left-3">#</span>
            <strong className="text-6xl">{ticketNumber -1}</strong>
          </div>
          <strong className='text-4xl mb-4'>{firstName} {lastName}</strong>
          <QRCodeSVG 
          size={300}                
          value={`${rootDomainName}/app/tracking/${uidRef}/${businessID}/${currentSelectedBranchID}/${sessionId}/${thisTicketId}`}                   
          />
          <button onClick={() => showQrCodeReseter()} className='flex justify-center items-center mt-4'>               
            <strong className='text-xs text-red-500 uppercase bg-red-300/10 py-4 px-7 rounded-md'>{t('Close')}</strong>
          </button>
          </div>
        : null
        }
      
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        //animate={{ opacity: 1, transition: { duration: 1 } }}
        exit={{ opacity: 0 }}>
        {
          afterCreateTicket === true ? (
            <motion.div
              className={`backdrop-blur-md fixed top-0 left-0 right-0 z-50 w-full h-screen flex-col ${
                afterCreateTicket ? "flex" : "hidden"
              }`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: .5 } }}
              exit={{ opacity: 0 }}>
              <motion.div
                className="grow flex flex-col justify-center items-center"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: .5 } }}>
                <Link to={`/app/session/watcher/${sessionId}`}>
                  <motion.div
                    className="flex flex-col justify-center items-center "
                    whileTap={{ scale: 0.9 }}>
                    <img src={BackToSessionIcon} alt="" className="w-2/3" />
                    <span className="uppercase mt-2 font-bold text-sm text-primary">
                      {t('Back to session')}
                    </span>
                  </motion.div>
                </Link>          
                {
                  sessionData?.queuePeak === 0 || sessionData?.queueLenth < sessionData?.queuePeak ? (
                  <motion.div className="mt-10" whileTap={{ scale: 0.9 }}>
                      <div
                        className="p-3 rounded-md bg-primary shadow-lg shadow-blue-500/50 text-white text-xs font-bold uppercase cursor-pointer flex flex-row items-center justify-between"
                        onClick={createAnotherTicketHandler}>
                        <IoTicketOutline fontSize={20} className={"text-white mr-2"} />    
                        <strong className="ltr:pl-1 rtl:pr-1">{t('Create another ticket')}</strong>
                      </div>
                    </motion.div>
                  ) : (
                    <div className='
                      mt-5
                      mx-auto
                      flex 
                      flex-col 
                      justify-center 
                      items-center                                           
                      z-10 
                      bg-red 
                      w-2/3
                      px-4
                    '>
                      <div className="alert alert-warning rounded-md ">
                        <div>
                          <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                          <span>{t('The session peak is reached!')}</span>
                        </div>
                      </div>
                      <Link to={"/app/edit-session"} state={{ 
                        passedSessionId: sessionId, 
                        passedStartDate: sessionData?.startDate,
                        passedEndDate: sessionData?.endDate, 
                        passedDepositAmount: sessionData?.depositAmount ,
                        passedFeesAmount: sessionData?.feesAmount,
                        passedSessionName: sessionData?.sessionName,
                        passedQueuePeak: sessionData?.queuePeak,
                        passedAvgTime: sessionData?.avgTime,
                        passedIsSessionActive: sessionData?.isActive
                        }} 
                        className='w-full mt-2'
                        >
                        
                            <motion.button 
                            className="
                              w-full
                              rounded-md 
                              font-bold 
                              uppercase 
                              text-xs 
                              py-5 
                              text-white 
                              hover:text-white 
                              focus:text-white 
                              bg-black 
                              hover:bg-black 
                              focus:bg-black           
                            "
                            whileTap={{ scale: 0.9 }}
                            >
                              {t('Change session settings')}
                            </motion.button>                
                        
                      </Link>
                    </div>
                  )
                }
              </motion.div>

              <motion.div
                className="grow-0 basis-1/2 bg-primary flex flex-col justify-center items-center pb-8 relative"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}>
                <div
                  className="absolute -top-1 left-0 right-0 h-1"
                  style={{ backgroundImage: `url(${TopBattern})` }}></div>
                
                <div className="relative flex flex-row items-start my-2">
                  <span className="text-white/60 text-xl absolute -top-3 -left-3">#</span>
                  <strong className="text-6xl text-white">{afterCreateTicket && ticketNumber -1}</strong>                        
                </div>
                <strong className="text-xl text-white block">{firstName} {lastName}</strong>            
                            
                <div className="flex flex-row justify-between items-center gap-3 mt-3">
                  <motion.a
                    href={`https://wa.me/+2${phoneNumber}?text=${businessName},%20Your%20tracking%20link%20is:%20${rootDomainName}/app/tracking/${uidRef}/${businessID}/${currentSelectedBranchID}/${sessionId}/${thisTicketId}`}
                    target="_blank"
                    className="flex flex-row justify-center items-center rounded-full p-5 bg-white border-white text-primary "
                    whileTap={{ scale: 0.9 }}>
                    <BsWhatsapp fontSize={20} className={"text-primary"} />              
                  </motion.a>

                  <motion.button
                    className="flex flex-row justify-center items-center rounded-full p-5 bg-white border-white text-primary "
                    whileTap={{ scale: 0.9 }}>                
                    <CopyToClipboard text={`${rootDomainName}/app/tracking/${uidRef}/${businessID}/${currentSelectedBranchID}/${sessionId}/${thisTicketId}`}
                      onCopy={() => setCopied(current => true)}>
                      <IoCopyOutline fontSize={20} className={"text-primary"} /> 
                    </CopyToClipboard>            
                  </motion.button>

                  <motion.button
                    className="flex flex-row justify-center items-center rounded-full p-5 bg-white border-white text-primary "
                    whileTap={{ scale: 0.9 }}
                    onClick={()=>{showQrCodeHandler(thisTicketId, firstName, lastName, ticketNumber -1)}}
                  >
                    <TbQrcode fontSize={20} className={"text-primary"} />              
                  </motion.button>

                  
                </div>
              </motion.div>
            </motion.div>
          ) : null
        }


        <Header title={t('Create Ticket')} />
        <div className="upper_container xs:mt-10 md:mt-10">
          {isLoading === false && sessionData && (
            <SessionInfoCap
              name={sessionData.sessionName}
              date={Dayjs(sessionData.startDate?.seconds * 1000).format(
                "ddd, DD MMM YYYY"
              )}
              startat={Dayjs(sessionData.startDate?.seconds * 1000).format(
                "h:mm A"
              )}
              endat={Dayjs(sessionData.endDate?.seconds * 1000).format("h:mm A")}
              url={`/session/watcher/${sessionId}`}
              remainingValue={sessionData.started === true ? (isNaN(remainingPercentage) ? null : remainingPercentage) : null}
              remainingTotal={isNaN(totalSessionMinutes) ? 0 : totalSessionMinutes}              
              remainingTime={
                sessionData.started === true
                  ? `${isNaN(sessionRemainingHr) ? '' : sessionRemainingHr}:${isNaN(sessionRemainingMin) ? '' : sessionRemainingMin} ${t('Remaining to end')}`
                  : null
              } 
            />
          )}
          <div className="mt-6">
            <Tabs value={tabValue}>
              <TabsHeader>
                <Tab key='create_ticket' value='create_ticket' onClick={createAnotherTicketHandler}>
                  {t('Create Ticket')}
                </Tab>
                <Tab key='pending_tickets' value='pending_tickets'>
                  {t('Bookings')} 
                  {
                    booking.length > 0 ? (
                      <div className="badge badge-[#dedede] rounded-lg pl-2 pr-2 ltr: ml-2 rtl:mr-2">
                        <span className="font-bold text-white">{booking.length}</span>
                      </div>
                    ) : null
                  }                
                </Tab>
              </TabsHeader>
              <TabsBody className="mt-4">
                <TabPanel value='create_ticket' className="p-0">
                  <div className="ticket_form">
                    <form onSubmit={submitForm}>
                      <div className="mb-7">
                        <strong className="text-neutral/50 uppercase text-2xs block font-bold">
                          {t('Ticket Details')}
                        </strong>
                        <div className="flex w-full mt-3">
                          <Input
                            type="text"
                            id='firstName'
                            className={firstName.length >= 3 ? "bg-teal-600/10" : null}
                            color="blue"
                            size="lg"
                            label={t('First name')}
                            icon={
                              <CgRename fontSize={20} className={"text-neutral/50"} />
                            }
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}                    
                            onBlur={(e) => {
                              setFirstNameErrMsg(t('First name is required'))
                              if (firstName.length <= 0) { 
                                e.target.classList.add("bg-red-600/10");
                                e.target.classList.remove("bg-teal-600/10")
                              } else {
                                e.target.classList.add("bg-teal-600/10"); 
                                e.target.classList.remove("bg-red-600/10")
                              }
                            }}                                          
                          required />
                        </div>
                        {!firstName && !isFormValid ? (<p className="text-sm text-red-400">{firstNameErrMsg}</p>) : null}
                        <div className="flex w-full mt-3">
                          <Input
                            type="text"
                            id='lastName'
                            className={lastName.length >= 3 ? "bg-teal-600/10" : null}
                            color="blue"
                            size="lg"
                            label={t('Last name')}
                            icon={
                              <CgRename fontSize={20} className={"text-neutral/50"} />
                            }
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}    
                            onBlur={(e) => {
                              setLastNameErrMsg(t('Last name is required'))
                              if (lastName.length <= 0) { 
                                e.target.classList.add("bg-red-600/10");
                                e.target.classList.remove("bg-teal-600/10")
                              } else {
                                e.target.classList.add("bg-teal-600/10"); 
                                e.target.classList.remove("bg-red-600/10")
                              }
                            }}              
                          required />
                        </div>
                        {!lastName && !isFormValid ? (<p className="text-sm text-red-400">{lastNameErrMsg}</p>) : null}
                        <div className="flex w-full mt-3">
                          <Input
                            type="number"
                            id='number'
                            className={phoneNumber.length === 11 ? "bg-teal-600/10" : null}
                            color="blue"
                            size="lg"
                            label={t('Phone number / Whatsapp')}
                            icon={
                              <TbDeviceMobile
                                fontSize={20}
                                className={"text-neutral/50"}
                              />
                            }
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}      
                            
                            onBlur={(e) => {
                              setPhoneErrMsg(t('Phone number / Whatsapp is required'))
                              if (phoneNumber.length !== 11) { 
                                e.target.classList.add("bg-red-600/10");
                                e.target.classList.remove("bg-teal-600/10")
                              } else {
                                e.target.classList.add("bg-teal-600/10"); 
                                e.target.classList.remove("bg-red-600/10")
                              }
                            }}          
                          required />
                        </div>
                        {!phoneNumber && !isFormValid ? (<p className="text-sm text-red-400">{phoneErrMsg}</p>) : null}
                        <div className="flex w-full mt-3">
                          <Input
                            type="number"
                            id='amountPaid'
                            className={amountPaid.length > 0 ? "bg-teal-600/10" : null}
                            color="blue"
                            size="lg"
                            label={t('Amount Paid')}
                            icon={<BiPound fontSize={20} className={"text-neutral/50"} />}
                            value={amountPaid}
                            onChange={(e) => setAmountPaid(e.target.value)}
                          />
                        </div>
                        <div className="flex w-full mt-3">
                          <Input
                            type="number"
                            id='amountDue'
                            className={amountDue.length > 0 ? "bg-teal-600/10" : null}
                            color="blue"
                            size="lg"
                            label={t('Amount Due')}
                            icon={<BiPound fontSize={20} className={"text-neutral/50"} />}
                            value={amountDue}
                            onChange={(e) => setAmountDue(e.target.value)}
                          />
                        </div>
                        {
                          businessCategory === 'clinic' || businessCategory === 'hospital' ?
                            (
                              <div className="form-control mt-3">
                                <label className="label cursor-pointer flex flex-row justify-start gap-2">                                        
                                    <input type="checkbox" className="toggle toggle-primary" 
                                    defaultChecked={followUp}
                                    onChange={followUpHandler}
                                    />
                                    <span className={`label-text ${followUp === true ? 'text-primary font-medium' : null}`}>{t('Follow Up')}</span>
                                </label>
                              </div>
                            ) : null                                                                          
                        }                       
                        <div className="flex w-full mt-3">
                            <Textarea 
                              type='text'                   
                              id='ticketNote' 
                              onChange={(e) => setTicketNote(e.target.value)}        
                              value={ticketNote}                                     
                              color="blue" 
                              size="lg" 
                              label={t('Ticket Note')}                                     
                            />
                        </div>          


                        {
                          isFormValid === false && formErrMsg !== "" ? (
                            <div className="alert alert-warning shadow-lg mt-5">
                              <div>
                                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                                <span>{formErrMsg}</span>
                              </div>
                            </div>
                          ) : null
                        }

                        {/* here */}
                        
                        
                        <div className="mt-7">
                          {/* <input type='submit' value='Create Session' className="btn btn-primary w-full text-white" /> */}
                          <button
                            type="submit"                  
                            className={`              
                              rounded-md 
                              font-bold 
                              uppercase 
                              text-xs 
                              w-full 
                              flex 
                              justify-center 
                              items-center 
                              py-5 
                              text-white                                                         
                              ${submitLoading === true ? 'bg-primary/50 cursor-wait ':'bg-primary hover:text-white focus:text-white hover:bg-primary focus:bg-primary cursor-pointer '}
                            `}
                            id="submit_adding_ticket"
                            //whileTap={{ scale: 0.9 }}
                            disabled={
                              submitLoading
                            }
                          >
                            {submitLoading ? (<Lottie animationData={LoadingAnimation} style={{ width: 19, height: 19 }} loop={true} />) : t('Add Ticket')}                  
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </TabPanel>
                <TabPanel value='pending_tickets' className="p-0">
                  <div className="mb-7">
                    {
                      booking.length > 0 ? (
                        <strong className="text-neutral/50 uppercase text-2xs block font-bold">
                          {t('Pending Tickets')}
                        </strong>
                      ) : null
                    }                  
                    <div className="flex w-full mt-3">
                      {
                        booking.length <= 0 ? (
                          <div className="flex flex-col justify-center items-center w-full">                          
                            <Lottie animationData={empty_box} style={{ height: 200 }} loop={true} />                          
                            <p className="text-2xl font-bold text-center text-black/90">{t('No pending tickets yet!')}</p>
                          </div>                        
                        ) : (
                          <div className="flex flex-col w-full">
                            {
                                booking.map( pending_ticket => (  
                                  <div key={pending_ticket.id} className="bg-accent mb-3 rounded-md p-5">
                                    <div className='flex flex-row justify-between items-start mb-2'>
                                      <div className='basis-3/5'>
                                        <strong className='text-xl'>{pending_ticket?.firstName + " " + pending_ticket?.lastName}</strong>
                                        <div className='flex flex-row justify-start items-start mt-1'>
                                          <div className='flex flex-col '>
                                            <span className='text-neutral/60 text-sm'>{t("Paid")}</span>
                                            <span className='font-bold -mt-1'>{pending_ticket.amountPaid ? pending_ticket?.amountPaid : 0} <small>{t('EGP')}</small></span>
                                          </div>
                                          <div className='flex flex-col ltr:ml-5 rtl:mr-5'>
                                            <span className='text-neutral/60 text-sm'>{t('Due')}</span>
                                            <span className='font-bold -mt-1'>{pending_ticket.amountDue ? pending_ticket?.amountDue : 0} <small>{t('EGP')}</small></span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='basis-3/5 flex flex-col justify-center items-end'>
                                        <strong className="font-bold">{Dayjs( pending_ticket.createdAt.seconds * 1000 ).format("ddd, DD MMM YYYY")}</strong>
                                        <strong className="font-bold">{Dayjs( pending_ticket.createdAt.seconds * 1000 ).format("h:m A")}</strong>                                                                                                                    
                                      </div>
                                    </div>
                                    <div className='flex justify-between items-center mt-5'>
                                      <div className="flex flex-row justify-start items-center">
                                        <motion.a href={`tel:${pending_ticket?.phoneNumber}`} whileTap={{ scale: 0.6 }} className="bg-white rounded-md px-3 py-2 shadow-sm ltr:mr-2 rtl:ml-2 flex items-center">
                                          <RiPhoneFill fontSize={16} className={"text-neutral"} />    
                                          <small className='ltr:ml-1 rtl:mr-1 uppercase font-semibold text-xs'>{t('Call')}</small>          
                                        </motion.a>
                                        <motion.a 
                                          href={`https://wa.me/+2${pending_ticket?.phoneNumber}`}
                                          target="_blank"
                                          whileTap={{ scale: 0.6 }} className="bg-white rounded-md px-3 py-2 shadow-sm ltr:mr-2 rtl:ml-2 flex items-center"
                                        >
                                          <BsWhatsapp fontSize={16} className={"text-neutral"} /> 
                                          <small className='ltr:ml-1 rtl:mr-1 uppercase font-semibold text-xs'>{t('Chat')}</small>                         
                                        </motion.a> 
                                      </div>                                                                                                                                                                                                   
                                      <div>
                                        <motion.button 
                                          onClick={ () => approveThisTicket(
                                            pending_ticket?.id,
                                            pending_ticket?.firstName, 
                                            pending_ticket?.lastName, 
                                            pending_ticket?.phoneNumber, 
                                            pending_ticket?.amountDue,
                                            pending_ticket?.amountPaid,
                                            pending_ticket?.ticketStatus,

                                          ) } 
                                          whileTap={{ scale: 0.6 }}                                           
                                          className="bg-primary rounded-md px-5 py-2 shadow-sm flex items-center"
                                        >
                                          <BsCheckAll fontSize={18} className={"text-white"} />    
                                          <small className='ltr:ml-1 rtl:mr-1 uppercase text-white font-semibold text-xs'>{t('Approve Booking')}</small>          
                                        </motion.button>
                                      </div>
                                    </div>                                    
                                  </div> 
                              ) )
                            }
                          </div>                        
                        )
                      }
                    </div>
                  </div>
                </TabPanel>
              </TabsBody>
            </Tabs>
          </div>        
        </div>
        {
          isApproveModalOpen === true ? (
            <div>
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='fixed inset-0 z-[2] w-screen h-screen bg-white/30 backdrop-blur-md cursor-pointer' onClick={(closeApproveModal)}></motion.div>                          
              <motion.div 
                className={`
                    fixed 
                    bottom-0 
                    left-0 
                    right-0 
                    container                     
                    xs:w-full 
                    sm:w-2/6 
                    3xl:w-2/6                     
                    backdrop-blur-md 
                    bg-white 
                    rounded-2xl
                    overflow-scroll
                    p-4
                    grow
                    
                    ${isApproveModalOpen && 'z-50' }
                `}   

                initial={{ y: "100%" }}
                animate={{ y: 0 }}
                exit={{ y: "100%" }}                             
                transition={{
                  delay: .2
                }}            
                style={{ boxShadow: '0px -10px 20px 0px rgba(0,0,0,0.10)', maxHeight: '90%' }}
                >
                  <div className='h-full'>
                    <div className='flex flex-col justify-center items-center border-b pt-3 pb-5'>              
                      <strong className='font-bold text-2xl text-teal-400'>{t('Approve This Ticket')}</strong>                    
                    </div>
                    <div className="ticket_form mt-5">
                      <form onSubmit={submitForm}>
                        <div className="mb-7">
                          <strong className="text-neutral/50 uppercase text-2xs block font-bold">
                            {t('Ticket Details')}
                          </strong>
                          <div className="flex w-full mt-3">
                            <Input
                              type="text"
                              id='firstName'
                              className={firstName.length >= 3 ? "bg-teal-600/10" : null}
                              color="blue"
                              size="lg"
                              label={t('First name')}
                              icon={
                                <CgRename fontSize={20} className={"text-neutral/50"} />
                              }
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}                    
                              onBlur={(e) => {
                                setFirstNameErrMsg(t('First name is required'))
                                if (firstName.length <= 0) { 
                                  e.target.classList.add("bg-red-600/10");
                                  e.target.classList.remove("bg-teal-600/10")
                                } else {
                                  e.target.classList.add("bg-teal-600/10"); 
                                  e.target.classList.remove("bg-red-600/10")
                                }
                              }}                                          
                            required />
                          </div>
                          {!firstName && !isFormValid ? (<p className="text-sm text-red-400">{firstNameErrMsg}</p>) : null}
                          <div className="flex w-full mt-3">
                            <Input
                              type="text"
                              id='lastName'
                              className={lastName.length >= 3 ? "bg-teal-600/10" : null}
                              color="blue"
                              size="lg"
                              label={t('Last name')}
                              icon={
                                <CgRename fontSize={20} className={"text-neutral/50"} />
                              }
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}    
                              onBlur={(e) => {
                                setLastNameErrMsg(t('Last name is required'))
                                if (lastName.length <= 0) { 
                                  e.target.classList.add("bg-red-600/10");
                                  e.target.classList.remove("bg-teal-600/10")
                                } else {
                                  e.target.classList.add("bg-teal-600/10"); 
                                  e.target.classList.remove("bg-red-600/10")
                                }
                              }}              
                            required />
                          </div>
                          {!lastName && !isFormValid ? (<p className="text-sm text-red-400">{lastNameErrMsg}</p>) : null}
                          <div className="flex w-full mt-3">
                            <Input
                              type="number"
                              id='number'
                              className={phoneNumber.length === 11 ? "bg-teal-600/10" : null}
                              color="blue"
                              size="lg"
                              label={t('Phone number / Whatsapp')}
                              icon={
                                <TbDeviceMobile
                                  fontSize={20}
                                  className={"text-neutral/50"}
                                />
                              }
                              value={phoneNumber}
                              onChange={(e) => setPhoneNumber(e.target.value)}      
                              
                              onBlur={(e) => {
                                setPhoneErrMsg(t('Phone number / Whatsapp is required'))
                                if (phoneNumber.length !== 11) { 
                                  e.target.classList.add("bg-red-600/10");
                                  e.target.classList.remove("bg-teal-600/10")
                                } else {
                                  e.target.classList.add("bg-teal-600/10"); 
                                  e.target.classList.remove("bg-red-600/10")
                                }
                              }}          
                            required />
                          </div>
                          {!phoneNumber && !isFormValid ? (<p className="text-sm text-red-400">{phoneErrMsg}</p>) : null}
                          <div className="flex w-full mt-3">
                            <Input
                              type="number"
                              id='amountPaid'
                              className={amountPaid.length > 0 ? "bg-teal-600/10" : null}
                              color="blue"
                              size="lg"
                              label={t('Amount Paid')}
                              icon={<BiPound fontSize={20} className={"text-neutral/50"} />}
                              value={amountPaid}
                              onChange={(e) => setAmountPaid(e.target.value)}
                            />
                          </div>
                          <div className="flex w-full mt-3">
                            <Input
                              type="number"
                              id='amountDue'
                              className={amountDue.length > 0 ? "bg-teal-600/10" : null}
                              color="blue"
                              size="lg"
                              label={t('Amount Due')}
                              icon={<BiPound fontSize={20} className={"text-neutral/50"} />}
                              value={amountDue}
                              onChange={(e) => setAmountDue(e.target.value)}
                            />
                          </div>
                          {
                            businessCategory === 'clinic' || businessCategory === 'hospital' ?
                              (
                                <div className="form-control mt-3">
                                  <label className="label cursor-pointer flex flex-row justify-start gap-2">                                        
                                      <input type="checkbox" className="toggle toggle-primary" 
                                      defaultChecked={followUp}
                                      onChange={followUpHandler}
                                      />
                                      <span className={`label-text ${followUp === true ? 'text-primary font-medium' : null}`}>{t('Follow Up')}</span>
                                  </label>
                                </div>
                              ) : null                                                                          
                          } 
                          <div className="flex w-full mt-3">
                              <Textarea 
                                type='text'                   
                                id='ticketNote' 
                                onChange={(e) => setTicketNote(e.target.value)}        
                                value={ticketNote}                                     
                                color="blue" 
                                size="lg" 
                                label={t('Ticket Note')}                                     
                              />
                          </div>          


                          {
                            isFormValid === false && formErrMsg !== "" ? (
                              <div className="alert alert-warning shadow-lg mt-5">
                                <div>
                                  <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                                  <span>{formErrMsg}</span>
                                </div>
                              </div>
                            ) : null
                          }                        
                          
                          
                          <div className="mt-7">
                            {/* <input type='submit' value='Create Session' className="btn btn-primary w-full text-white" /> */}
                            <button
                              type="submit"                  
                              className={`              
                                rounded-md 
                                font-bold 
                                uppercase 
                                text-xs 
                                w-full 
                                flex 
                                justify-center 
                                items-center 
                                py-5 
                                text-white                                                         
                                ${submitLoading === true ? 'bg-primary/50 cursor-wait ':'bg-primary hover:text-white focus:text-white hover:bg-primary focus:bg-primary cursor-pointer '}
                              `}
                              id="submit_adding_ticket"
                              //whileTap={{ scale: 0.9 }}
                              disabled={
                                submitLoading
                              }
                            >
                              {submitLoading ? (<Lottie animationData={LoadingAnimation} style={{ width: 19, height: 19 }} loop={true} />) : t('Approve Ticket')}                  
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>                       
                  </div>
              </motion.div>
            </div> 
          ) : null
        }      
      </motion.div>
      </>
    )
  );
};

export default CreateTicket;
