import React, {useEffect, useState} from 'react';
import { Header, BusinessWithBranch, DashboardElements } from '../../components/app';
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
//import { IsCompleted, FirstName, LastName, RegisterStep } from '../../helper/Globals';
import { GlobalState } from '../../context/StateContext';
import { TbAlertTriangle } from 'react-icons/tb';



const Dashboard = () => {
        
  // const isCompleted = IsCompleted();
  // const firstName = FirstName();
  // const lastName = LastName();
  // const registerStep = RegisterStep();
  const { t } = useTranslation();  
  const isActive = GlobalState()?.userData?.isActive;
  const isCompleted = GlobalState()?.userData?.isCompleted;  
  const firstName = GlobalState()?.userData?.firstName;
  const lastName = GlobalState()?.userData?.lastName;
  const registerStep = GlobalState()?.userData?.registerStep;


  useEffect( () => {     
    document.title = 'Dashboard';
  },[] )


  return (
    <>      
      {
      isCompleted === 'undefined' ?    
        <div className='fixed z-20 flex flex-col h-screen justify-center items-center'>
          <progress className="progress progress-primary w-56"></progress>
        </div>          
      : isCompleted === false && registerStep === 0 ?
        <div className='w-auto h-screen flex flex-col justify-center items-center'>        
          <TbAlertTriangle fontSize={70} className={'text-neutral'} />
          <h1 className='text-4xl font-bold text-center'>{t('Your Account is not completed yet!')}</h1>
          <Link to="/app/create-business-owner" className='btn btn-primary mt-4 text-white font-black'>{t('Complete it to continue')}</Link>
        </div>  
      : isCompleted === false && registerStep === 1 ?
        <div className='w-auto h-screen flex flex-col justify-center items-center'>        
          <TbAlertTriangle fontSize={70} className={'text-neutral'} />
          <h1 className='text-4xl font-bold text-center'>{t('Your Account is not completed yet!')}</h1>
          <Link to="/app/create-business-owner" className='btn btn-primary mt-4 text-white font-black'>{t('Complete it to continue')}</Link>
        </div>      
      : isCompleted === false && registerStep === 2 ?
        <div className='w-auto h-screen flex flex-col justify-center items-center'>        
          <TbAlertTriangle fontSize={70} className={'text-neutral'} />
          <h1 className='text-4xl font-bold text-center'>{t('Your Account is not completed yet!')}</h1>
          <Link to="/app/create-business" className='btn btn-primary mt-4 text-white font-black'>{t('Complete it to continue')}</Link>
        </div>      
      : isActive === false ?
        <div className='w-auto h-screen flex flex-col justify-center items-center'>        
          <TbAlertTriangle fontSize={70} className={'text-neutral'} />
          <h1 className='text-4xl font-bold text-center'>{t('Your account has been suspended!')}</h1>
          <Link to="/contact-us" className='btn btn-primary mt-4 text-white font-black'>{t('Contact Support')}</Link>
        </div>           
      :         
        <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        //animate={{ opacity: 1, transition: { duration: 1 } }}
        exit={{ opacity: 0 }}
        >
          <Header title ={t('Dashboard')} />          
          <div className='xs:pt-10 md:pt-28 mb-4'>
            <BusinessWithBranch />
            <div className='xs:pt-10 md:pt-28'>
              <div className='flex flex-col items-start'>
                <span className='text-sm text-neutral/70'>{t('Welcome')}</span>
                <strong className='text-4xl text-neutral'>{firstName && firstName} {lastName && lastName}</strong>
              </div>
              <div className='mt-3'>
                <DashboardElements />
              </div>                        
            </div>
          </div>
        </motion.div>
      }
      
    </>    
  )
}

export default Dashboard