import React, { useCallback, useState, useEffect } from "react";
import { db, auth } from "../../utils/firebase-config.js";
import { useTranslation } from "react-i18next";
import {
  onSnapshot,
  doc,
  collection,
  addDoc,
  serverTimestamp,
  setDoc,
  query,
  where,
  orderBy
} from "firebase/firestore";
import { GlobalState } from "../../context/StateContext";
//import GetCurrentBranch from "../../helper/GetCurrentBranch";
import NewGetCurrentBranch from "../../helper/NewGetCurrentBranch.js";
import { Header, SessionInfoCap } from "../../components/app";
import { motion } from "framer-motion";
import Dayjs from "dayjs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Input, 
  Textarea,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
 } from "@material-tailwind/react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Lottie from "lottie-react";
import {QRCodeSVG} from 'qrcode.react';
import { BiPound } from "react-icons/bi";
import { BsWhatsapp, BsCheckAll } from "react-icons/bs";
import { CgRename } from "react-icons/cg";
import { TbDeviceMobile, TbQrcode } from "react-icons/tb";
import { IoCopyOutline, IoTicketOutline } from "react-icons/io5";
import { RiPhoneFill } from "react-icons/ri";
import BackToSessionIcon from "../../assets/svgs/back_to_session_icon.svg";
import TopBattern from "../../assets/svgs/top_pattern.svg";
import LoadingAnimation from '../../assets/jsons/lottie/loading.json'
import empty_box from "../../assets/jsons/lottie/empty_box.json";
import { MdArrowBack, MdOutlineArrowForward } from 'react-icons/md';

const EditTicket = () => {

  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { 
    passedSessionId = null, 
    passedTicketId = null, 
    passedFirstName = null, 
    passedLastName = null, 
    passedPhoneNumber = null, 
    passedPaid = null, 
    passedDue = null,
    passedNote = null
  } = location.state || {};
  const user = auth.currentUser;
  const uidRef = GlobalState()?.userData?.id;
  const businessID = GlobalState()?.businessData?.id;
  const currentSelectedBranchID = NewGetCurrentBranch();
  const sessionId = passedSessionId
  
  //const [isLoading, setIsloading] = useState(true);
  // const [sessionData, setSessionData] = useState([]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [amountPaid, setAmountPaid] = useState("");
  const [amountDue, setAmountDue] = useState("");
  const [ticketNote, setTicketNote] = useState("");

  const [isFormValid, setIsFormValid] = useState(null);
  const [formErrMsg, setFormErrMsg] = useState("");  
  const [firstNameErrMsg, setFirstNameErrMsg] = useState("");  
  const [lastNameErrMsg, setLastNameErrMsg] = useState("");  
  const [phoneErrMsg, setPhoneErrMsg] = useState("");  
  const [submitLoading, setSubmitLoading] = useState(false)


  useEffect( () => {
    setFirstName(passedFirstName);
    setLastName(passedLastName);
    setPhoneNumber(passedPhoneNumber);
    setAmountPaid(passedPaid);
    setAmountDue(passedDue);
    setTicketNote(passedNote)
  }, [passedFirstName, passedLastName, passedPhoneNumber, passedPaid, passedDue, passedNote] )

  useEffect ( () => {
    !firstName || !lastName || !phoneNumber || phoneNumber.length < 11 ? setIsFormValid(current => false) : setIsFormValid(current => true) 
  }, [firstName, lastName, phoneNumber] )


  const submitForm = async (e) => {
    e.preventDefault();    
    
    if (isFormValid === false) setFormErrMsg(`First name, Last name, Phone number are required`)
    
    if (user && isFormValid === true) {
      setSubmitLoading(current => true)                     
      const ticketDocRef = doc( db, `users/${uidRef}/business/${businessID}/branches/${currentSelectedBranchID}/sessions/${sessionId}/queues`, passedTicketId );
      await setDoc(ticketDocRef, {
        editedBy: user.uid,
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber,
        amountPaid: Number(amountPaid),
        amountDue: Number(amountDue),
        ticketNote: ticketNote,        
        editedAt: serverTimestamp(),
      }, {
        merge: true
      }).then(() => console.log("Document updated"));

      navigate('/app/session/all-tickets/' + sessionId);
    }            
  };



  console.log(passedFirstName)

  return (
    passedSessionId === null || passedTicketId === null || passedFirstName === null || passedLastName === null || passedPhoneNumber === null || passedPaid === null || passedDue === null || passedNote === null  ? (
      <div className='hero container max-w-screen-lg mx-auto pb-10 flex items-center justify-center'>
        <div className='flex flex-col justify-center items-center mx-auto'>
          {/* <Lottie animationData={err404} style={{  }} loop={false} />  */}
          <button className='btn mt-7' onClick={() => navigate(`/app/`)}>Go Back</button>
        </div>
      </div>      
    ) : (
        <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="pb-7"
        > 
          <Header title={t('Edit Ticket')} />
          <div className="upper_container xs:mt-10 md:mt-10 ">
          <Link to={'/app/session/all-tickets/' + sessionId} className="flex flex-row justify-start items-center cursor-pointer mb-6">                                                
                {
                  localStorage.getItem("dir") === 'ltr' ? (
                    <MdArrowBack fontSize={30} className={"text-primary"}/>
                  ) : (
                    <MdOutlineArrowForward fontSize={30} className={"text-primary"}/>
                  )
                }
                <strong className="mx-2 text-primary uppercase">{t('Back to all tickets')}</strong>
              </Link>
            <div className="ticket_form">
              <form onSubmit={submitForm}>
                <div className="mb-7">
                  <strong className="text-neutral/50 uppercase text-2xs block font-bold">
                    {t('Ticket Details')}
                  </strong>
                  <div className="flex w-full mt-3">
                    <Input
                      type="text"
                      id='firstName'
                      className={firstName.length >= 3 ? "bg-teal-600/10" : null}
                      color="blue"
                      size="lg"
                      label={t('First name')}
                      icon={
                        <CgRename fontSize={20} className={"text-neutral/50"} />
                      }
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}                    
                      onBlur={(e) => {
                        setFirstNameErrMsg(t('First name is required'))
                        if (firstName.length <= 0) { 
                          e.target.classList.add("bg-red-600/10");
                          e.target.classList.remove("bg-teal-600/10")
                        } else {
                          e.target.classList.add("bg-teal-600/10"); 
                          e.target.classList.remove("bg-red-600/10")
                        }
                      }}                                          
                    required />
                  </div>
                  {!firstName && !isFormValid ? (<p className="text-sm text-red-400">{firstNameErrMsg}</p>) : null}
                  <div className="flex w-full mt-3">
                    <Input
                      type="text"
                      id='lastName'
                      className={lastName.length >= 3 ? "bg-teal-600/10" : null}
                      color="blue"
                      size="lg"
                      label={t('Last name')}
                      icon={
                        <CgRename fontSize={20} className={"text-neutral/50"} />
                      }
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}    
                      onBlur={(e) => {
                        setLastNameErrMsg(t('Last name is required'))
                        if (lastName.length <= 0) { 
                          e.target.classList.add("bg-red-600/10");
                          e.target.classList.remove("bg-teal-600/10")
                        } else {
                          e.target.classList.add("bg-teal-600/10"); 
                          e.target.classList.remove("bg-red-600/10")
                        }
                      }}              
                    required />
                  </div>
                  {!lastName && !isFormValid ? (<p className="text-sm text-red-400">{lastNameErrMsg}</p>) : null}
                  <div className="flex w-full mt-3">
                    <Input
                      type="number"
                      id='number'
                      className={phoneNumber.length === 11 ? "bg-teal-600/10" : null}
                      color="blue"
                      size="lg"
                      label={t('Phone number / Whatsapp')}
                      icon={
                        <TbDeviceMobile
                          fontSize={20}
                          className={"text-neutral/50"}
                        />
                      }
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}      
                      
                      onBlur={(e) => {
                        setPhoneErrMsg(t('Phone number / Whatsapp is required'))
                        if (phoneNumber.length !== 11) { 
                          e.target.classList.add("bg-red-600/10");
                          e.target.classList.remove("bg-teal-600/10")
                        } else {
                          e.target.classList.add("bg-teal-600/10"); 
                          e.target.classList.remove("bg-red-600/10")
                        }
                      }}          
                    required />
                  </div>
                  {!phoneNumber && !isFormValid ? (<p className="text-sm text-red-400">{phoneErrMsg}</p>) : null}
                  <div className="flex w-full mt-3">
                    <Input
                      type="number"
                      id='amountPaid'
                      className={amountPaid.length > 0 ? "bg-teal-600/10" : null}
                      color="blue"
                      size="lg"
                      label={t('Amount Paid')}
                      icon={<BiPound fontSize={20} className={"text-neutral/50"} />}
                      value={amountPaid}
                      onChange={(e) => setAmountPaid(e.target.value)}
                    />
                  </div>
                  <div className="flex w-full mt-3">
                    <Input
                      type="number"
                      id='amountDue'
                      className={amountDue.length > 0 ? "bg-teal-600/10" : null}
                      color="blue"
                      size="lg"
                      label={t('Amount Due')}
                      icon={<BiPound fontSize={20} className={"text-neutral/50"} />}
                      value={amountDue}
                      onChange={(e) => setAmountDue(e.target.value)}
                    />
                  </div>    
                  <div className="flex w-full mt-3">
                      <Textarea 
                        type='text'                   
                        id='ticketNote' 
                        onChange={(e) => setTicketNote(e.target.value)}        
                        value={ticketNote}                                     
                        color="blue" 
                        size="lg" 
                        label={t('Ticket Note')}                                     
                      />
                  </div>                          


                  {
                    isFormValid === false && formErrMsg !== "" ? (
                      <div className="alert alert-warning shadow-lg mt-5">
                        <div>
                          <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                          <span>{formErrMsg}</span>
                        </div>
                      </div>
                    ) : null
                  }

                  {/* here */}
                  
                  
                  <div className="mt-7">
                    {/* <input type='submit' value='Create Session' className="btn btn-primary w-full text-white" /> */}
                    <button
                      type="submit"                  
                      className={`              
                        rounded-md 
                        font-bold 
                        uppercase 
                        text-xs 
                        w-full 
                        flex 
                        justify-center 
                        items-center 
                        py-5 
                        text-white                                                         
                        ${submitLoading === true ? 'bg-primary/50 cursor-wait ':'bg-primary hover:text-white focus:text-white hover:bg-primary focus:bg-primary cursor-pointer '}
                      `}
                      id="submit_adding_ticket"
                      //whileTap={{ scale: 0.9 }}
                      disabled={
                        submitLoading
                      }
                    >
                      {submitLoading ? (<Lottie animationData={LoadingAnimation} style={{ width: 19, height: 19 }} loop={true} />) : t('Edit Ticket')}                  
                    </button>
                  </div>
                </div>
              </form>
            </div>            
          </div>
        </motion.div>
      )
  )
}

export default EditTicket