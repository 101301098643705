import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
//import { BsGlobe2 } from "react-icons/bs";


const ChangeLangDir = (props) => {
  const { i18n } = useTranslation();
  const [dir, setDir] = useState(localStorage.getItem('dir') || 'ltr');  
  
  useEffect(() => {
    localStorage.setItem('dir', dir)
    if ( dir === 'ltr' ) {
      document.getElementsByTagName('html')[0].setAttribute("dir", "ltr"); 
      document.querySelector('body').classList.remove('rtl');
    } else {
      document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");  
      document.querySelector('body').classList.add('rtl');
    }    
  }, [dir])

  const handleClick = () => {
    if (i18n.language === 'en' || i18n.language === 'en-US' || i18n.language === 'en-GB') {      
      i18n.changeLanguage('ar');
      setDir('rtl');      
      localStorage.setItem('dir', 'rtl');
      document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");      
      document.querySelector('body').classList.add('rtl');
      //window.location.reload();
    } else {      
      i18n.changeLanguage('en');
      setDir('ltr');      
      localStorage.setItem('dir', 'ltr');
      document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
      document.querySelector('body').classList.remove('rtl');
      //window.location.reload();
    }
  }

  return (
    <button className={props.classNameProp} onClick={handleClick}>
      <p className={props.classNamePropText}>
        { i18n.language === 'en-US' || i18n.language === 'en' || i18n.language === 'en-GB' ? "عربي" : "English" }
      </p>      
    </button>
  );
}

export default ChangeLangDir