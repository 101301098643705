import React, { useState, useEffect } from 'react';
import { db } from '../../utils/firebase-config';
import { useTranslation } from 'react-i18next';
import ChangeLangDir from '../../helper/ChangeLangDir';
import { motion } from "framer-motion";
import { addMinutes, format, getTime, isToday, formatDistanceStrict } from 'date-fns';
import { arSA } from 'date-fns/locale';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import Lottie from "lottie-react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { IoReload } from "react-icons/io5";
import { BsFillCalendarXFill } from "react-icons/bs";
import default_business from "../../assets/jsons/lottie/default_business.json";
import ticket_icon from "../../assets/images/ticket.png";
import alert_circle from "../../assets/jsons/lottie/alert_circle.json";
import success from "../../assets/jsons/lottie/success.json";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Input, Textarea } from "@material-tailwind/react";
import { CgRename } from "react-icons/cg";
import { BsWhatsapp } from "react-icons/bs";
import { BsTelephoneFill } from "react-icons/bs";
import { TbDeviceMobile } from "react-icons/tb";
import { FiAlertCircle } from "react-icons/fi";
import LoadingAnimation from '../../assets/jsons/lottie/loading.json';
import queueImg from "../../assets/jsons/lottie/queue.json";
import {
  onSnapshot,
  doc,
  collection,
  addDoc,
  serverTimestamp,
  setDoc
} from "firebase/firestore";

const BookSession = () => {
  const params = useParams();
  const location = useLocation();
  const userId = params.uId;
  const sessionId = params.sId;
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  //const nowTime = format(new Date(), 'h:mm aaa');
  const nowTimeRowSeconds = getTime(new Date()) * 1000;
  const nowTimeRow = new Date();

  // Define a state variable to keep track of whether an error has occurred
  const [hasError, setHasError] = useState(false);

  // Destructure the location state variables, and set defaults in case they are not present
  const { 
    passedUid = null, 
    passedBid = null, 
    passedSelectedBranchId = null, 
    passedBusinessLogo = null, 
    passedBusinessName = null, 
    passedBusinessDesc = null,
    passedSelectedBranchName = null,
    passedSelectedBranchPhoneNumber = null,
    passedSelectedBranchWhatsapp = null,
    passedSelectedBranchGovernorate = null,
    passedSelectedBranchArea = null,
    passedSelectedBranchFullAddress = null,
    passedStartDateFull = null,
    passedEndDateFull = null,
    passedStartDate = null,
    passedEndDate = null ,
    passedAvgTime = null,
    passedRemaining = null,
    passedIsStarted = null,
    passedSessionName = null,
    passedSessionPeak = null,
    passedStartDateTimeRow = null,
    passedEndDateTimeRow = null
  } = location.state || {};


  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");


  const [isFormValid, setIsFormValid] = useState(null);
  const [formErrMsg, setFormErrMsg] = useState("");  
  const [firstNameErrMsg, setFirstNameErrMsg] = useState("");  
  const [lastNameErrMsg, setLastNameErrMsg] = useState("");  
  const [phoneErrMsg, setPhoneErrMsg] = useState("");  

  const [submitLoading, setSubmitLoading] = useState(false)

  const[isBookingSuccessed, setIsBookingSuccessed] = useState(false)

  const [updateComponent, setUpdateComponent] = useState(0)

  const sessionName = passedSessionName;
  //const isStarted = passedIsStarted;
  const remaining = passedRemaining;
  const peak = passedSessionPeak;
  const avgTime = passedAvgTime;
  const startTime = passedStartDate;
  const startDateTimeRow= passedStartDateTimeRow;
  const startTimeRow = new Date(startDateTimeRow);


  const calculateEntranceTime = (remaining, avgTime, startDateTimeRow) => {
    const entranceTime = startDateTimeRow + avgTime * 60000 * remaining;
    return format(entranceTime, 'h:mm aaa', i18n.language === 'ar' && { locale: arSA });
  }
  const estimatedEntranceTime = calculateEntranceTime(remaining, avgTime, startDateTimeRow);



  const calculateEntranceTimeIfExceeded = (remaining, avgTime, nowTimeRow) => {
    const entranceTime = nowTimeRow ;
    const unixTimestamp = entranceTime / 1000000; // convert microseconds to seconds
    const dateObj = new Date(unixTimestamp * 1000 + avgTime * 60000 * remaining); // convert to milliseconds
    return format(dateObj, 'h:mm aaa', i18n.language === 'ar' && { locale: arSA });   
  };
  const exceededStartTimeEntranceTime = calculateEntranceTimeIfExceeded(remaining, avgTime, nowTimeRowSeconds);



  // const calculateEntranceTimeIfExceeded = (remaining, avgTime, startTime, nowTimeRow) => {
  //   const totalBookingTime = remaining * avgTime;
  //   const startDateTime = new Date();
  //   const [startHours, startMinutes, startAmPm] = startTime.split(/[:\s]/);
  //   startDateTime.setHours(parseInt(startHours, 10) + (startAmPm === 'PM' ? 12 : 0));
  //   startDateTime.setMinutes(parseInt(startMinutes, 10));
  //   const entranceTime = addMinutes(startDateTime, totalBookingTime);
  //   const nowTime = new Date();
  //   const combinedTime = addMinutes(entranceTime, nowTime.getHours() * 60 + nowTime.getMinutes());
  //   const formattedTime = format(combinedTime, 'h:mm aaa');
  //   return formattedTime;
  // };


  const SessionName = () => {
    return (
      <div><span className='name badge badge-sm border-none bg-thirdparty font-bold text-neutral/70 p-3 mb-3'>{sessionName}</span></div>
    )
  }

  
  // console.log("sessionName", sessionName)
  // console.log("isStarted", isStarted)
  // console.log("remaining", remaining)
  // console.log("estimatedEntranceTime", estimatedEntranceTime)


  //const remainingSeconds = (remaining * avgTime) + startDateTimeRow

  //console.log("start", startDateTimeRow)
  //console.log("now", nowTimeRow)
  //console.log("now/", nowTimeRow / 1000)
  //console.log("now", nowTimeRow)
  console.log("avgTime", avgTime)
  console.log("remaining", remaining)
  //console.log("passedStartDateFull", passedStartDateFull)
  //console.log('start ', new Date(startDateTimeRow))
  //console.log("isToday", isToday(new Date(passedStartDateFull)))
  
  //console.log("remainingSeconds", startDateTimeRow + avgTime * 60000 * remaining)

  // console.log(passedStartDate)
  // console.log(nowTime)





  //&& isStarted === true && estimatedEntranceTime > passedStartDate

  const TicketStatus = () => {
    switch(true) {
      case (remaining > 0 && !isToday(new Date(passedStartDateFull))  ):
        return (
          <div className='bg-yellow-100 rounded-md p-5 w-full mt-4 flex items-center'>
            <img className="w-10 h-10" src={ticket_icon} alt={sessionName}/>
            <div className='flex flex-col ltr:ml-3 rtl:mr-3'>
              <strong>{t('Estimated time to enter!')}</strong>
              <span>{estimatedEntranceTime}</span>
            </div>
          </div>
        )
      case (remaining === 0 && !isToday(new Date(passedStartDateFull))  ):
        return (
          <div className='bg-yellow-100 rounded-md p-5 w-full mt-4 flex items-center'>
            <img className="w-10 h-10" src={ticket_icon} alt={sessionName}/>
            <div className='flex flex-col ltr:ml-3 rtl:mr-3'>
              <strong>{t('You will be the first to enter at')}</strong>
              <span>{estimatedEntranceTime}</span>
            </div>
          </div>
        )
      case (remaining > 0 && isToday(new Date(passedStartDateFull)) && nowTimeRow < startTimeRow  ):
        return (
          <div className='bg-yellow-100 rounded-md p-5 w-full mt-4 flex items-center'>
            <img className="w-10 h-10" src={ticket_icon} alt={sessionName}/>
            <div className='flex flex-col ltr:ml-3 rtl:mr-3'>
              <strong>{t('Estimated time to enter!')}</strong>
              <span>{estimatedEntranceTime}</span>
            </div>            
          </div>
        )
      case (remaining === 0 && isToday(new Date(passedStartDateFull)) && nowTimeRow < startTimeRow ):
        return (
          <div className='bg-yellow-100 rounded-md p-5 w-full mt-4 flex items-center'>
            <img className="w-10 h-10" src={ticket_icon} alt={sessionName}/>
            <div className='flex flex-col ltr:ml-3 rtl:mr-3'>
              <strong>{t('You will be the first to enter at')}</strong>
              <span>{estimatedEntranceTime}</span>
            </div>
          </div>
        ) 
      case ( remaining > 0 && isToday(new Date(passedStartDateFull)) && nowTimeRow > startTimeRow ):
        return (
          <div className='bg-yellow-100 rounded-md p-5 w-full mt-4 flex items-center'>
            <img className="w-10 h-10" src={ticket_icon} alt={sessionName}/>
            <div className='flex flex-col ltr:ml-3 rtl:mr-3'>
              <strong>{t('Estimated time to enter!')}</strong>
              <span>{ exceededStartTimeEntranceTime }</span>
            </div>
          </div>
        )      
      case (remaining === 0 && isToday(new Date(passedStartDateFull)) && nowTimeRow > startTimeRow ):
        return (
          <div className='bg-yellow-100 rounded-md p-5 w-full mt-4 flex items-center'>
            <img className="w-10 h-10" src={ticket_icon} alt={sessionName}/>
            <div className='flex flex-col ltr:ml-3 rtl:mr-3'>
              <strong>{t('You will be the first to enter at')}</strong>
              <span>{ exceededStartTimeEntranceTime }</span>
            </div>
          </div>
        )           
      case ( remaining > 0 ):
        return (
          <div className='bg-yellow-100 rounded-md p-5 w-full mt-4 flex items-center'>
            <img className="w-10 h-10" src={ticket_icon} alt={sessionName}/>
            <div className='flex flex-col ltr:ml-3 rtl:mr-3'>
              <strong>{t('Estimated time to enter!')}</strong>
              <span>{estimatedEntranceTime}</span>
            </div>
          </div>
        )      
      case (remaining === 0 ):
        return (
          <div className='bg-yellow-100 rounded-md p-5 w-full mt-4 flex items-center'>
            <img className="w-10 h-10" src={ticket_icon} alt={sessionName}/>
            <div className='flex flex-col ltr:ml-3 rtl:mr-3'>
              <strong>{t('You will be the first to enter at')}</strong>
              <span>{passedStartDate}</span>
            </div>
          </div>
        )
      default:
        return null
    }
  }
  


  // else {
  //   return (
  //     <div className='bg-yellow-100 rounded-md p-5 w-full mt-4 flex items-center'>
  //       <LazyLoadImage effect="blur" className="w-10 h-10" src={ticket_icon} />
  //       <div className='flex flex-col ltr:ml-3 rtl:mr-3'>
  //         <strong>{t('You will be the first to enter')}</strong>
                      
  //       </div>              
  //     </div>
  //   );
  // }

  

  const SessionEndedComp = () => {
    return (
      <div className='flex flex-col justify-center items-center mt-8'>
        <BsFillCalendarXFill fontSize={30} className={"text-red-500"} />
        <strong className='my-2 text-2xl text-center text-red-500'>{t('This appointment time has ended!')}</strong>
        <button onClick={() => navigate(-1)} className='px-3 h-10 flex items-center bg-primary/20 rounded-md'>          
          <span className='text-primary font-bold text-xs uppercase'>{t('Choos another appointment')}</span>
        </button>
      </div>
    )
  }
  

  // useEffect( () => {
  //   // setTimeout( () => setUpdateComponent( current => current + 1 ), 5 * 60 * 1000 )

  //   function runEveryMinute() {      
  //     setUpdateComponent( current => current + 1 )
  //   }

  //   const now = new Date();
  //   const timeUntilNextMinute = (60 - now.getSeconds()) * 1000;

  //   // Wait until the next full minute before starting the interval
  //   setTimeout(() => {
  //     runEveryMinute(); // Run the function immediately
  //     setInterval(runEveryMinute, 60000); // Then run it every minute thereafter
  //   }, timeUntilNextMinute);

  // }, [updateComponent] )

  useEffect(() => {
  function runEveryMinute() {      
    setUpdateComponent(current => current + 1)
  }

  const now = new Date()
  const timeUntilNextMinute = (60 - now.getSeconds()) * 1000

  // Wait until the next full minute before starting the interval
  setTimeout(() => {
    runEveryMinute() // Run the function immediately
    const intervalId = setInterval(runEveryMinute, 60000) // Then run it every minute thereafter
    // Clear interval after 5 minutes
    const timeoutId = setTimeout(() => {
      clearInterval(intervalId)
    }, 5 * 60 * 1000)
    // Return cleanup function to clear timeout and interval
    return () => {
      clearTimeout(timeoutId)
      clearInterval(intervalId)
    }
  }, timeUntilNextMinute)
}, [updateComponent])




  useEffect ( () => {
    !firstName || !lastName || !phoneNumber || phoneNumber.length < 11 ? setIsFormValid(false) : setIsFormValid(true) 
  }, [firstName, lastName, phoneNumber, updateComponent] )



 console.log(updateComponent)

  // Render a fallback UI if an error has occurred
  if (hasError) {
    return <div>Something went wrong!</div>;
  }





  const submitForm = async (e) => {
    e.preventDefault();    
    
    if (isFormValid === false) setFormErrMsg(`First name, Last name, Phone number are required`)
    
    if (isFormValid === true) {
      setSubmitLoading(true)
      const sessionDocRef = doc( db, `users/${userId}/business/${passedBid}/branches/${passedSelectedBranchId}/sessions`, sessionId );
      const pendingColRef = collection(sessionDocRef, "booking");

      await addDoc(
        pendingColRef,
        {
          uidRef: userId,
          businessid: passedBid,
          branchid: passedSelectedBranchId,
          sessionid: sessionId,
          firstName: firstName,
          lastName: lastName,
          phoneNumber: phoneNumber,
          amountPaid: 0,
          amountDue: 0,
          ticketStatus: 'pending',
          createdAt: serverTimestamp(),
        },
        { merge: false }
      )
      .then((docRef) => {
        setDoc(
          docRef,            
          {
            id: docRef.id,              
          },
          { merge: true },          
        );
        setIsBookingSuccessed(true)
        //navigate(`/app/booking/${userId}/session/${sessionId}/booking-successed/${docRef.id}`);
      })                 
      .catch((error) => console.error("Error adding document: ", error));                     
    }          
  };


  // const closeBookingSuccessedModal = () => {
  //   setIsBookingSuccessed(current => !current)
  // }



  




  return (
    sessionId === null || passedUid === null || passedBid === null || passedSelectedBranchId === null || passedBusinessLogo === null || passedBusinessName === null || passedBusinessDesc === null || passedSelectedBranchName === null || passedSelectedBranchPhoneNumber === null || passedSelectedBranchWhatsapp === null || passedSelectedBranchGovernorate === null || passedSelectedBranchArea === null || passedSelectedBranchFullAddress === null || passedStartDateFull === null || passedEndDateFull === null || passedStartDate === null || passedEndDate === null || passedSessionPeak === null || passedStartDateTimeRow === null || passedEndDateTimeRow === null  ? (
      <div className='max-w-screen-lg mx-auto flex items-center justify-center align-middle h-screen'>
        <div className='flex flex-col justify-center items-center mx-auto'>
          <div className='flex flex-col justify-center items-center'>
            <Lottie animationData={alert_circle} style={{ width: 40, opacity: .5 }} loop={true} /> 
            <strong className='text-red-600 mt-5 mb-1'>{t('Something Went Wrong!')}</strong>
            <p className='text-center'>{t('Could be the appointment link is wrong or it has been ended!')}</p>
          </div>
          <button className='btn bg-accent hover:bg-accent border-none text-primary w-full mt-7' onClick={() => navigate(`/app/booking/${userId}`)}>{t('Go Back')}</button>
        </div>
      </div>
      
    ) : (
      <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="pb-7"
      > 
        <div className="border-b py-7 flex flex-col">
          <div className='flex flex-row justify-between items-center'>            
            <div className='basis-3/12'>
              <button className='w-10 h-8 flex justify-center items-center  rounded-md cursor-pointer' onClick={() => navigate(-1)}>
                <AiOutlineArrowLeft fontSize={20} className={"text-primary rtl:rotate-180"} /> 
              </button>
            </div> 
            {
              passedBusinessLogo && passedBusinessName ? (
                <LazyLoadImage effect="blur" className="basis-1/2 mask mask-squircle w-32 h-32 object-cover" src={passedBusinessLogo} alt={passedBusinessName && passedBusinessName} />
              ) : (
                <Lottie animationData={default_business} style={{ height: 140 }} className="basis-1/2" loop={true} /> 
              )
            }
            <div className='basis-3/12 flex justify-end'>
              <ChangeLangDir classNameProp="w-20 h-8 flex justify-center items-center  rounded-md cursor-pointer text-primary " />
            </div>
          </div>
          <div className='flex flex-col justify-center items-center'>             
            <h1 className='text-center text-lg font-bold pt-3'>{passedBusinessName && passedBusinessName}</h1>
            <p className='text-center text-neutral '>{passedBusinessDesc && passedBusinessDesc}</p>            
            <div className='flex justify-center items-center mt-2'>
              <span className='rounded-md bg-thirdparty font-bold text-xs px-4 h-8 flex items-center'>{passedSelectedBranchName && passedSelectedBranchName}</span>
              <button onClick={() => navigate(-1)} className='px-3 h-8 flex items-center ltr:ml-1 rtl:mr-1'>
                {/* <IoReload fontSize={18} className={"text-primary rtl:rotate-180"} /> */}
                <span className='text-primary font-bold text-xs uppercase'>{t('Change Branch')}</span>
              </button>
            </div>
          </div>          
        </div>       
        <div className='border-b pb-7 mt-7 mb-4 flex flex-col justify-center items-center'>
            
            {
              sessionName !== passedStartDateFull ? <SessionName /> : null
            }
            <span className='text-3xl font-medium '>{passedStartDateFull && passedStartDateFull}</span>
            <p className='pt-1'>{passedStartDate && passedStartDate} {t('to')} {passedEndDate && passedEndDate}</p>

            {nowTimeRow > passedEndDateTimeRow ? null : (
              passedAvgTime !== null && passedRemaining !== null && avgTime !== 0 ? (                              
                peak === 0 || remaining < peak ? <TicketStatus/> : null                
              ) : null
            )}

        </div>
        {
          
          nowTimeRow > passedEndDateTimeRow ? (
            <SessionEndedComp />
          ) : (
            peak === 0 || remaining < peak ? (
              <>
                <div className='mt-7'>
                  <form onSubmit={submitForm}>
                    <strong className='text-neutral/50 uppercase text-2xs block'>{t('Please provide your information')}</strong>
                    <div className="flex w-full mt-4">
                      <Input
                        type="text"
                        id='firstName'
                        className={firstName.length >= 3 ? "bg-teal-600/10" : null}
                        color="blue"
                        size="lg"
                        label={t('First name')}
                        icon={
                          <CgRename fontSize={20} className={"text-neutral/50"} />
                        }
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}                    
                        onBlur={(e) => {
                          setFirstNameErrMsg(t('First name is required'))
                          if (firstName.length <= 0) { 
                            e.target.classList.add("bg-red-600/10");
                            e.target.classList.remove("bg-teal-600/10")
                          } else {
                            e.target.classList.add("bg-teal-600/10"); 
                            e.target.classList.remove("bg-red-600/10")
                          }
                        }}                                          
                      required />
                    </div>
                    {!firstName && !isFormValid ? (<p className="text-sm text-red-400">{firstNameErrMsg}</p>) : null}
                    <div className="flex w-full mt-3">
                      <Input
                        type="text"
                        id='lastName'
                        className={lastName.length >= 3 ? "bg-teal-600/10" : null}
                        color="blue"
                        size="lg"
                        label={t('Last name')}
                        icon={
                          <CgRename fontSize={20} className={"text-neutral/50"} />
                        }
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}    
                        onBlur={(e) => {
                          setLastNameErrMsg(t('Last name is required'))
                          if (lastName.length <= 0) { 
                            e.target.classList.add("bg-red-600/10");
                            e.target.classList.remove("bg-teal-600/10")
                          } else {
                            e.target.classList.add("bg-teal-600/10"); 
                            e.target.classList.remove("bg-red-600/10")
                          }
                        }}              
                      required />
                    </div>
                    {!lastName && !isFormValid ? (<p className="text-sm text-red-400">{lastNameErrMsg}</p>) : null}
                    <div className="flex w-full mt-3">
                      <Input
                        type="number"
                        id='number'
                        className={phoneNumber.length === 11 ? "bg-teal-600/10" : null}
                        color="blue"
                        size="lg"
                        label={t('Phone number / Whatsapp')}
                        icon={
                          <TbDeviceMobile
                            fontSize={20}
                            className={"text-neutral/50"}
                          />
                        }
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}      
                        
                        onBlur={(e) => {
                          setPhoneErrMsg(t('Phone number / Whatsapp is required'))
                          if (phoneNumber.length !== 11) { 
                            e.target.classList.add("bg-red-600/10");
                            e.target.classList.remove("bg-teal-600/10")
                          } else {
                            e.target.classList.add("bg-teal-600/10"); 
                            e.target.classList.remove("bg-red-600/10")
                          }
                        }}          
                      required />
                    </div>
                    {!phoneNumber && !isFormValid ? (<p className="text-sm text-red-400">{phoneErrMsg}</p>) : null}
                    <div className='w-full flex justify-start items-center mt-5'>
                      <FiAlertCircle fontSize={40} className={"ltr:mr-2 rtl:ml-2"} />
                      <span className='font-semibold'>
                        {t('Tracking link will sent to your Whatsapp, With the final estimated entery time!')}
                      </span>
                    </div>

                    {
                      isFormValid === false && formErrMsg !== "" ? (
                        <div className="alert alert-warning shadow-lg mt-5">
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                            <span>{formErrMsg}</span>
                          </div>
                        </div>
                      ) : null
                    }

                    <div className="mt-7">
                      {/* <input type='submit' value='Create Session' className="btn btn-primary w-full text-white" /> */}
                      <button
                        type="submit"                  
                        className={`              
                          rounded-md 
                          font-bold 
                          uppercase 
                          text-xs 
                          w-full 
                          flex 
                          justify-center 
                          items-center 
                          py-5 
                          text-white                                                         
                          ${submitLoading === true ? 'bg-primary/50 cursor-wait ':'bg-primary hover:text-white focus:text-white hover:bg-primary focus:bg-primary cursor-pointer '}
                        `}
                        id="submit_adding_ticket"
                        //whileTap={{ scale: 0.9 }}
                        disabled={
                          submitLoading
                        }
                      >
                        {submitLoading ? (<Lottie animationData={LoadingAnimation} style={{ width: 19, height: 19 }} loop={true} />) : t('Book Now!')}                  
                      </button>              
                    </div>

                  </form>

                </div>
                {
                  isBookingSuccessed === true ? (
                    <>
                      {/* <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='fixed inset-0 z-40 w-screen h-screen bg-white/30 backdrop-blur-md cursor-pointer' onClick={(closeBookingSuccessedModal)}></motion.div>                           */}
                      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='fixed inset-0 z-40 w-screen h-screen bg-white/30 backdrop-blur-md'></motion.div>                          
                      <motion.div 
                        className={`
                            fixed 
                            bottom-0 
                            left-0 
                            right-0 
                            container                     
                            xs:w-full 
                            sm:w-2/6 
                            3xl:w-2/6                     
                            backdrop-blur-md 
                            bg-white 
                            rounded-2xl
                            overflow-scroll
                            p-4
                            grow
                            
                            ${isBookingSuccessed && 'z-50' }
                        `}   

                        initial={{ y: "100%" }}
                        animate={{ y: 0 }}
                        exit={{ y: "100%" }}                             
                        transition={{
                          delay: .2
                        }}            
                        style={{ boxShadow: '0px -10px 20px 0px rgba(0,0,0,0.10)', maxHeight: '95%' }}
                        >
                          <div className='h-full'>
                            <div className='flex flex-col justify-center items-center border-b py-7'>
                              <Lottie animationData={success} style={{  }} loop={true} /> 
                              <strong className='font-bold text-2xl text-teal-400 pt-4'>{t('Successfully Booked!')}</strong>
                              <p className='text-center pt-2 text-teal-400'>{t('Well send you the queue tracking link when a moderator approve your booking.')}</p>
                            </div>
                            <div className='mt-7 mb-4'>
                              <strong className="text-neutral/50 uppercase text-2xs block pb-4">{t('Booking Details')}</strong>
                              <div className='flex flex-col'>                        
                                {
                                  passedStartDateFull && passedStartDate ? (
                                    <div>
                                      <div className="stats w-full shadow-sm border border-gray-300 !flex !flex-col">  
                                        <div className="stat">
                                          <div className="stat-title uppercase text-sm">{t('Appointment starts at')}</div>
                                          <div className="stat-value font-medium text-xl pt-1">{`${passedStartDateFull}`}<small> - {passedStartDate}</small></div>                                                
                                        </div> 
                                        {nowTimeRow > passedEndDateTimeRow ? null : (
                                          passedAvgTime !== null && passedRemaining !== null && avgTime !== 0 ? (                              
                                            peak === 0 || remaining < peak ? <div className='-mt-5'><TicketStatus /></div> : null                
                                          ) : null
                                        )}
                                      </div>
                                    </div>
                                  ) : null
                                }  
                                {
                                  passedSelectedBranchArea && passedSelectedBranchGovernorate && passedSelectedBranchFullAddress ? (
                                    <div className="mt-3 stats w-full shadow-sm border border-gray-300">  
                                    <div className="stat">
                                      <div className="stat-title uppercase text-sm">{t('Branch Address')}</div>
                                      <div className="stat-value font-medium text-xl whitespace-pre-wrap pt-1">{`${passedSelectedBranchFullAddress}, ${passedSelectedBranchArea}, ${passedSelectedBranchGovernorate}`}</div>                                                
                                    </div> 
                                    </div>
                                  ) : null
                                }                                                                                   
                              </div>
                            </div>
                            <div className='mt-7 flex justify-between items-start'>                                            
                              <div className="flex flex-row justify-center items-center gap-3">
                                <motion.a
                                  href={`https://wa.me/+2${passedSelectedBranchWhatsapp}`}
                                  target="_blank"
                                  className="flex flex-row justify-center items-center rounded-full p-5 bg-teal-400 border-white text-primary "
                                  whileTap={{ scale: 0.9 }}>
                                  <BsWhatsapp fontSize={20} className={"text-white"} />              
                                </motion.a>
                                
                                <motion.a
                                  className="flex flex-row justify-center items-center rounded-full p-5 bg-orange-700 border-white text-primary "
                                  whileTap={{ scale: 0.9 }}
                                  href={`tel:${passedSelectedBranchPhoneNumber}`}
                                >                        
                                  <BsTelephoneFill fontSize={20} className={"text-white"} />                           
                                </motion.a>                      
                              </div>
                              <button className='btn btn-link border border-primary rounded-md bg-primary/5 hover:border hover:border-primary hover:bg-primary hover:text-white hover:no-underline	' onClick={() => navigate(-1)}>                        
                                {t('Book another')}
                              </button>
                            </div>
                          </div>
                      </motion.div>
                    </>
                  ) : null
                } 
              </>
            ) : (
              <div className='mt-7 flex flex-col justify-center items-center'>
                <Lottie animationData={queueImg} style={{ height: 150 }} loop={true} />
                <h1 className="text-2xl font-bold text-center mt-4 text-red-600">{t('Queue is full!')}</h1>
                <p className='text-center text-neutral'>{t('Contact Us')}</p>
                <div className="flex flex-row justify-center items-center gap-3 mt-4">
                  <motion.a
                    href={`https://wa.me/+2${passedSelectedBranchWhatsapp}`}
                    target="_blank"
                    className="flex flex-row justify-center items-center rounded-full p-5 bg-teal-400 border-white text-primary "
                    whileTap={{ scale: 0.9 }}>
                    <BsWhatsapp fontSize={20} className={"text-white"} />              
                  </motion.a>
                  
                  <motion.a
                    className="flex flex-row justify-center items-center rounded-full p-5 bg-orange-700 border-white text-primary "
                    whileTap={{ scale: 0.9 }}
                    href={`tel:${passedSelectedBranchPhoneNumber}`}
                  >                        
                    <BsTelephoneFill fontSize={20} className={"text-white"} />                           
                  </motion.a>                      
                </div>
              </div>
            )
          )
        }
        {
          
        }                       
    </motion.div>
    )    
  );
};

// Define an Error Boundary component to catch errors in the BookSession component
class BookSessionErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // Set the hasError state variable to true if an error occurs
      this.setState({ hasError: true });
      return <div>Something went wrong!</div>;
    }

    return this.props.children;
  }
}

// Export the BookSession component wrapped in the Error Boundary component
export default function BookSessionWithErrorBoundary(props) {
  return (
    <BookSessionErrorBoundary>
      <BookSession {...props} />
    </BookSessionErrorBoundary>
  );
}
