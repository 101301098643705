import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Header } from '../../components/app';
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Input } from "@material-tailwind/react";
//import moment from 'moment';
import Dayjs from "dayjs";
import Lottie from "lottie-react";
import { BiPound } from 'react-icons/bi';
import { IoTimerSharp } from 'react-icons/io5';
import { BsFillCreditCard2FrontFill, BsSpeakerFill } from 'react-icons/bs';
import Sheet, { SheetRef } from 'react-modal-sheet';
import { db, auth } from '../../utils/firebase-config';
import { doc, updateDoc, setDoc, addDoc, collection, serverTimestamp, getDocs, query, getDoc, onSnapshot } from "firebase/firestore";
import { GlobalState } from '../../context/StateContext';
//import GetCurrentBranch from '../../helper/GetCurrentBranch'
import NewGetCurrentBranch from '../../helper/NewGetCurrentBranch';
import LoadingAnimation from '../../assets/jsons/lottie/loading.json'

const EditSession = (props) => {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { passedSessionId, passedStartDate, passedEndDate, passedDepositAmount, passedFeesAmount, passedSessionName, passedQueuePeak, passedAvgTime, passedIsSessionActive } = location.state || {};
     
    const user = auth.currentUser;
    const uidRef = GlobalState()?.userData?.id;
    const businessID = GlobalState()?.businessData?.id;
    const currentSelectedBranchID = NewGetCurrentBranch();
    // const retrevedCurrentSelectedBranchID = GetCurrentBranch();
    // const [currentSelectedBranchID, setCurrentSelectedBranchID] = useState()    
    
    // retrevedCurrentSelectedBranchID.then(function(currentSelectedBranchIDResult) {    
    //   try {
    //     setCurrentSelectedBranchID(currentSelectedBranchIDResult)
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }) 

    const sessionId = passedSessionId;
    const sessionsCollectionRef = `users/${uidRef}/business/${businessID}/branches/${currentSelectedBranchID}/sessions`;

    const [startDate, setStartDate] = useState(Dayjs(passedStartDate.seconds * 1000).toDate());
    const [endDate, setEndDate] = useState(Dayjs(passedEndDate.seconds * 1000).toDate());    
    const [ticketKind, setTicketKind] = useState('free');
    const [depositAmount, setDepositAmount] = useState(passedDepositAmount);
    const [feesAmount, setFeesAmount] = useState(passedFeesAmount);
    const [sessionName, setSessionName] = useState(passedSessionName);
    const [queuePeak, setQueuePeak] = useState(passedQueuePeak);
    const [avgTime, setAvgTime] = useState(passedAvgTime);
    const [isActive, setIsActive] = useState(passedIsSessionActive);
    const [isPolicyOpened, setIsPolicyOpened] = useState(false);          
    const sheetRef = useRef();

    const [submitLoading, setSubmitLoading] = useState(false)


    const openPolicyHandler = () => {
      setIsPolicyOpened( current => !current )
    }
    
    // useMemo ( () => {
    //   setSessionName(Dayjs(startDate).format('ddd, DD MMM YYYY'))
    // }, [startDate] )
  

    // const sessionNameHandler = (e) => {
    //   setSessionName( e.target.value )      
    // }   

    // useEffect( () => {
    //   const sessionNameUnsubscribe = setSessionName( Dayjs(startDate).format('ddd, DD MMM YYYY') )      
    //   return ( () => sessionNameUnsubscribe )
    // }, [startDate] )





    const submitForm = async (e) => {
      e.preventDefault();

      if (user ) {              
        setSubmitLoading(current => true)
        const thisSessionDocRef = doc(db, sessionsCollectionRef, sessionId);        
        await updateDoc(thisSessionDocRef, {          
          startDate: startDate,
          endDate: endDate,
          ticketKind: ticketKind,
          depositAmount: Number(depositAmount),
          feesAmount: Number(feesAmount),
          sessionName: sessionName,
          queuePeak: Number(queuePeak),            
          avgTime: Number(avgTime),
          isActive: isActive,                  
          lastEditdBy: user.uid,
          lastEditAt: serverTimestamp(),                  
        });

        navigate('/app/session/watcher/' + sessionId);
        
      }
    }    

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Header title={t('Edit Session')} />
        <div className="xs:mt-10 md:mt-10">
          <form onSubmit={submitForm}>
            <div className="mb-7">
              <strong className="text-neutral/50 uppercase text-2xs pb-3 block">
                {t('Session starts at')}
              </strong>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                showTimeSelect
                dateFormat="ccc, d MMMM yyyy - h:mm aa"
                className="input input-bordered input-md input-primary text-center w-full text-primary"
              />
            </div>
            <div className="mb-7">
              <strong className="text-neutral/50 uppercase text-2xs pb-3 block">
                {t('Session ends at')}
              </strong>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                showTimeSelect
                dateFormat="ccc, d MMMM yyyy - h:mm aa"
                className="input input-bordered input-md input-primary text-center w-full text-primary"
              />
            </div>
            <div className="mb-7">
              <div className="flex justify-between justify-items-center mb-1">
                <div>
                  <strong className="text-neutral/50 uppercase text-2xs">
                    {t('Ticket configration')}
                  </strong>
                </div>
                <div>
                  <motion.div
                    whileTap={{ scale: 0.9 }}
                    className="px-3 py-1 rounded-md bg-thirdparty text-primary text-xs font-bold capitalize cursor-pointer"
                    onClick={openPolicyHandler}
                  >
                    {t('Ticket Policy')}
                  </motion.div>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div className="form-control">
                  <label className="label cursor-pointer">
                    <input
                      type="radio"
                      name="tokenoption"
                      className="radio checked:bg-primary ltr:mr-3 rtl:ml-3"
                      value="free"
                      onClick={() => setTicketKind("free")}
                      defaultChecked
                    />
                    <span className="label-text">{t('Free Ticket')}</span>
                  </label>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div className="form-control">
                  <label className="label cursor-pointer">
                    <input
                      type="radio"
                      name="tokenoption"
                      className="radio checked:bg-primary ltr:mr-3 rtl:ml-3"
                      value="deposit"
                      onClick={() => setTicketKind("deposit")}
                      disabled
                    />
                    <span className="label-text">{t('Deposit')}</span>
                  </label>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <div className="form-control">
                  <label className="label cursor-pointer">
                    <input
                      type="radio"
                      name="tokenoption"
                      className="radio checked:bg-primary ltr:mr-3 rtl:ml-3"
                      value="full-fees"
                      onClick={() => setTicketKind("full-fees")}
                      disabled
                    />
                    <span className="label-text">{t('Full Charge Fees')}</span>
                  </label>
                </div>
              </div>              
              <div className="flex w-full mt-3">
                <Input
                  type="number"
                  className=""
                  color="blue"
                  size="lg"
                  label={t('Fees amount')}
                  icon={<BiPound fontSize={20} className={"text-neutral/50"} />}
                  value={feesAmount}
                  onChange={(e) => setFeesAmount(e.target.value)}
                />
              </div>
              <div className="flex w-full mt-5">
                <Input
                  type="number"
                  className=""
                  color="blue"
                  size="lg"
                  label={t('Deposit amount')}
                  icon={<BiPound fontSize={20} className={"text-neutral/50"} />}
                  value={depositAmount}
                  onChange={(e) => setDepositAmount(e.target.value)}
                  disabled={true}
                />
              </div>
            </div>
            <div className="mb-7">
              <strong className="text-neutral/50 uppercase text-2xs block">
                {t('Session options')}
              </strong>
              <div className="flex w-full mt-4">
                <Input
                  type="text"
                  className=""
                  color="blue"
                  size="lg"
                  label={t('Session name')}
                  //value={moment(startDate).format('ddd MMM/DD/YYYY')}
                  value={sessionName}
                  onChange={(e) => setSessionName(e.target.value)}
                  // onChange={sessionNameHandler}
                  //onChange={(sessionName: string): void => setSessionName(sessionName)}
                  icon={
                    <BsFillCreditCard2FrontFill
                      fontSize={20}
                      className={"text-neutral/50"}
                    />
                  }
                />
              </div>
              <div className="flex w-full mt-3">
                <Input
                  type="number"
                  className=""
                  color="blue"
                  size="lg"
                  label={t('Queue Peak')}
                  icon={
                    <BsSpeakerFill
                      fontSize={20}
                      className={"text-neutral/50"}
                    />
                  }
                  value={queuePeak}
                  onChange={(e) => setQueuePeak(e.target.value)}
                />
              </div>
              <div className="flex flex-col w-full mt-3">
              <div className='flex flex-row items-center justify-between'>
                  <Input
                    type="number"
                    className=""
                    value={avgTime}
                    onChange={(e) => setAvgTime(e.target.value)}
                    color="blue"
                    size="lg"
                    label={t('Estimate the time for each entrance')}                    
                  />
                  <div className='flex flex-row items-center w-30 px-2 border rounded-md h-11 border-black/25 ltr:ml-1 rtl:mr-1'>
                    <IoTimerSharp
                        fontSize={20}
                        className={"text-neutral/50"}
                      />
                      <strong>{t('Min')}</strong>
                  </div>
                </div>
                <div className="flex flex-row justify-start items-stretch mt-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    className="stroke-info flex-shrink-0 w-4 h-4 mr-1 relative"
                    style={{ top: 2 }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <span className="text-primary/80 text-sm">
                    {t('How long will you stay with each person? (In minutes)')}
                  </span>
                </div>
                <div className="flex flex-row mt-3">
                  <label className="cursor-pointer label">
                    <input type="checkbox" className="toggle toggle-primary" onChange={(e) => setIsActive(prevCheck => !prevCheck)} defaultChecked={isActive === true ? true : false} />
                    <span className="label-text ltr:ml-1 rtl:mr-1">{isActive ? t('Active') : t('Deactive')}</span>                       
                  </label>
                </div>
              </div>
            </div>
            <div className="mb-7">
              {/* <input type='submit' value='Create Session' className="btn btn-primary w-full text-white" /> */}
              <button
                type="submit"                
                className={`
                rounded-md 
                font-bold 
                uppercase 
                text-xs 
                w-full 
                flex 
                justify-center 
                items-center 
                py-5 
                text-white                         
                ${submitLoading === true ? 'bg-primary/50 cursor-wait ':'bg-primary hover:text-white focus:text-white hover:bg-primary focus:bg-primary cursor-pointer '}
                `}
                disabled={
                  submitLoading
                }
                //whileTap={{ scale: 0.9 }}
              >
                {submitLoading ? (<Lottie animationData={LoadingAnimation} style={{ width: 19, height: 19 }} loop={true} />) : t('Save Changes')}                  
              </button>              
            </div>
            <Sheet
              isOpen={isPolicyOpened}
              onClose={() => setIsPolicyOpened(false)}
              ref={sheetRef}
              snapPoints={[600, 400, 100, 0]}
              initialSnap={1}
              // onSnap={(snapIndex) =>
              //   console.log("> Current snap point index:", snapIndex)
              // }
            >
              <Sheet.Container>
                <Sheet.Header />
                <Sheet.Content>
                  <div className="p-5">
                    <strong className="text-neutral/50 uppercase text-2xs block">
                      {t('Free Ticket Policy')}
                    </strong>
                    <div className="pt-3">
                      <ul className="steps steps-vertical">
                        <li className="step ltr:!text-left rtl:!text-right" data-content="●">
                          {t('Any Ticket can join.')}
                        </li>
                        <li className="step ltr:!text-left rtl:!text-right" data-content="●">
                          {t('Tickets are generated by the owner or adminstrators.')}
                        </li>
                        <li className="step ltr:!text-left rtl:!text-right" data-content="●">
                          {t('No penalty charge for none show tickets.')}
                        </li>
                        <li className="step ltr:!text-left rtl:!text-right" data-content="●">
                          {t('You can prevent same none show ticket to not use this service in the future.')}
                        </li>
                      </ul>
                    </div>
                  </div>
                </Sheet.Content>
              </Sheet.Container>
              <Sheet.Backdrop />
            </Sheet>
          </form>
        </div>
      </motion.div>
    );
}

export default EditSession