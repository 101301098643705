import React, { useState } from 'react';
import { Header } from '../../components/app';
import { motion } from "framer-motion";
import { Input, Select, Option } from "@material-tailwind/react";
import { TbDeviceMobile } from 'react-icons/tb';
import { BsPersonBadge } from 'react-icons/bs';

const EditMember = () => {


    const [isActive, setIsActive] = useState(false);

    const isActiveHandler = () => {
        setIsActive( current => !current )
    }
   

    const submitForm = (e) => {
      e.preventDefault();               
    }
    


    


    return (
        <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            //animate={{ opacity: 1, transition: { duration: 1 } }}
            exit={{ opacity: 0 }}               
            >                
                
                <Header title ='Edit Member' />
                <div className='upper_container xs:mt-10 md:mt-10'>                    
                    <div className='token_form mt-10'>
                        <form onSubmit={submitForm}>
                            <div className='mb-7'>
                                <strong className='text-neutral/50 uppercase text-2xs block'>Member Details</strong>
                                <div className="flex w-full mt-4">
                                    <Input type='text' className='' color="blue" size="lg" label="First name" value='Mohamed' />
                                </div>
                                <div className="flex w-full mt-3">
                                    <Input type='text' className='' color="blue" size="lg" label="Last name" value='Gaber' />
                                </div>
                                <div className="flex w-full mt-3">
                                    <Input type='number' className='' color="blue" size="lg" label="Phone number" value='01228736650' icon={<TbDeviceMobile fontSize={20} className={'text-neutral/50'} />} />
                                </div>
                                <div className="flex flex-row justify-center items-center gap-3 w-full mt-3 border border-grey-400 rounded-md p-3">
                                    <div>
                                        <img className="mask mask-squircle w-12 h-12 object-cover" src='http://www.thecairoscene.online/Content/Admin/Uploads/Articles/ArticleImages/70a4bfcf-cb02-4720-acdb-5fa56557ef29.jpg' alt='' />                    
                                    </div>
                                    <Input type='file' className='block w-full text-sm text-gray-500 file:mr-4 file:py-0 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100' color="blue" size="lg" label="Image" icon={<BsPersonBadge fontSize={20} className={'text-neutral/50'} />} />
                                </div>
                                <div className="flex w-full mt-3">
                                    <Select label="Role Type" value='moderator'>                                        
                                        <Option value="admin">Admin</Option>                                        
                                        <Option value="moderator">Moderator</Option>                                        
                                    </Select>
                                </div>
                                <div className="form-control mt-3">
                                    <label className="label cursor-pointer flex flex-row justify-start gap-2">                                        
                                        <input type="checkbox" className="toggle toggle-primary" 
                                        defaultChecked={isActive}
                                        onChange={isActiveHandler}
                                        />
                                        <span className="label-text">{isActive ? 'Active' : 'Inactive'}</span>
                                    </label>
                                </div>
                                <div className='mt-7'>                                    
                                    <motion.input 
                                    type='submit' 
                                    value='Save' 
                                    className="              
                                    rounded-md 
                                    font-bold 
                                    uppercase 
                                    text-xs 
                                    w-full 
                                    py-5
                                    text-white 
                                    hover:text-white 
                                    focus:text-white 
                                    bg-primary 
                                    hover:bg-primary 
                                    focus:bg-primary      
                                    cursor-pointer                   
                                    "
                                    whileTap={{ scale: 0.9 }}                                    
                                    />
                                </div> 
                            </div> 
                            
                        </form>
                    </div>
                </div>
        </motion.div>
    )
}

export default EditMember