import React, { useState, useEffect } from 'react';
import { db, auth } from '../../utils/firebase-config';
import { useTranslation } from 'react-i18next';
import { onAuthStateChanged } from "firebase/auth";
import { doc, setDoc, getDoc, serverTimestamp, onSnapshot } from "firebase/firestore"; 
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { HeaderOfUnAuth } from '../../components/app';
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Input } from "@material-tailwind/react";
import quecks_logo from '../../assets/images/quecks_logo.png';
import login_icon from '../../assets/images/login_icon.png';
import otp_icon from '../../assets/images/otp_icon.png';
import egypt_flag from '../../assets/images/egypt_flag.png';





const Login = (props) => {
  
  const {t} = useTranslation();

  // onAuthStateChanged(auth, (user) => {
  //     //user && navigate('/dashboard')    
      
  //     if (user) {
  //         const user = auth.currentUser
          
  //     }
  // });

  
  


  const [isToVerify, setIsToVerify] = useState(false);  
  const countryCode = '+20';
  const phoneNumberLength = 13
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberErrMsg, setPhoneNumberErrMsg] = useState('');
  const [OTP, setOTP] = useState('');
  const [OTPErrMsg, setOTPErrMsg] = useState('');
  const navigate = useNavigate();




    
  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.        
      }
    }, auth);
  }

  const phoneNumberOnChangeHandler = (e) => {
    let numberIs = e.target.value;
    if (numberIs.startsWith("0")) {
      numberIs = numberIs.substr(1);
    }
    setPhoneNumber(countryCode + numberIs);
  }

  const requestOTPHandler = (e) => {    
    e.preventDefault();     
    if(phoneNumber.length === phoneNumberLength ) {
      setIsToVerify( isToVerify => true );         
      generateRecaptcha();
      const appVerifier = window.recaptchaVerifier;      
      signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        // ...
      }).catch((error) => {
        console.log(error) 
        // Error; SMS not sent
        // ...
      });        
    } else {
      setPhoneNumberErrMsg(t('Phone number lenth is not precise!'));
    }    
  }


  const verifyOTPHandler = (e) => {    
    e.preventDefault();        
    const otp = e.target.value;
    setOTP(otp);
    if(otp.length === 6 ) {      
      const confirmationResult = window.confirmationResult;
      confirmationResult.confirm(otp).then((result) => {
        // User signed in successfully.
        const user = result.user;

        const getUserDetails = async () => {
          const docRef = doc(db, "users", user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const userRef = docSnap.data();
            if (userRef?.isCompleted === false && userRef?.registerStep === 0) {
              navigate('/app/create-business-owner');
            }  
            if (userRef?.isCompleted === false && userRef?.registerStep === 1) {
              navigate('/app/create-business-owner');
            }   
            if (userRef?.isCompleted === false && userRef?.registerStep === 2) {
              navigate('/app/create-business');
            }              
            if (userRef?.registerStep === 3 && userRef?.isCompleted === true && userRef?.type === 'owner') {
              navigate('/app');
            }            
          } else {
            setDoc(doc(db, "users", user.uid), {
              id: user.uid,
              phone: phoneNumber,
              registerStep: 0,
              isCompleted: false,          
              isActive: true,
              type: 'owner',          
              createdAt: serverTimestamp(),
            }).then( () => {
              navigate('/app/create-business-owner');
            })
          }
        }

        getUserDetails()
        
        
      }).catch((error) => {        
        setOTPErrMsg(t('Wrong OTP code, Please try again!'))
        console.log(error) 
      });
    }    
  }

  const changeNumHandler = () => {    
    setIsToVerify( isToVerify => false );     
  }



  return (
    <motion.div 
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    //animate={{ opacity: 1, transition: { duration: 1 } }}
    exit={{ opacity: 0 }}
    
    >
      <HeaderOfUnAuth logo ={quecks_logo} />
      <div className='flex flex-col justify-between items-center xs:mt-10 md:mt-10'>        
        <motion.div className='w-full fixed bottom-0 inset-x-0 mb-8'
        initial={{ opacity: 0 }}        
        animate={{ opacity: 1, transition: { duration: 1 } }}
        exit={{ opacity: 0 }}
        >
          <div className='relative container xs:w-full sm:w-2/6 3xl:w-2/6 px-5'>

            {
              isToVerify ? 
                <>
                  <img src={otp_icon} width='70' className='relative -left-3 mb-5' alt='' />
                  <h1 className='font-black text-4xl'>            
                    {t('Weve sent')} <br />
                    {t('you a verification')} <br />
                    {t('code, Use it!')}
                  </h1>
                </>
              :
                <>
                  <img src={login_icon} width='70' className='relative -left-3 mb-5' alt='' />
                  <h1 className='font-black text-4xl'>
                  {
                    localStorage.getItem("dir") === 'ltr' ? (
                      <>
                        Login,<br />
                        Signup <br />
                        with your <br />
                        phone number
                      </>
                    ):(
                      <>
                        قُم بالدخول <br />
                        أو التسجيل فقط برقم هاتفك المحمول
                      </>
                    )
                  }                                              
                </h1>
                </>                
            }   
            
            <div className="relative mt-7 w-full flex ltr:flex-row rtl:flex-row-reverse justify-between items-stretch">
              <div className='flex ltr:flex-row rtl:flex-row-reverse justify-center items-center bg-thirdparty border border-inputborder rounded-md px-4 py-2 mr-2' style={{ minHeight: 32 }}>
                <img src={egypt_flag} width='30' alt='' />
                <span className='text-neutral/60 text-sm ml-1' style={{ direction: "ltr" }}>+20</span>
              </div>              
              <Input 
                type='text' 
                label={t('Type your phone number')} 
                name='phoneNumber' 
                onChange={phoneNumberOnChangeHandler} 
                className={phoneNumber.length === phoneNumberLength ? 'bg-teal-600/10' : ''} 
                color={phoneNumber.length === phoneNumberLength ? 'teal' : 'blue'} 
                disabled={isToVerify === true} // no need to check for === true/false
              />              
              {
                isToVerify && 
                <button className="absolute z-10 top-3 ltr:right-3 rtl:left-28 text-xs uppercase font-bold text-primary" onClick={changeNumHandler}>{t('Change Number')}</button>
              }
            </div>
            {phoneNumber.length !== phoneNumberLength ? <p className='text-red-400 py-2 text-center font-bold text-xs'>{phoneNumberErrMsg}</p> : null}
            <div className='mt-3'>                                    
                {
                  isToVerify ? 
                  <div className='border-inputborder/60 border-dashed border-t mt-5 pt-5'>
                    <Input type='text' label={t('Enter the code youve got via SMS')} className={OTPErrMsg ? 'bg-red-600/10' : null} name='OTPNumber' id='OTPInput' value={OTP} autoComplete="one-time-code" onChange={verifyOTPHandler}   />                                        
                    {OTPErrMsg && <p className='text-red-400 py-2 text-center font-bold text-xs'>{OTPErrMsg}</p>}
                    <motion.button 
                      className="
                      w-full                     
                      rounded-md 
                      font-bold 
                      uppercase 
                      text-xs 
                      py-5
                      mt-3
                      text-white 
                      hover:text-white 
                      focus:text-white 
                      bg-primary 
                      hover:bg-primary 
                      focus:bg-primary           
                    "                                        
                    whileTap={{ scale: 0.9 }}
                    onClick={verifyOTPHandler}        
                    >
                      {t('Verify Me')}
                    </motion.button>             
                  </div>
                : 
                  <motion.button 
                    className="
                    w-full                     
                    rounded-md 
                    font-bold 
                    uppercase 
                    text-xs 
                    py-5
                    text-white 
                    hover:text-white 
                    focus:text-white 
                    bg-primary 
                    hover:bg-primary 
                    focus:bg-primary           
                  "                                      
                  onClick={requestOTPHandler}        
                  whileTap={{ scale: 0.9 }}                  
                  >
                    {t('Send Verification code')}
                  </motion.button> 
                }         
            </div> 
            <div id='recaptcha-container'></div>
            
          </div>
        </motion.div>
      </div>
    </motion.div>
  )
}

export default Login