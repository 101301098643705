import React, { useEffect, useState, useMemo, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Header, Session } from "../../components/app";
import { motion } from "framer-motion";
import Dayjs from "dayjs";
import { db, auth } from "../../utils/firebase-config";
import { collection, onSnapshot, query, orderBy, doc, limitToLast, limit, where, startAfter } from "firebase/firestore";
import { GlobalState } from "../../context/StateContext";
//import GetCurrentBranch from "../../helper/GetCurrentBranch";
import NewGetCurrentBranch from "../../helper/NewGetCurrentBranch";
import Lottie from "lottie-react";
import { MdOutlineTableRows, MdArrowBack, MdOutlineArrowForward } from 'react-icons/md';
import emptyImg from "../../assets/jsons/lottie/empty.json";

const EndedSessions = () => {
  const { t } = useTranslation();
  const today = Dayjs();
  const todayRaw = new Date();
  const todayRawChange = new Date();
  //console.log('o', todayRawChange)
  todayRawChange.setTime(todayRaw.getTime() - (1440 * 60 * 1000));
    
  const pageSize = 5;
  const user = auth.currentUser;
  const uidRef = GlobalState()?.userData?.id;
  const businessID = GlobalState()?.businessData?.id;
  const currentSelectedBranchID = NewGetCurrentBranch();
  // const retrevedCurrentSelectedBranchID = GetCurrentBranch();
  // const [currentSelectedBranchID, setCurrentSelectedBranchID] = useState();
  const [sessionData, setSessionData] = useState([]);
  const [lastvisibility, setlastvisibility] = useState({});       
  const [isSessionData, setIsSessionData] = useState(true);  
  const [loading, setLoading] = useState(false);
  const sessionsCollectionRef = `users/${uidRef}/business/${businessID}/branches/${currentSelectedBranchID}/sessions`;
  

  //console.log('n',todayRawChange);

  // retrevedCurrentSelectedBranchID.then(function (currentSelectedBranchIDResult) {
  //   if(!currentSelectedBranchIDResult) return;
  //   try {
  //     setCurrentSelectedBranchID(currentSelectedBranchIDResult);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // });

  useMemo(() => {
    if (user && localStorage.getItem(`selectedBranch-${user?.uid}`) !== null && localStorage.getItem(`selectedBranch-${user?.uid}`) !== undefined && localStorage.getItem(`selectedBranch-${user?.uid}`) !== '') {       
      setLoading(true);     
      const q = query(collection(db, sessionsCollectionRef), 
        orderBy('endDate', 'desc'),        
        where("endDate", "<", todayRaw),
        limitToLast(pageSize), 
        limit(pageSize)
      );
      const sessionsUnsub = onSnapshot(q, (snapshot) => {          
          setSessionData(snapshot.docs.map(doc => ({...doc.data(), id: doc.id})));
          setlastvisibility(snapshot.docs[snapshot.docs.length-1]);            
          setLoading(false);     
      })
           
      return () => sessionsUnsub();     
    }    
  }, [user, sessionsCollectionRef]);  

  const loadMoreSessions = () => {
      const q = query(collection(db, sessionsCollectionRef), 
      orderBy('endDate', 'desc'),      
      where("endDate", "<", todayRaw),
      startAfter(lastvisibility), 
      limit(pageSize)
      );
      const load = onSnapshot(q, (snapshot) => {
        const allsessionData = sessionData.concat(snapshot.docs.map(doc => ({...doc.data(), id: doc.id})))
        setlastvisibility(snapshot.docs[snapshot.docs.length-1]);
        setSessionData(allsessionData);
        // setsessionData(snapshot.docs.map(doc => ({...doc.data(), id: doc.id})));
        console.log(lastvisibility);
        //console.log(sessionData);
      })
      return () => load();   
    }

  useEffect ( () => {
    const checkSessionData = () => {
      if (sessionData.length !== 0 ) {
        setIsSessionData(current => true)
      } else {
        setIsSessionData(current => false)
      }
    }
    checkSessionData()
  }, [sessionData, sessionData.length, loading] )

  useLayoutEffect(() => {
    const unsubscribe = () => {
      document.title = "Sessions";
    };
    return unsubscribe;
  }, []);
  
  //console.log('sessions', sessionData)
  // console.log('sessions', sessionData?.length)
  // console.log('sessionsLimit', sessionsLimit)
  

  return (
    uidRef === undefined || businessID === undefined || currentSelectedBranchID === null || loading === true ? (
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='fixed w-screen h-screen inset-0 z-50 flex justify-center items-center'><div className='spinner'></div></motion.div>
    ) : (
      <>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Header 
          title={t('Ended Sessions')} 
          extraNavAction={
              <Link to={"/app/sessions"}>
                <MdOutlineTableRows
                  fontSize={20}
                  className={"text-primary cursor-pointer"}
                />
              </Link>
            }
          />
          {loading === true && (          
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='fixed w-screen h-screen inset-0 z-50 flex justify-center items-center'><div className='spinner'></div></motion.div>          
          )}
          <div className="xs:mt-10 md:mt-10">
            {localStorage.getItem(`selectedBranch-${user?.uid}`) === null && (
              <div className="flex flex-col justify-center items-center h-[calc(100vh-200px)]">
                <Lottie animationData={emptyImg} loop={true} />
                <h1 className="text-2xl font-bold text-center">
                  {t('No branch selected!')}
                </h1>
              </div>
            )}
            {localStorage.getItem(`selectedBranch-${user?.uid}`) !== null && localStorage.getItem(`selectedBranch-${user?.uid}`) !== undefined && localStorage.getItem(`selectedBranch-${user?.uid}`) !== '' && (
              <>
                <Link to={"/app/sessions"} className="flex flex-row justify-start items-center cursor-pointer mb-6">                                                
                  {
                    localStorage.getItem("dir") === 'ltr' ? (
                      <MdArrowBack fontSize={30} className={"text-primary"}/>
                    ) : (
                      <MdOutlineArrowForward fontSize={30} className={"text-primary"}/>
                    )
                  }
                  <strong className="mx-2 text-primary uppercase">{t('Back to sessions')}</strong>
                </Link>
                <div className="flex flex-col justify-center items-center content-center pb-28">
                  {sessionData?.length >= 1 ? (
                    sessionData.map((session) => {
                      //Dayjs(session.startDate.seconds * 1000).format('ddd, DD MMM YYYY - h:m A');
                      const sessionStartDay = Dayjs( session.startDate.seconds * 1000 ).format("ddd, DD MMM YYYY");
                      const sessionEndDay = Dayjs( session.endDate.seconds * 1000 ).format("ddd, DD MMM YYYY");
                      const sessionEndDayFull = Dayjs( session.endDate.seconds * 1000 );                  
                      const sessionName = session.sessionName;

                      if (today <= sessionEndDayFull && session.isActive === true) {
                        return (
                          <Link
                            key={session.id}
                            to={`/app/session/watcher/${session.id}`}
                            className="block w-full"
                          >
                            <Session
                              isInactiveClass="opacity-100 border border-primary"
                              sessionName={session.sessionName}
                              dateStartEnd={
                                sessionStartDay === sessionEndDay
                                  ? `${Dayjs(
                                      session.startDate.seconds * 1000
                                    ).format("h:mm A")} ${t('to')} ${Dayjs(
                                      session.endDate.seconds * 1000
                                    ).format("h:mm A")}`
                                  : `${Dayjs(
                                      session.startDate.seconds * 1000
                                    ).format(
                                      "ddd, DD MMM YYYY - h:mm A"
                                    )} ${t('to')} ${Dayjs(
                                      session.endDate.seconds * 1000
                                    ).format("ddd, DD MMM YYYY - h:mm A")}`
                              }
                              sessionStartDateFull={
                                sessionName !== sessionStartDay
                                  ? sessionStartDay
                                  : null
                              }
                            />
                          </Link>
                        );
                      } else {
                        return (
                          <Link
                            key={session.id}
                            to={`/app/session/watcher/${session.id}`}
                            className="block w-full"
                          >
                            <Session
                              key={session.id}
                              isInactiveClass="opacity-50"
                              sessionName={`${session.sessionName} - ${t("Ended")}`}
                              dateStartEnd={
                                sessionStartDay === sessionEndDay
                                  ? `${Dayjs(session.startDate.seconds * 1000).format(
                                      "h:m A"
                                    )} ${t('to')} ${Dayjs(
                                      session.endDate.seconds * 1000
                                    ).format("h:m A")}`
                                  : `${Dayjs(session.startDate.seconds * 1000).format(
                                      "ddd, DD MMM YYYY - h:m A"
                                    )} ${t('to')} ${Dayjs(
                                      session.endDate.seconds * 1000
                                    ).format("ddd, DD MMM YYYY - h:mm A")}`
                              }
                              sessionStartDateFull={
                                sessionName !== sessionStartDay
                                  ? sessionStartDay
                                  : null
                              }
                            />
                          </Link>
                        );
                      }
                    })
                  ) : isSessionData === false && loading === false ? (                
                    <div className="flex flex-col justify-center items-center h-[calc(100vh-200px)]">
                      <Lottie animationData={emptyImg} loop={true} />
                      <h1 className="text-2xl font-bold text-center">
                        {t('No ended sessions to display!')}
                      </h1>
                    </div>                
                  ) : (                  
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='fixed w-screen h-screen inset-0 z-50 flex justify-center items-center'><div className='spinner'></div></motion.div>                  
                  )}   
                      
                  {
                    sessionData?.length >= 1 ? (
                      lastvisibility === undefined ? (
                        <p className="mt-4 opacity-40">{t('No more sessions to load!')}</p>
                      ) : (
                        <button onClick={loadMoreSessions} className='btn btn-link mt-1 w-full'>
                          {t('Show More')}                        
                        </button>
                      )
                    ) : null
                  }
                          
                </div>
              </>            
            )}          
          
          </div>
        </motion.div>
      </>
    )
  );
};

export default EndedSessions;
