import React, {useEffect, useState} from 'react';
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';
import { db, auth } from '../../utils/firebase-config.js';
import { onSnapshot, doc, query, collection, orderBy, where, limit, setDoc, addDoc, serverTimestamp} from "firebase/firestore";
import Dayjs from "dayjs";
import { GlobalState } from '../../context/StateContext';
import { Link, useParams, useNavigate } from "react-router-dom";
import { Textarea, Button } from "@material-tailwind/react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lottie from "lottie-react";
import default_business from "../../assets/jsons/lottie/default_business.json";
import TopBattern from "../../assets/svgs/top_pattern.svg";
import BottomBattern from "../../assets/svgs/bottom_pattern.svg";
import { IoLogoWhatsapp } from "react-icons/io";
import { BsTelephoneFill } from "react-icons/bs";
import { TbTicketOff } from "react-icons/tb";
import { GiSandsOfTime } from "react-icons/gi";
import { MdNotificationsActive } from "react-icons/md";
import LoadingAnimation from '../../assets/jsons/lottie/loading.json';
import success from "../../assets/jsons/lottie/success.json";
import ChangeLangDir from '../../helper/ChangeLangDir';
import { format, addMinutes, differenceInMinutes } from 'date-fns';



const TicketTracking = () => {
  const {t} = useTranslation();
    const params = useParams();
    const navigate = useNavigate();
    
    //console.log(params)

    const uidParamsRef = params.uId;
    const businessIdParamsRef = params.businessId;
    const branchIdParamsRef = params.branchId;
    const sessionIdIdParamsRef = params.sessionId;
    const ticketIdParamsRef = params.ticketId;
    
    const [isSessionLoading, setIsSessionloading] = useState(true);
    const [sessionData, setSessionData] = useState({});  
    
    const [isTicketLoading, setTicketloading] = useState(true);
    const [ticketData, setTicketData] = useState({});  

    const [ticketProgress, setTicketProgress] = useState(0)


    const businessName = ticketData?.businessName;
    const businessDesc = ticketData?.businessDesc;
    const businessImg = ticketData?.businessImg;
    
    const [ahedNum, setAhedNum] = useState(null);     
    const avgTimeInMinutes = sessionData?.avgTime;
    const [est, setEsta] = useState(null)

    const [serviceRating, setServiceRating] = useState(5);
    const [assistanteRating, setAssistantRating] = useState(5);
    const [placeRating, setPlaceRating] = useState(5);
    const [commentRating, setCommentRating] = useState("");

    const [submitLoading, setSubmitLoading] = useState(false)
    const[isRatingSuccessed, setIsRatingSuccessed] = useState(false)

    useEffect( () => {
      setEsta(ahedNum * avgTimeInMinutes)
    }, [ahedNum, avgTimeInMinutes] )

  const estHours = Math.floor(est / 60); // get the number of hours
  const estMinutes = est % 60; // get the remaining minutes


  //const outputString = `Your estimated time is ${estHours} Hr and ${estMinutes} min.`;
  //console.log(outputString); // or display it in some other way



    // const currentTime = new Date();
    // const estimatedTimeInMinutes = ahedNum * avgTimeInMinutes;
    // const estimatedTime = addMinutes(currentTime, estimatedTimeInMinutes);
    // const timeLeftInMinutes = differenceInMinutes(estimatedTime, currentTime);
  
    // const formattedTimeLeft = format(new Date().setMinutes(timeLeftInMinutes), "m 'minute(s)'");

    // console.log(timeLeftInMinutes) 
    //console.log(formattedTimeLeft)
    

    useEffect(() => {
      setIsSessionloading(false)
      if (isSessionLoading === false) {
          const sessionUnsub = onSnapshot(doc(db, `users/${uidParamsRef}/business/${businessIdParamsRef}/branches/${branchIdParamsRef}/sessions/${sessionIdIdParamsRef}`), (doc) => {
              //console.log("Current data: ", doc.data());
              setSessionData(doc.data())
          });
          return () => sessionUnsub();
      }        
    }, [uidParamsRef, businessIdParamsRef, branchIdParamsRef, isSessionLoading, sessionIdIdParamsRef]);  

    useEffect(() => {
      setTicketloading(false)
      if (isTicketLoading === false) {
          const ticketUnsub = onSnapshot(doc(db, `users/${uidParamsRef}/business/${businessIdParamsRef}/branches/${branchIdParamsRef}/sessions/${sessionIdIdParamsRef}/queues/${ticketIdParamsRef}`), (doc) => {
              //console.log("Current data: ", doc.data());
              setTicketData(doc.data())
          });
          return () => ticketUnsub();
      }        
    }, [uidParamsRef, businessIdParamsRef, branchIdParamsRef, isTicketLoading, sessionIdIdParamsRef, ticketIdParamsRef]);  

    useEffect( () => {
      const calcAhedNum = ticketData?.ticketNumber - sessionData?.nowTicket;
      setAhedNum( calcAhedNum.toString() )
    }, [ticketData?.ticketNumber, sessionData?.nowTicket] )        

    useEffect ( () => {
      setTicketProgress(100 - (ticketData?.ticketNumber - sessionData?.nowTicket) * 100 / ticketData?.ticketNumber)      
    }, [ticketData?.ticketNumber, sessionData?.nowTicket] )    
   
    console.log(ticketData)

    const submitForm = async (e) => {
      e.preventDefault();    
      setSubmitLoading(true)      
      
      const branchDocRef = doc( db, `users/${uidParamsRef}/business/${businessIdParamsRef}/branches`, branchIdParamsRef );
      const ratingsColRef = collection(branchDocRef, "ratings");

      await addDoc(
        ratingsColRef,
        {          
          branchid: branchIdParamsRef,          
          firstName: ticketData?.firstName,
          lastName: ticketData?.lastName,
          phoneNumber: ticketData?.phoneNumber,
          serviceRating: serviceRating,
          assistanteRating: assistanteRating,
          placeRating: placeRating,
          commentRating: commentRating,
          createdAt: serverTimestamp(),
        },
        { merge: false }
      )
      .then((docRef) => {
        setDoc(
          docRef,            
          {
            id: docRef.id,              
          },
          { merge: true },          
        );
        setIsRatingSuccessed(true)        
      })                 
      .catch((error) => console.error("Error adding document: ", error));   

    }


    return (
      est === null || ahedNum === null || ticketData?.ticketMove === undefined ? (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='fixed w-screen h-screen inset-0 z-50 flex justify-center items-center'><div className='spinner'></div></motion.div>
      ) :
        (
        <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="my-6"
        >                
          <div className='flex flex-col justify-center items-center content-center mb-4'>
            {
              businessImg ? (
                <LazyLoadImage effect="blur" className="basis-1/2 mask mask-squircle w-32 h-32 object-cover mb-3" src={businessImg} alt={businessName && businessName} />
              ) : (
                <Lottie animationData={default_business} style={{ height: 200 }} loop={true} /> 
              )
            } 
            <div className='mt-1 mb-3 text-center'>
              {businessName && <strong className='text-center text-lg font-bold pt-3'>{businessName}</strong>}
              {businessDesc && <p className='text-neutral py-1'>{businessDesc}</p>}
            </div> 
          </div>
          <motion.div 
          className="grow-0 bg-primary flex flex-col justify-center items-center py-8 -mx-4 relative rounded-sm drop-shadow-2xl"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
          transition={{ delay: .5, type: "spring", stiffness: 100 }}
          >
            {
              ticketData?.ticketMove === 'enqueue' && 
              <p className='badge badge-ghost mb-6'>
                { Dayjs(sessionData?.startDate?.seconds * 1000).format("ddd, DD MMM YYYY - h:mm A") }
              </p>
            }            
            <div
            className="absolute -top-1 left-0 right-0 h-1"
            style={{ backgroundImage: `url(${TopBattern})` }}></div>            
            {
              ticketData?.ticketMove === 'dequeue' ? (
                <div className='flex flex-col justify-center items-center my-10'>
                  {/* <TbTicketOff fontSize={100} className={"text-white"} />       */}
                  <strong className='text-white text-4xl text-center'>{t('Your ticket has been expired!')}</strong>
                </div>
              ) : ticketData?.ticketMove === 'enqueue' ? (
                <div className='bg-primary flex flex-col justify-center items-center content-center'>
                  <div 
                  className="radial-progress bg-white text-primary-content border-8 border-white " 
                  style={{"--value":ticketProgress, "--size": "18rem", "--thickness": ".6rem"}}
                  >
                    <div className='-mt-4'>
                      <div className='flex flex-col items-center'>
                        {
                          ahedNum === "0" ? (
                            <strong className='font-bold text-4xl text-neutral'>{t('Youre Next')}</strong>
                          ) : (
                          <>
                            <strong className='font-bold text-7xl text-neutral'>{ahedNum}</strong>
                            <span className='text-neutral/70'>{t('Ahed of you!')}</span>
                          </>
                          )
                        }                        
                      </div>
                      {
                        avgTimeInMinutes > 0 ? (
                          sessionData?.started === true ? (
                            <div className='mt-3 flex flex-col items-center'>
                              <strong className='font-bold text-xl text-neutral'>
                              {
                                estHours > 0 && estMinutes > 0 ? (
                                  <>{estHours} {t("Hr")}<span className='blink px-[2px]'>:</span>{estMinutes} {t('Min')}</>
                                ) : estMinutes <= 0 ? (
                                  <>{estHours} {t('Hr')}</>
                                ) : (
                                  <>{estMinutes} {t('Min')}</>
                                )
                              }                            
                              </strong>                      
                              <span className='text-neutral/70'>{t('Estimated time to enter!')}</span>
                            </div>
                          ):(
                            <div className='mt-3 flex flex-col items-center'>
                            <motion.div
                              animate={{ rotate: 360 }}
                              transition={{ ease: "linear", duration: 2, repeat: Infinity }}
                              >
                                <GiSandsOfTime fontSize={30} className={"text-red-600"} />        
                            </motion.div> 
                              <strong className='text-red-600'>{t('Session hasnt started yet!')}</strong>
                            </div>
                          )
                        ):null
                      }                                        
                    </div>
                  </div>

                  {
                    ahedNum <= 2 &&  ahedNum !== "0" ? (
                      <div className="alert !bg-red-600 mt-5 relative rounded-md">                   
                        <div>
                          <MdNotificationsActive fontSize={22} className={"text-white"} />              
                          <span className='font-bold text-white'>{t('Be ready your turn is almost ahead')}</span>
                        </div>
                      </div> 
                    ) : null
                  }

                  <div className='mt-4 flex flex-row justify-between items-start w-full'>
                  {
                    sessionData?.started === true ? (
                      <div className='flex flex-col items-center'>
                        <strong className='text-4xl font-extrabold text-white'>{sessionData?.nowTicket}</strong>
                        <span className='text-white'>{t('Now Serving')}</span>
                      </div>
                    ) : (
                      <div className='flex flex-col items-center bg-red-600 rounded-md py-2 px-4'>
                        <motion.div
                        animate={{ rotate: 360 }}
                        transition={{ ease: "linear", duration: 2, repeat: Infinity }}
                        >
                          <GiSandsOfTime fontSize={30} className={"text-white"} />        
                        </motion.div>                        
                        <span className='text-white'>{t('Session hasnt started yet!')}</span>
                      </div>
                    )
                  }
                    
                    <div className='flex flex-col items-center'>
                      <strong className='text-4xl font-extrabold text-white'>{ticketData?.ticketNumber}</strong>
                      <span className='font-bold text-white'>{ticketData?.firstName} ({t('Me')})</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='flex flex-col justify-center items-center my-10'>
                  <TbTicketOff fontSize={100} className={"text-white"} />      
                  <strong className='text-white text-4xl text-center'>{t('Invalid ticket info!')}</strong>
                </div>
              )
            }
            {
              ticketData?.ticketMove === 'enqueue' ? (
                <div className="mt-6 p-3 rounded-lg w-4/5 shadow-md bg-yellow-200">  
                  <p className='text-center font-semibold'>{t('Please be on time, If you are late you will have to take a new queue number')}</p>              
                </div>  
              ) : (
                <div className="mx-5">  
                  <Link to={`/app/booking/${uidParamsRef}`} target='_blank' className='btn btn-warning'>{t('Book your next appoitment without waiting')}</Link>
                </div>
              )
            }            
            <div
            className="absolute -bottom-1 left-0 right-0 h-1.5"
            style={{ backgroundImage: `url(${BottomBattern})` }}></div>
          </motion.div>          
          {
            ticketData?.ticketMove === 'dequeue' ? (
              <form onSubmit={submitForm}>
                <div className='bg-blue-50 -m-4 px-6 pt-14 pb-5 mb-9 rounded-lg'>
                  <strong className='text-2xl'>{t('Would you please rate us')}</strong>
                  <p>{t('Help us improve our services')}</p>
                  <div className='mt-7'>
                    <div className='flex justify-between items-center border-b border-black/10 border-dotted mb-3 pb-3'>
                      <strong>{t('The Service')}</strong>       
                      <div>
                        <div className="rating rating-md">
                          <input type="radio" name="rating-service" value={1} className="mask mask-star-2 bg-orange-400" onChange={(e)=>setServiceRating(parseInt(e.target.value))} checked={serviceRating === 1}/>
                          <input type="radio" name="rating-service" value={2} className="mask mask-star-2 bg-orange-400" onChange={(e)=>setServiceRating(parseInt(e.target.value))} checked={serviceRating === 2}/>
                          <input type="radio" name="rating-service" value={3} className="mask mask-star-2 bg-orange-400" onChange={(e)=>setServiceRating(parseInt(e.target.value))} checked={serviceRating === 3}/>
                          <input type="radio" name="rating-service" value={4} className="mask mask-star-2 bg-orange-400" onChange={(e)=>setServiceRating(parseInt(e.target.value))} checked={serviceRating === 4}/>
                          <input type="radio" name="rating-service" value={5} className="mask mask-star-2 bg-orange-400" onChange={(e)=>setServiceRating(parseInt(e.target.value))} checked={serviceRating === 5}/>
                        </div>
                      </div>                  
                    </div>               
                    <div className='flex justify-between items-center border-b border-black/10 border-dotted mb-3 pb-3'>
                      <strong>{t('The Assistant')}</strong>       
                      <div>
                        <div className="rating rating-md">
                          <input type="radio" name="rating-assistant" value={1} className="mask mask-star-2 bg-orange-400" onChange={(e)=>setAssistantRating(parseInt(e.target.value))} checked={assistanteRating === 1}/>
                          <input type="radio" name="rating-assistant" value={2} className="mask mask-star-2 bg-orange-400" onChange={(e)=>setAssistantRating(parseInt(e.target.value))} checked={assistanteRating === 2}/>
                          <input type="radio" name="rating-assistant" value={3} className="mask mask-star-2 bg-orange-400" onChange={(e)=>setAssistantRating(parseInt(e.target.value))} checked={assistanteRating === 3}/>
                          <input type="radio" name="rating-assistant" value={4} className="mask mask-star-2 bg-orange-400" onChange={(e)=>setAssistantRating(parseInt(e.target.value))} checked={assistanteRating === 4}/>
                          <input type="radio" name="rating-assistant" value={5} className="mask mask-star-2 bg-orange-400" onChange={(e)=>setAssistantRating(parseInt(e.target.value))} checked={assistanteRating === 5}/>
                        </div>
                      </div>                  
                    </div> 
                    <div className='flex justify-between items-center mb-6'>
                      <strong>{t('The Place')}</strong>       
                      <div>
                        <div className="rating rating-md">
                          <input type="radio" name="rating-place" value={1} className="mask mask-star-2 bg-orange-400" onChange={(e)=>setPlaceRating(parseInt(e.target.value))} checked={placeRating === 1}/>
                          <input type="radio" name="rating-place" value={2} className="mask mask-star-2 bg-orange-400" onChange={(e)=>setPlaceRating(parseInt(e.target.value))} checked={placeRating === 2}/>
                          <input type="radio" name="rating-place" value={3} className="mask mask-star-2 bg-orange-400" onChange={(e)=>setPlaceRating(parseInt(e.target.value))} checked={placeRating === 3}/>
                          <input type="radio" name="rating-place" value={4} className="mask mask-star-2 bg-orange-400" onChange={(e)=>setPlaceRating(parseInt(e.target.value))} checked={placeRating === 4}/>
                          <input type="radio" name="rating-place" value={5} className="mask mask-star-2 bg-orange-400" onChange={(e)=>setPlaceRating(parseInt(e.target.value))} checked={placeRating === 5}/>
                        </div>
                      </div>                  
                    </div> 
                    <div className="w-full">
                      <Textarea label={t('Additional Comment')} type='text' className='bg-white/60' id='raitingcomment' onChange={(e) => setCommentRating(e.target.value)} value={commentRating}  />                      
                      <button
                        type="submit"                  
                        className={`             
                          btn 
                          border-none
                          rounded-md 
                          font-bold 
                          uppercase 
                          text-xs 
                          w-full 
                          flex 
                          justify-center 
                          items-center 
                          mt-4
                          mb-2
                          text-white                                                         
                          ${submitLoading === true ? 'bg-primary/50 cursor-wait ':'bg-primary hover:text-white focus:text-white hover:bg-primary focus:bg-primary cursor-pointer '}
                        `}                  
                        disabled={
                          submitLoading
                        }
                      >
                        {submitLoading ? (<Lottie animationData={LoadingAnimation} style={{ width: 19, height: 19 }} loop={true} />) : t('Send Review')}                  
                      </button> 
                    </div>              
                  </div>           
                </div>
              </form>
            ) : null
          }
          <div className='flex flex-row-reverse rtl:flex-row justify-between items-center mt-7'>
            <div className='flex flex-row-reverse rtl:flex-row justify-between items-center content-center'>
              <motion.a
              href={`https://wa.me/+2${sessionData?.branchWhatsappRef}`}
              target="_blank"
              className="flex flex-row justify-center items-center rounded-lg mx-2 px-8 py-4 bg-teal-400 "
              whileTap={{ scale: 0.9 }}>
                <IoLogoWhatsapp fontSize={22} className={"text-white"} />              
              </motion.a>
              <motion.a
                href={`tel:${sessionData?.branchPhoneRef}`}
                className="flex flex-row justify-center items-center rounded-lg mx-2 px-8 py-4 bg-orange-700"
                whileTap={{ scale: 0.9 }}>
                <BsTelephoneFill fontSize={20} className={"text-white"} />              
              </motion.a>
            </div>
            <ChangeLangDir 
            classNameProp="btn btn-link px-8 py-4" 
            classNamePropText="font-black"             
            />
          </div>          
          {
            isRatingSuccessed === true ? (
              <>                
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='fixed inset-0 z-40 w-screen h-screen bg-white/30 backdrop-blur-md'></motion.div>                          
                <motion.div 
                  className={`
                      fixed 
                      bottom-0 
                      left-0 
                      right-0 
                      container                     
                      xs:w-full 
                      sm:w-2/6 
                      3xl:w-2/6                     
                      backdrop-blur-md 
                      bg-white 
                      rounded-2xl
                      overflow-scroll
                      p-4
                      grow
                      
                      ${isRatingSuccessed && 'z-50' }
                  `}   

                  initial={{ y: "100%" }}
                  animate={{ y: 0 }}
                  exit={{ y: "100%" }}                             
                  transition={{
                    delay: .2
                  }}            
                  style={{ boxShadow: '0px -10px 20px 0px rgba(0,0,0,0.10)', maxHeight: '95%' }}
                  >
                    <div className='h-full'>
                      <div className='flex flex-col justify-center items-center border-b py-7'>
                        <Lottie animationData={success} style={{  }} loop={true} /> 
                        <strong className='font-bold text-2xl text-teal-400 pt-4'>{t('Successfully Sent!')}</strong>
                        <p className='text-center pt-2 text-teal-400'>{t('Thanks for your review')}</p>
                      </div>  
                      <div className='mt-7 flex justify-between items-start'>                                                                    
                        <button className='btn btn-link border border-primary rounded-md bg-primary/5 hover:border hover:border-primary hover:bg-primary hover:text-white hover:no-underline w-full' onClick={() => navigate(`/app/booking/${uidParamsRef}`)}>                        
                          {t('Book your next appoitment without waiting')}
                        </button>
                      </div>                    
                    </div>
                </motion.div>
              </>
            ) : null
          } 
        </motion.div>)
    )
}

export default TicketTracking