import React, {useEffect, useMemo, useState} from 'react';
import { db } from '../../utils/firebase-config';
import { doc, getDoc, getDocs, collection, onSnapshot, query, where } from "firebase/firestore";
import { useTranslation } from 'react-i18next';
import ChangeLangDir from '../../helper/ChangeLangDir';
import { motion } from "framer-motion";
//import { Menu, Transition } from '@headlessui/react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lottie from "lottie-react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { RiMenu4Fill } from 'react-icons/ri';
import default_business from "../../assets/jsons/lottie/default_business.json";
import alert from "../../assets/jsons/lottie/alert.json";
import no_appointment from "../../assets/jsons/lottie/no_appointment.json";
import arrow_top from "../../assets/jsons/lottie/arrow_top.json";
import Dayjs from "dayjs";
//import { Helmet } from 'react-helmet';
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  isEqual,
  isSameDay,
  isSameMonth,
  isToday,
  parse,
  parseISO,
  startOfToday,
} from 'date-fns'
import { Link } from 'react-router-dom';




function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const Booking = () => {

  const {t} = useTranslation();
  const todayRaw = new Date();
  const [uid, setUid] = useState(null)
  const [userData, setUserData] = useState([])
  const [isUserData, setIsUserData] = useState(false)
  const [bid, setBid] = useState(null)
  const [businessData, setBusinessData] = useState(null)
  const [businessLogo, setBusinessLogo] = useState("")
  const [businessName, setBusinessName] = useState("")
  const [businessDesc, setBusinessDesc] = useState("")
  const [branches, setBranches] = useState([]);
  const [selectedBranchId, setSelectedBranchId] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedBranchData, setSelectedBranchData] = useState(null)
  const [selectedBranchPhoneNumber, setSelectedBranchPhoneNumber] = useState(null)
  const [selectedBranchWhatsapp, setSelectedBranchWhatsapp] = useState(null)
  const [selectedBranchGovernorate, setSelectedBranchGovernorate] = useState(null)
  const [selectedBranchArea, setSelectedBranchArea] = useState(null)
  const [selectedBranchFullAddress, setSelectedBranchFullAddress] = useState(null)

  const [selectedBranchSessions, setSelectedBranchSessions] = useState([]);


  


  let today = startOfToday()
  let [selectedDay, setSelectedDay] = useState(today)
  let [currentMonth, setCurrentMonth] = useState(format(today, 'MMM-yyyy'))
  let firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date())

  let days = eachDayOfInterval({
    start: firstDayCurrentMonth,
    end: endOfMonth(firstDayCurrentMonth),
  })

  function previousMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 })
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
  }

  function nextMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 })
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
  }


  let selectedDayselectedBranchSessions = selectedBranchSessions.filter((session) => 
    isSameDay(parseISO(Dayjs(session.startDate.seconds * 1000).format("YYYY-MM-DDTHH:mm")), selectedDay)
  )


  // useEffect( () => {
  //   const currentUrl = window.location.href;
  //   const parts = currentUrl.split('/');
  //   setUid(parts[parts.length - 1])  
  // }, [] )

  useEffect(() => {
    const currentUrl = window.location.href;
    const parts = currentUrl.split('/');
    const uid = parts[parts.length - 1].split('?')[0]; // split at "?" and take first element
    setUid(uid);
  }, []);
  
  console.log(uid)
  

  useEffect( () => {    
    if (uid !== null) {
      const getUserDataUn = async() => {        
        const userDocRef = doc(db, "users", uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setUserData(userDocSnap.data())          
          setIsUserData(true)
        } else {        
          console.log("No such document!");
        }
      }
      getUserDataUn(); 
    }         
  },[uid] )

  useEffect( () => {    
    if (uid !== null && isUserData === true) {
      const getBusinessIdUn = async() => {        
        const businessColRef = collection(db, `users/${uid}/business`);        
        try {
          const businessDocSnap = await getDocs(businessColRef);
          if(businessDocSnap.docs.length > 0) {
            businessDocSnap.forEach(doc => {                                  
                setBid(doc.id)                
                setBusinessLogo(doc.data()?.businessImg)
                setBusinessName(doc.data()?.businessName)
                setBusinessDesc(doc.data()?.businessDesc)
                setBusinessData(doc.data())
            })
          }
        } catch (error) {
          console.log(error);
        }
      }
      getBusinessIdUn(); 
    }         
  },[uid, isUserData] )

  useEffect( () => {    
    if (uid !== null && isUserData === true && bid !== null) {
      const getBranchesUn = async() => {        
        const businessDocRef = doc(db, `users/${uid}/business`, bid);                  
        const branchesColRef = collection(businessDocRef, "branches")                                                                     
        onSnapshot(branchesColRef, (querySnapshot) => {                
            const allBranches = querySnapshot.docs.map((doc)=>({
                ...doc.data()
            }))                
            setBranches(allBranches)             
        });  
      }
      getBranchesUn(); 
    }         
  },[uid, isUserData, bid] )


  useEffect( () => {    
    if (uid !== null && bid !== null && selectedBranchId !== null && selectedBranchId !== '') {
      const getSelectedBranchDataUn = async() => {        
        const selectedBranchDocRef = doc(db, `users/${uid}/business/${bid}/branches`, selectedBranchId);
        const selectedBranchDocSnap = await getDoc(selectedBranchDocRef);
        if (selectedBranchDocSnap.exists()) {
          setSelectedBranchData(selectedBranchDocSnap.data())                    
        } else {        
          console.log("No such document!");
        }
      }
      getSelectedBranchDataUn(); 
    }         
  },[uid, bid, selectedBranchId] )



  useEffect(() => {
    const unsubscribePageTitle = () => {
      document.title = businessData?.businessName;
    };
    return unsubscribePageTitle();
  }, [businessData?.businessName]);





  useMemo ( () => {
    if (selectedBranchData !== null) {
      setSelectedBranchPhoneNumber(selectedBranchData.branchPhone)
      setSelectedBranchWhatsapp(selectedBranchData.branchWhatsapp)
      setSelectedBranchGovernorate(selectedBranchData.governorate.label)
      setSelectedBranchArea(selectedBranchData.area)
      setSelectedBranchFullAddress(selectedBranchData.fullAddress)
    }
  }, [selectedBranchData] )


  


  useMemo(() => {    
    if (uid !== null && isUserData === true && bid !== null && selectedBranchId !== "") {
      const selectedBranch = `users/${uid}/business/${bid}/branches/${selectedBranchId}/sessions`; 
      const q = query(collection(db, selectedBranch),       
        where("isActive", "==", true),
        where("endDate", ">=", todayRaw),      
      );

      // const sessionsUnsub = await getDocs(q);
      // sessionsUnsub.forEach((doc) => {
      //   // doc.data() is never undefined for query doc snapshots
      //   console.log(doc.id, " => ", doc.data());
      // });

      const sessionsUnsub = onSnapshot(q, (snapshot) => {          
        setSelectedBranchSessions(snapshot.docs.map(doc => ({...doc.data(), id: doc.id})));           
      })
            
      return () => sessionsUnsub();   
    }   
  }, [selectedBranchId, uid, bid, isUserData]);  


  const handleSelectBranchBtn = (id, branchName) => {
    setSelectedBranchId(id);
    setSelectedBranch(branchName);    
  };
  
  if(selectedBranchData) {
    console.log(selectedBranchPhoneNumber)
  }
  

  return (
    uid === null || userData <= 0 || isUserData === false  || bid === null || businessData === null ? (
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='fixed w-screen h-screen inset-0 z-50 flex justify-center items-center'><div className='spinner'></div></motion.div>
    ) : (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className=""
        >  
        {/* <Helmet>
          <title>{businessData?.businessName}</title>
          <meta name="description" content={`${t('Bookings')} ${businessData?.businessName}`} />          
        </Helmet> */}
        <div className="border-b py-7 flex flex-col">
          <div className='flex flex-row justify-between items-center'>

            <div className='basis-3/12'>
              <button className='w-10 h-8 flex justify-center items-center  rounded-md cursor-pointer'>              
                <RiMenu4Fill fontSize={20} className={"text-primary rtl:rotate-180"} />
              </button>
            </div>            

            {
              businessData && businessData?.businessImg ? (
                <LazyLoadImage effect="blur" className="basis-1/2 mask mask-squircle w-32 h-32 object-cover" src={businessData?.businessImg} alt={businessData?.businessName && businessData?.businessName} />
              ) : (
                <Lottie animationData={default_business} style={{ height: 140 }} className="basis-1/2" loop={true} /> 
              )
            }

            <div className='basis-3/12 flex justify-end'>
              <ChangeLangDir classNameProp="w-20 h-8 flex justify-center items-center  rounded-md cursor-pointer text-primary " />
            </div> 

          </div>
          <div className='flex flex-col justify-center items-center'>             
            <h1 className='text-center text-lg font-bold pt-3'>{businessData?.businessName && businessData?.businessName}</h1>
            <p className='text-center text-neutral '>{businessData?.businessDesc && businessData?.businessDesc}</p>
          </div>          
        </div>
        <div className='mt-7 mb-7'>
          <strong className="text-neutral/50 uppercase text-2xs block pb-2">{t('Select branch')}</strong>
          <div className=''>
            <div className='pb-3'>
              {
                branches && branches.map ( branch => (
                  <motion.button
                    whileTap={{ scale: .9 }}
                    key={branch?.id}
                    className={ `px-3 py-1 rounded-md bg-thirdparty text-gray-600 text-xs font-bold capitalize cursor-pointer ltr:mr-2 rtl:ml-2 ${selectedBranch === branch?.branchName && '!bg-primary !text-white '}`}
                    onClick={() => handleSelectBranchBtn(branch?.id, branch?.branchName)}
                  >
                    {branch?.branchName}
                  </motion.button>
                ) )
              }              
            </div>
            <div className="border-b mt-4"></div> 
          </div>
        </div>

        {
          selectedBranch !== "" && selectedBranchSessions.length <= 0 ? (            
            <div className='relative -top-12 flex flex-col justify-center items-center mt-20'>
              <Lottie animationData={no_appointment} style={{ height: 140 }} loop={true} /> 
              <p className='relative -top-10 text-2xl text-center block opacity-50 pt-1'>{t('No appointments available!')}</p>
            </div>
          ):
          selectedBranch !== "" ? (
            <div className="mx-auto">
              <div className="grid grid-row-2 divide-y divide-gray-200">
                <div className="">
                  <div className="flex justify-between items-center">                    
                    <div className='flex justify-between items-center w-full'>
                      <button
                        type="button"
                        onClick={previousMonth}
                        className="flex flex-col justify-center items-center px-3 py-2 rounded-md bg-blue-gray-50"
                      >
                        <span className="sr-only">Previous month</span>
                        <IoIosArrowBack fontSize={18} className={"text-black rtl:rotate-180"} />   
                      </button>
                      <h2 className=" font-semibold text-gray-900">
                        {format(firstDayCurrentMonth, 'MMMM yyyy')}
                      </h2>
                      <button
                        onClick={nextMonth}
                        type="button"
                        className="flex flex-col justify-center items-center px-3 py-2 rounded-md bg-blue-gray-50"
                      >
                        <span className="sr-only">Next month</span>
                        <IoIosArrowForward fontSize={18} className={"text-black rtl:rotate-180"} />   
                      </button>
                    </div>
                  </div>
                  <div className='mt-7 '>
                    <div className="grid grid-cols-7 text-xs leading-6 text-center text-gray-500">
                      <div>{t('Sunday')}</div>
                      <div>{t('Monday')}</div>
                      <div>{t('Tuesday')}</div>
                      <div>{t('Wednesday')}</div>
                      <div>{t('Thursday')}</div>
                      <div>{t('Friday')}</div>
                      <div>{t('Saturday')}</div>
                    </div>
                    <div className="grid grid-cols-7 mt-2 text-sm">
                      {days.map((day, dayIdx) => (
                        <div
                          key={day.toString()}
                          className={classNames(
                            dayIdx === 0 && colStartClasses[getDay(day)],
                            'py-1.5'
                          )}
                        >
                          <button
                            type="button"
                            onClick={() => setSelectedDay(day)}
                            className={classNames(
                              isEqual(day, selectedDay) && 'text-white',
                              !isEqual(day, selectedDay) &&
                                isToday(day) &&
                                'text-primary',
                              !isEqual(day, selectedDay) &&
                                !isToday(day) &&
                                isSameMonth(day, firstDayCurrentMonth) &&
                                'text-gray-900',
                              !isEqual(day, selectedDay) &&
                                !isToday(day) &&
                                !isSameMonth(day, firstDayCurrentMonth) &&
                                'text-gray-400',
                              isEqual(day, selectedDay) && isToday(day) && 'bg-primary',
                              isEqual(day, selectedDay) &&
                                !isToday(day) &&
                                'bg-primary',
                              !isEqual(day, selectedDay) && 'hover:bg-gray-200',
                              (isEqual(day, selectedDay) || isToday(day)) &&
                                'font-semibold',
                              'mx-auto flex h-8 w-8 items-center justify-center rounded-full'
                            )}
                          >
                            <time dateTime={format(day, 'yyyy-MM-dd')}>
                              {format(day, 'd')}
                            </time>
                          </button>

                          <div className="w-1 h-1 mx-auto mt-1">
                            {selectedBranchSessions.some((session) =>
                              isSameDay(parseISO(Dayjs(session.startDate.seconds * 1000).format("YYYY-MM-DDTHH:mm")), day)
                            ) && (
                              <div className="w-1 h-1 rounded-full bg-primary"></div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                
                <section className="mt-7 pt-7 mb-5">
                  <h2 className="font-semibold text-gray-900">
                    {t('Schedule for')}{' '}
                    <time dateTime={format(selectedDay, 'yyyy-MM-dd')}>
                      {format(selectedDay, 'MMM dd, yyy')}
                    </time>
                  </h2>
                  <div className="mt-4 ">
                    {selectedDayselectedBranchSessions.length > 0 ? (
                      selectedDayselectedBranchSessions.map((session) => (
                        <Session 
                          key={session.id}
                          session={session} 
                          t={t} 
                          uid={uid} 
                          bid={bid} 
                          selectedBranchId={selectedBranchId}
                          businessLogo={businessLogo}
                          businessName={businessName}
                          businessDesc={businessDesc}
                          selectedBranch={selectedBranch}
                          selectedBranchPhoneNumber={selectedBranchPhoneNumber}
                          selectedBranchWhatsapp={selectedBranchWhatsapp}
                          selectedBranchGovernorate={selectedBranchGovernorate}
                          selectedBranchArea={selectedBranchArea}
                          selectedBranchFullAddress={selectedBranchFullAddress}
                        />
                      ))
                    ) : (
                      <p>{t('No appointments available!')}</p>
                    )}
                  </div>
                </section>
              </div>
            </div>
          ) : ( 
            <div className='flex flex-col justify-center items-center mt-20'>
              <div className='relative'>
                <div className='absolute bottom-20 ltr:-left-12 rtl:-right-12 rtl:rotate-90'>
                  <Lottie animationData={arrow_top} style={{ height: 30, opacity: .3 }} loop={true} /> 
                </div>
                <Lottie animationData={alert} style={{ height: 40, opacity: .3 }} loop={true} /> 
                <p className='text-2xl text-center block opacity-50 pt-1'>{t('No branch selected!')}</p>
              </div>
            </div>
            
             )
        }
      </motion.div>
    )    
  )


  function Session({ session, t, uid, bid, selectedBranchId, businessLogo, businessName, businessDesc, selectedBranch, selectedBranchPhoneNumber, selectedBranchWhatsapp, selectedBranchGovernorate, selectedBranchArea, selectedBranchFullAddress }) {
    let startDate = parseISO(Dayjs(session.startDate.seconds * 1000).format("YYYY-MM-DDTHH:mm"))
    let endDate = parseISO(Dayjs(session.endDate.seconds * 1000).format("YYYY-MM-DDTHH:mm"))      
  
    return (
      <div className="flex items-center mt-3 p-5 rounded-xl bg-primary/10">      
        <div className="flex-auto">    
          {          
            Dayjs(session?.startDate.seconds * 1000).format("ddd, DD MMM YYYY") === session?.sessionName ? null : (
              <p className="text-md pb-1">{session?.sessionName}</p>
            )
          }            
          <p className="text-xl">
            <time dateTime={Dayjs(session?.startDate.seconds * 1000).format("YYYY-MM-DDTHH:mm")}>
              {format(startDate, 'h:mm a')}
            </time>{' '}
            {t('to')} {' '}
            <time dateTime={Dayjs(session?.endDate.seconds * 1000).format("YYYY-MM-DDTHH:mm")}>
              {format(endDate, 'h:mm a')}
            </time>
          </p>
          <motion.button whileTap={{ scale: .9 }} className='w-full'>
            <Link 
              to={`/app/booking/${uid}/session/${session?.id}`}
              state={{ 
                passedUid: uid, 
                passedBid: bid,
                passedSelectedBranchId: selectedBranchId,
                passedBusinessLogo: businessLogo,
                passedBusinessName: businessName,
                passedBusinessDesc: businessDesc,
                passedSelectedBranchName: selectedBranch,
                passedSelectedBranchPhoneNumber: selectedBranchPhoneNumber,
                passedSelectedBranchWhatsapp: selectedBranchWhatsapp,
                passedSelectedBranchGovernorate: selectedBranchGovernorate,
                passedSelectedBranchArea: selectedBranchArea,
                passedSelectedBranchFullAddress: selectedBranchFullAddress,
                passedStartDateFull: Dayjs(session?.startDate.seconds * 1000).format("ddd, DD MMM YYYY"),
                passedEndDateFull: Dayjs(session?.endDate.seconds * 1000).format("ddd, DD MMM YYYY"),
                passedStartDate: Dayjs(session?.startDate.seconds * 1000).format("h:mm a"),
                passedEndDate: Dayjs(session?.endDate.seconds * 1000).format("h:mm a"),
                passedAvgTime: session?.avgTime,
                passedRemaining: session?.remaining,
                passedIsStarted: session?.started,
                passedSessionName: session?.sessionName,
                passedSessionPeak: session?.queuePeak,
                passedStartDateTimeRow: session?.startDate.seconds * 1000,
                passedEndDateTimeRow: session?.endDate.seconds * 1000
              }}
              className='inline-block w-full rounded-lg font-bold uppercase text-xs mt-4 py-4 text-white bg-primary  hover:bg-primary hover:text-white'
            >
              {t('Book Now!')}
            </Link>        
          </motion.button>
        </div>      
      </div>
    )
  }


}



let colStartClasses = [
  '',
  'col-start-2',
  'col-start-3',
  'col-start-4',
  'col-start-5',
  'col-start-6',
  'col-start-7',
]

export default Booking