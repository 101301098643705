import React, { useState, useEffect } from "react";
import { db, auth } from "../utils/firebase-config";
import { GlobalState } from "../context/StateContext";
import { getDocs, query, where, collection } from "firebase/firestore";

const NewGetCurrentBranch = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedBranchID, setSelectedBranchID] = useState(null);
  const user = auth.currentUser;
  const selectedBranch = localStorage.getItem(`selectedBranch-${user?.uid}`);
  const uidRef = GlobalState()?.userData?.id;
  const businessID = GlobalState()?.businessData?.id;

  useEffect(() => {
    if (!selectedBranch) {
      setError("No branch selected");
      return;
    }

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const selectedBranchQuery = query(
          collection(db, `users/${uidRef}/business/${businessID}/branches`),
          where("branchName", "==", selectedBranch)
        );
        const selectedBranchQuerySnapshot = await getDocs(selectedBranchQuery);
        selectedBranchQuerySnapshot.forEach((selectedBrancDoc) => {
          setSelectedBranchID(selectedBrancDoc.id);
        });
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [selectedBranch, uidRef, businessID]);

  if (error) {
    return <div>{error}</div>;
  }
  if (isLoading) {
    return <div>Loading...</div>;
  }
  return selectedBranchID;
};

export default NewGetCurrentBranch;
