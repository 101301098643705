import useAuth from '../helper/useAuth';
import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { motion } from "framer-motion";

const AdminRoutes = () => {
    
    const user = useAuth();        

    return typeof user === 'undefined' ? (        
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='fixed w-screen h-screen inset-0 z-50 flex justify-center items-center'><div className='spinner'></div></motion.div>
    ) : user.phoneNumber === process.env.REACT_APP_FALI_ADMIN_ONE ? (                
        <Outlet />
    ) : (
        <Navigate to="/app"/>
    );  
}

export default AdminRoutes