import React from 'react';
//import { MdOutlineMoreHoriz } from 'react-icons/md';
// import { RiSettings3Fill } from 'react-icons/ri';


const Session = (props) => {
  
  return (
    <div className={ `rounded-md bg-accent p-5 xs:w-full mb-2 relative ${ props.isInactiveClass }` }>
        <div className='flex flex-row justify-between items-center'>
            <div className='flex flex-col'>
              <strong className='font-medium text-xl'>{props.sessionName}</strong>              
              <small>{props.sessionStartDateFull}</small>
            </div>
            {/* <div><RiSettings3Fill fontSize={20} className={'text-neutral/50'} /></div> */}
            {/* <div><MdOutlineMoreHoriz fontSize={20} className={'text-neutral/50'} /></div> */}
        </div>
        <div>
          <p className='text-neutral/80'>{props.dateStartEnd}</p>
        </div>
        {/* <div className={ `absolute top-3 right-3 flex-col backdrop-blur-md bg-white/80 rounded-md ${ props.isActionToggleClass }` }>
          <div className='flex flex-row justify-start items-center px-5 py-3 pb-3 border-b border-black/5'>
              <RiSettings3Fill fontSize={20} className={'text-primary mr-1'} />
              <span className='text-primary text-md'>Settings</span>
            </div>
            <div className='flex flex-row justify-center items-center px-5 py-1'>
              <div className="form-control">
                <label className="label cursor-pointer">                  
                  <input type="checkbox" className="toggle toggle-primary mr-1" defaultChecked />

                </label>
              </div>
            </div>          
        </div> */}
    </div>
  )
}

export default Session


