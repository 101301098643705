import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './styles/BusinessWithBranch.css';
import { BsPersonCircle } from 'react-icons/bs';
import { MdBusinessCenter } from 'react-icons/md';
import { RiBuildingFill } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';




const BusinessWizard = (props) => {
  
    const {t} = useTranslation();

    return (    
        <motion.div 
        className='flex flex-row justify-center items-center content-center'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}        
        exit={{ opacity: 0 }}  
        >
            <div className='flex flex-col justify-center items-center'>
                <BsPersonCircle fontSize={40} className={ props.firstStepColor} />
                <span className={`font-bold text-xs rtl:mt-1 ${ props.firstStepColor}`}>{t('The Owner')}</span>
            </div>
            <div className={`bg-black w-5 mx-5 ${ props.oneToTwoColor}`} style={{ height: 1 }}></div>
            <div className='flex flex-col justify-center items-center'>
                <MdBusinessCenter fontSize={40} className={ props.secondStepColor} />
                <span className={`font-bold text-xs rtl:mt-1 ${ props.secondStepColor}`}>{t('The Business')}</span>
            </div>
            <div className={`bg-black w-5 mx-5 ${ props.twoToThreeColor}`} style={{ height: 1 }}></div>
            <div className='flex flex-col justify-center items-center'>
                <RiBuildingFill fontSize={40} className={ props.thirdStepColor} />
                <span className={`font-bold text-xs rtl:mt-1 ${ props.thirdStepColor}`}>{t('The Branch')}</span>
            </div>

        </motion.div>                      
    )
}



export default BusinessWizard