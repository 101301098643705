import React, { createContext, useContext, useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { onAuthStateChanged } from "firebase/auth";
import { db, auth } from "../utils/firebase-config";
import { collection, doc, onSnapshot, getDoc, query, getDocs, where, collectionGroup } from "firebase/firestore";

const StateContext = createContext();

export const StateContextProvider = ({children}) => {

    
    const [userData, setUserData] = useState({});    
    const [businessData, setBusinessData] = useState({});
    const [branchsData, setBranchsData] = useState([]);
    //const [currentSelectedBranch, setCurrentSelectedBranch] = useState('');
    //const [isLoading, setIsLoading] = useState(true);


    useEffect( () => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {

                const uid = user.uid;
                onSnapshot(doc(db, "users", uid), (userdoc) => {                                
                    setUserData(userdoc.data())
                    const isTheUserCompletedRef = userdoc.data()
                    const isTheUserCompleted = isTheUserCompletedRef?.isCompleted
                    //console.log('§§§§§§'+isTheUserCompleted)
                    if (!isTheUserCompleted) return;


                    if (isTheUserCompleted === true) {
                        
                        const businessCollectionRef = collection(db, "users", uid, "business");
                        onSnapshot(businessCollectionRef, (querySnapshot) => {                
                            const userBusinessInfo = querySnapshot.docs.map((doc)=>({
                                ...doc.data()
                            }))                
                            userBusinessInfo ? setBusinessData(userBusinessInfo[0]) : setBusinessData({});                                                        
                            //userBusinessInfo ? setIsLoading(false) : setIsLoading(true);                                                        
                            
                            
                            const businessId = userBusinessInfo[0].id;                    
                            const userBusinessDocRef = doc(db, `users/${uid}/business`, businessId);                  
                            const businessBranchColRef = collection(userBusinessDocRef, "branches")                                                                     
                            onSnapshot(businessBranchColRef, (querySnapshot) => {                
                                const userBranchesInfo = querySnapshot.docs.map((doc)=>({
                                    ...doc.data()
                                }))                
                                userBranchesInfo ? setBranchsData(userBranchesInfo) : setBranchsData([]); 
                                //userBranchesInfo ? setIsLoading(false) : setIsLoading(true);                                                                                                                                                                         
                            });  
                        });     

                        // if(!currentSelectedBranch) {                            
                        //     setCurrentSelectedBranch(localStorage.getItem('selectedBranch'))
                        // }                                                
                        //setCurrentSelectedBranch(localStorage.getItem('selectedBranch'))                        

                    }  
                    
                });                                                               
                
            } else {
                setUserData({})                
                setBusinessData({})
                setBranchsData([])
            }
        });
        return unsubscribe;
    },[]);

 
    const values = {
        userData,
        businessData,
        branchsData,
        //currentSelectedBranch
    }

    //console.log(values)    
    

    return (
        <StateContext.Provider value={values}>                        
            {children}
        </StateContext.Provider>                              
    );
}

export const GlobalState = () => {
    return useContext(StateContext);
}