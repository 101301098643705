import React, { useState, useEffect } from 'react';
import { db, auth } from '../../utils/firebase-config';
import { GlobalState } from '../../context/StateContext'
import {useTranslation} from 'react-i18next';
import { onAuthStateChanged } from "firebase/auth";
import { doc, setDoc, addDoc, collection, serverTimestamp, getDocs, query, getDoc, onSnapshot } from "firebase/firestore";
//import { Header } from '../../components/app';
import { motion } from "framer-motion";
import { useNavigate, useLocation } from "react-router-dom";
import { Input, Textarea } from "@material-tailwind/react";
import Select from 'react-tailwindcss-select';
import { TbDeviceMobile, TbBrandWhatsapp } from 'react-icons/tb';









const AddNewBranch = () => {
  
   const{t} = useTranslation();

   const governorateOptions = [
    {value: "cairo", label: t('Cairo')},  
    {value: "giza", label: t('Giza')},  
    {value: "alexandria", label: t('Alexandria')},  
    {value: "ismailia ", label: t('Ismailia')},  
    {value: "aswan ", label: t('Aswan')},  
    {value: "asyut ", label: t('Asyut')},  
    {value: "beheira ", label: t('Beheira')},  
    {value: "beni-suef", label: t('Beni Suef')},  
    {value: "dakahlia", label: t('Dakahlia')},  
    {value: "damietta", label: t('Damietta')},  
    {value: "faiyum", label: t('Faiyum')},  
    {value: "gharbia", label: t('Gharbia')},  
    {value: "kafr-el-sheikh", label: t('Kafr El Sheikh')},  
    {value: "luxor", label: t('Luxor')},  
    {value: "matruh", label: t('Matruh')},  
    {value: "minya", label: t('Minya')},  
    {value: "monufia", label: t('Monufia')},  
    {value: "new-valley", label: t('New Valley')},  
    {value: "north-sinai", label: t('North Sinai')},  
    {value: "port-said", label: t('Port Said')},  
    {value: "qalyubia", label: t('Qalyubia')},  
    {value: "qena", label: t('Qena')},  
    {value: "red-sea", label: t('Red Sea')},  
    {value: "sharqia", label: t('Sharqia')},  
    {value: "sohag", label: t('Sohag')},  
    {value: "south-sinai", label: t('South Sinai')},  
    {value: "suez", label: t('Suez')},  
    ];

    const navigate = useNavigate();
    //const location = useLocation();

    const user = auth.currentUser;
    //const [user, setUser] = useState(null)
    //const [uid, setUid] = useState(null) 
    const uid = user?.uid;

    const businessId = GlobalState()?.businessData?.id;    


    // useEffect(() => {
    //   const un = () => {
    //     if (user) {
    //       //setUser(auth.currentUser);
    //       setUid(user.uid);
    //     }
    //   };

    //   return un;
    // }, [user]);


    //const businessId = location.state.businessId;      

    //const [userData, setUserData] = useState({});    

    const [branchName, setBranchName] = useState('');
    const [branchPhone, setBranchPhone] = useState('');    
    const [branchWhatsapp, setBranchWhatsapp] = useState('');    
    const [governorate, setGovernorate] = useState(null);
    const [area, setArea] = useState('');
    const [fullAddress, setFullAddress] = useState('');    
    //const [businessId, setBusinessId] = useState('');    


    // useEffect(() => {
    //   const unsb = async () => {
    //     const userRef = doc(db, "users", user.uid);
    //     const userDocSnap = await getDoc(userRef);

    //     if (userDocSnap.exists()) {
    //       const userDocResult = userDocSnap.data();
    //       const userDocId = userDocResult.id;
    //       console.log(userDocId);

    //       let businessCollectionRef = collection(
    //         db,
    //         "users",
    //         userDocId,
    //         "business"
    //       );

    //       onSnapshot(businessCollectionRef, (querySnapshot) => {
    //         const userBusinessInfo = querySnapshot.docs.map((doc) => ({
    //           ...doc.data(),
    //           id: doc.id,
    //         }));

    //         setBusinessId(userBusinessInfo[0].id);
    //       });
    //     } else {
    //       console.log("no user document!");
    //     }
    //   };

    //   return () => {
    //     unsb();
    //   };
    // }, [user.uid]);

    // console.log('bid ===== '+businessId)
    // console.log('uid ===== '+uid)

    const handleGovernorateChange = (value) => {        
        setGovernorate(value);                      
    }


    const submitForm = async (e) => {
        e.preventDefault();        

        if (user ) {        

            const userBusinessDocRef = doc(db, `users/${uid}/business`, businessId);        
            const businessBranchColRef = collection(userBusinessDocRef, "branches")    


            await addDoc(businessBranchColRef, { 
                uid: uid,
                businessid: businessId,
                branchName: branchName,
                branchPhone: branchPhone,
                branchWhatsapp: branchWhatsapp,
                governorate: governorate,
                area: area,
                fullAddress: fullAddress,
                isActive: true,                                
                createdAt: serverTimestamp(),
            }, { merge: false })
            .then(docRef => {                
                setDoc(docRef, {
                    id: docRef.id
                }, { merge:true })    
                //setBranchId(docRef.id)                
                
            })
            .catch(error => console.error("Error adding document: ", error))                  

        } else {
            // No user is signed in.
        }


        navigate('/app');       
    }
    

    return (
        businessId === undefined ? (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className='fixed w-screen h-screen inset-0 z-50 flex justify-center items-center'><div className='spinner'></div></motion.div>
          ) : (
            <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                //animate={{ opacity: 1, transition: { duration: 1 } }}
                exit={{ opacity: 0 }}               
                >                
                    
                    {/* <Header title = {t('Add new branch')} />                 */}
                    <div className='upper_container xs:mt-10 md:mt-10'>                                        
                        <div className='mt-10'>
                            <form onSubmit={submitForm}>
                                <div className='mb-7'>
                                    <strong className='text-neutral/50 uppercase text-2xs block'>{t('Branch Details')}</strong>
                                    <div className="flex w-full mt-4">
                                        <Input type='text' value={branchName} className='' onChange={(e)=>setBranchName((e.target.value))} color="blue" size="lg" label={t('Branch name')} />
                                    </div>
                                    <div className="flex w-full mt-3">
                                        <Input type='number' value={branchPhone} className='' onChange={(e)=>setBranchPhone((e.target.value), setBranchWhatsapp(e.target.value))} color="blue" size="lg" label={t('Branch phone number')} icon={<TbDeviceMobile fontSize={20} className={'text-neutral/50'} />} />
                                    </div>
                                    <div className="flex w-full mt-3">
                                        <Input type='number' value={branchWhatsapp} className='' onChange={(e)=>setBranchWhatsapp((e.target.value))} color="blue" size="lg" label={t('Branch Whatsapp number')} icon={<TbBrandWhatsapp fontSize={20} className={'text-neutral/50'} />} />
                                    </div>
                                    <div className="flex w-full mt-3">
                                        <Select
                                            placeholder = {t('Governorate') + '*'}
                                            value={governorate}
                                            onChange={handleGovernorateChange}
                                            options={governorateOptions}           
                                            className="border border-gray-400 rounded-md "                             
                                        />                                     
                                    </div> 
                                    <div className="flex w-full mt-3">
                                        <Input type='text' value={area} className='' onChange={(e)=>setArea((e.target.value))} color="blue" size="lg" label={t('Area')} />
                                    </div>    
                                    <div className="flex w-full mt-3">
                                        <Textarea value={fullAddress} label={t('Full address')} onChange={(e)=>setFullAddress((e.target.value))} />
                                    </div>                            
                                    
                                                                    
                                    
                                    <div className='mt-7'>                                    
                                        <motion.input 
                                        type='submit' 
                                        value={t('Add branch')} 
                                        className="              
                                        rounded-md 
                                        font-bold 
                                        uppercase 
                                        text-xs 
                                        w-full 
                                        py-5
                                        text-white 
                                        hover:text-white 
                                        focus:text-white 
                                        bg-primary 
                                        hover:bg-primary 
                                        focus:bg-primary      
                                        cursor-pointer                   
                                        "
                                        whileTap={{ scale: 0.9 }}                                    
                                        />
                                    </div> 
                                </div> 
                                
                            </form>
                        </div>
                    </div>
            </motion.div>
          )
    )
}

export default AddNewBranch